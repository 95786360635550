import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import validate from '../../../srp_modules/validate'
import CallAnytime from '../../myaccount_header/call-anytime'

const PasswordResetPage = ({ emailToSendReset, sendPasswordResetEmailOnClick, t }) => {

    let validateEmail = (values) => {
        let errors = {}

        if (values.email === undefined || values.email.length === 0) {
            errors.email = "Email address is required"
        }
        else if (!validate.emailAddress(values.email)) {
            errors.email = "You must enter a valid email address"
        }

        return errors
    }

    return (
        <div>
            <div className="container py-3">
                <CallAnytime />
                <h3 className="mt-3">{t("Forgot your password?")}</h3>
                <h5 className="text-muted">{t("Retrieve password reset instructions by email")}</h5>

                <div className="row">
                    <div className="col-lg-8 col-12 mt-4">
                        <div className="srp-card-header">
                            {t("Verify your email address")}
                        </div>

                        <div className="srp-card-body ">
                            <div className="srp-card-details">
                                <Formik
                                    initialValues={{
                                        email: emailToSendReset
                                    }}
                                    onSubmit={sendPasswordResetEmailOnClick}
                                    validate={validateEmail}>
                                    {({ values, touched, status, dirty, errors, handleChange, handleBlur, handleSubmit, submitCount, isSubmitting }) => {
                                        let emailError = (dirty || submitCount > 0) && touched.email && errors.email !== undefined
                                        let resetStatus = status || {}

                                        return (
                                            <form method="POST" onSubmit={handleSubmit}>
                                                {resetStatus.resetError ? (
                                                    <div className="srp-alert-error mb-2">
                                                        {resetStatus.resetErrorText}
                                                    </div>
                                                ) : null}
                                                <div className="col-lg-7 col-12" style={{ padding: 0 }}>
                                                    <TextField id="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={emailError}
                                                        helperText={emailError ? t(errors.email) : ''}
                                                        fullWidth={true} 
                                                        label={t("Email address")}
                                                    />
                                                </div>
                                                <br />
                                                <div className="d-flex justify-content-end" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <Link to="/login" className="btn srp-btn btn-lightblue mb-2">{t("Back to Login")}</Link>
                                                    <button type="submit" disabled={isSubmitting} className="btn srp-btn btn-green text-white ml-2 mb-2">
                                                        {isSubmitting ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} alt="progress icon" aria-label={'submitting password reset'} /> : t("Reset Password")}
                                                    </button>
                                                </div>
                                            </form>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-12 mt-4">
                        <div className="srp-card-header">
                            {t("Need a new login?")}
                        </div>

                        <div className="srp-card-body ">
                            <div className="srp-card-details">
                                <div>{t("if_no_longer_have_access")}</div>
                                <div className="need-new-login-alert-body mt-4">
                                    <div className="need-new-login-alert-details">
                                        {t("by_creating_new_login")}
                                    </div>
                                </div>

                                <div className="srp-card-buttons d-flex justify-content-end mt-4">
                                    <Link to="/accountSetup" className="btn srp-btn btn-lightblue">{t("Create new login")}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

PasswordResetPage.propTypes = {
    emailToSendReset: PropTypes.string,
    sendPasswordResetEmailOnClick: PropTypes.func.isRequired,
    passwordResetEmailSentError: PropTypes.bool,
    passwordResetEmailSentErrorText: PropTypes.string,
    t: PropTypes.func
}

export default PasswordResetPage