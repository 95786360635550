import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

const EmailAddForm = ({goToAddButtonStep, noSubscribedEmailContacts, values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldTouched, setFieldValue, t}) => {
    let isError = touched["contactAddress"]
        && Object.prototype.hasOwnProperty.call(errors, "contactAddress")
    let errMsg = isError ? errors["contactAddress"] : ""

    let isNickNameError = touched["contactLabel"]
        && Object.prototype.hasOwnProperty.call(errors, "contactLabel")
    let nickNameErrMsg = isNickNameError ? errors["contactLabel"] : ""

    return(
    <form onSubmit={handleSubmit}>
        <div className="my-4">
            <TextField
                id="contactAddress"
                name="contactAddress"
                label={t("Email address")}
                helperText={errMsg}
                error={isError}
                value={values.contactAddress}
                type="email"
                fullWidth={true}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <TextField
                id="contactLabel"
                name="contactLabel"
                label={t("Nickname (optional)")}
                helperText={nickNameErrMsg}
                error={isNickNameError}
                value={values.contactLabel}
                fullWidth={true}
                onChange={handleChange}
                onBlur ={(e) => {
                    let value = e.target.value.substring(0,20)
                    setFieldValue('contactLabel', value, true)
                    setFieldTouched('contactLabel', true)
                }}
                inputProps={{ maxLength: 21 }}
            />
            <br />
            <div>
                <input
                    id="makePrimary"
                    name="makePrimary"
                    type="checkbox"
                    checked={values.makePrimary}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <span className="ml-2" style={{color:"rgb(112,112,112)"}}>{t("Make primary email")}</span>
            </div>
            <div className="d-flex flex-row-reverse mt-2">
                {noSubscribedEmailContacts ? <button type="submit" className="btn srp-btn btn-green ml-2">{t("Add")}</button> : <button type="submit" className="btn srp-btn btn-blue ml-2">{t("Next")}</button> }
                <button className="btn srp-btn btn-lightblue" onClick={goToAddButtonStep}>{t("Cancel")}</button>
            </div>
        </div>
    </form>
    )
}

EmailAddForm.propTypes = {
    goToAddButtonStep: PropTypes.func.isRequired,
    noSubscribedEmailContacts: PropTypes.bool.isRequired,

    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,

    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,

    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,

    t: PropTypes.func.isRequired
}

export default EmailAddForm