import * as React from "react"
import * as d3 from "d3"
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import drawVerticalBars from '../../../srp_modules/charting/draw-vertical-bars'
import { getAccountHistory } from '../../../actions/auth/payment/account-history-actions'
import * as loadingStatus from '../../../constants/loading-status-constants'
import {getDisplaySize} from '../../../srp_modules/display-size'
import { displaySizeConstants } from '../../../constants/display-size-constants'
import { initDailyUsagePage } from "../../../actions/auth/usage/usage-actions"
import { withRouter } from '../../../srp_modules/with-router'
import { dailyUsageChartTypeConstants } from '../../../constants/daily-usage-chart-type-constants'
import { getDailyUsage } from '../../../actions/auth/usage/daily-usage-actions'

const yOffset = 40
const standardChartKeys = ["total", "max"]
const margin = {
    top: 20,
    right: 10,
    bottom: 10,
    left: 45
}

class UsageChartMonthlyContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
           resizeListener: this.updateMonthlyDashChart.bind(this)
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.state.resizeListener)
        this.updateMonthlyDashChart()

        if(!this.props.billAccount.selectedBillAccountDetails.isPrePay){
            if (this.props.accountHistory.accountHistoryStatus === loadingStatus.LOADING_STATUS_INIT
            && this.props.selectedBillAccount > 0)
                this.props.getAccountHistoryForAccount(this.props.selectedBillAccount)
        
            if(this.props.accountHistory.accountHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS)
                this.updateMonthlyDashChart()
        }
        else{
            if (this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT && this.props.selectedBillAccount > 0){
                this.props.getDailyUsageForAccount(this.props.selectedBillAccount)
            }
            if (this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_SUCCESS)
                this.updateMonthlyDashChart()
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if (nextProps.accountHistory.accountHistoryStatus === loadingStatus.LOADING_STATUS_INIT
            && nextProps.selectedBillAccount > 0 && !nextProps.billAccount.selectedBillAccountDetails.isPrePay)
                this.props.getAccountHistoryForAccount(nextProps.selectedBillAccount)
        
        if (nextProps.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT 
            && nextProps.selectedBillAccount > 0 && nextProps.billAccount.selectedBillAccountDetails.isPrePay)
        {
            this.props.getDailyUsageForAccount(nextProps.selectedBillAccount)
        }
    }

    componentDidUpdate() {
        if (this.props.accountHistory.accountHistoryStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && !this.props.billAccount.selectedBillAccountDetails.isPrePay)
            this.updateMonthlyDashChart()
        
        if (this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.billAccount.selectedBillAccountDetails.isPrePay)
            this.updateMonthlyDashChart()
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.state.resizeListener)
    }

    updateMonthlyDashChart() {
        initializeChart(this.props.accountHistory.accountHistoryList,
            this.props.dailyUsage.monthlyCostAndKwhList,
            this.props.rateMetaData.rateMetaData.isMPower,
            this.props.initDailyUsagePage,
            this.props.rateMetaData.rateMetaData.isNetBilled,
            this.props.t)
    }

    render() {
        const isMetered = this.props.rateMetaData.rateMetaData.isMetered
        let legendText = this.props.rateMetaData.rateMetaData.isMPower ? "Total cost" : "Total billing"
        return (
            <div>
            <div>
                {!isMetered && (
                <div className="mt-3">{this.props.t("Monthly billing")}</div>)}
            </div>
            <div id="monthlyDashChartContainer" >
              <div id="monthlyDashChartLegendContainer" className="chart-legend-container" >
                <div id="totalLegendItem" className="chart-legend-item-show">
                    <span>{this.props.t(legendText)}</span>
                    <div className="chart-legend-circle viz-Total" />
                </div>

                    <div id="creditLegendItem" className="chart-legend-item-hidden">
                        <span>{this.props.t("Credit")}</span>
                        <div className="chart-legend-circle viz-total-credit" />
                    </div>

                </div>

              <div className=".viz-tooltip-arrow-main" />
            </div>
            </div>
            )
    }
}
UsageChartMonthlyContainer.propTypes = {
    accountHistory: PropTypes.object,
    billAccount: PropTypes.shape({
        selectedBillAccountDetails: PropTypes.shape({
            isPrePay: PropTypes.bool
        })
    }),
    selectedBillAccount: PropTypes.number,
    getAccountHistoryForAccount: PropTypes.func,
    getDailyUsageForAccount: PropTypes.func,
    rateMetaData: PropTypes.object,
    prepayHistory: PropTypes.object,
    payment: PropTypes.object,
    initDailyUsagePage: PropTypes.func,
    dailyUsage: PropTypes.object,
    t: PropTypes.func.isRequired
}
const mapStateToProps = (state) => {
    return {
        accountHistory: state.accountHistory,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        billAccount: state.accountInfo.billAccount,
        rateMetaData: state.rateMetaData,
        prepayHistory: state.prepayHistory,
        payment: state.accountInfo.payment,
        dailyUsage: state.dailyUsage
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getAccountHistoryForAccount: (billAccount) => {
             dispatch(getAccountHistory(billAccount))
        },
        initDailyUsagePage: (startDate, endDate, chartType) => {
            dispatch(initDailyUsagePage(startDate, endDate, chartType))
            ownProps.router.navigate('/myaccount/usage')
        },
        getDailyUsageForAccount: (billAccount) => {
            dispatch(getDailyUsage(billAccount))
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsageChartMonthlyContainer))

let initializeChart = (monthlyCostList, monthlyPrepayData, isMPower, barClickCallback, isNetBilled, t) => {
    if((!isMPower && monthlyCostList.length > 0)
        || (isMPower && monthlyPrepayData.length > 0) ){
        drawChart(monthlyCostList,
            monthlyPrepayData,
            isMPower,
            barClickCallback,
            isNetBilled,
            t
        )
    }
}

let drawChart = (monthlyData, monthlyPrepayData, isMPower, barClickCallback, isNetBilled, t) => {
    let convertedData = convertData(monthlyData, monthlyPrepayData, isMPower)
    let data = getMothlyComparisonData(convertedData)
    let maxY = getCostMax(data);
    let minY = 0
    window.dash_usage_selected_date =  ""
    let displaySize = getDisplaySize(window.innerWidth)
    let chartSize = getChartSvgSize(displaySize ,margin.right)
    margin.right = chartSize.marginRight

    let chart = d3.select("#monthlyDashChartContainer")
    d3.selectAll("#monthlyDashChart").remove()
    let  svg = chart
        .insert('svg','div')
        .attr('width', chartSize.width)
        .attr('height', chartSize.height)
        .attr('id', 'monthlyDashChart')
        .attr('class','viz-chart')
    let width = +svg.attr("width") - margin.left - margin.right
    let height = +svg.attr("height") - margin.top - margin.bottom

    let g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    const svgOffsetLeft = calculateOffsetLeft(svg);
    const barWidth = (width / 3 / 1.2)
    const xOffset = (width / data.length - barWidth) / 2

    data.forEach(function(element) {
        element.max = maxY - element.total
        element.date = DateTime.fromISO(element.date)
    })

    let x = d3.scaleBand()
            .rangeRound([0, width])
            .align(0.1)

    let y = d3.scaleLinear()
            .rangeRound([height - yOffset, 0])

    x.domain(data.map(function (d) {
        return d.date
    }))

    y.domain([minY, maxY])

    let tooltip = addTooltipToChart(chart)

    g.append("g").attr("id", "monthlyChartMainGroupDashboard")
        .selectAll("g")
        .data(d3.stack().keys(standardChartKeys)(data))
        .enter().append("g")
        .attr("class", function (d, i) {
            return getCssClass(standardChartKeys[i]) + " viz-bar"
        })
        .selectAll("path")
        .data(function (d) {
            return d
        })
        .enter().append("g")
        .attr("class", function (d) {
            return  d.data.isCredit ? "viz-total-credit" : ""
        })
        .append("path")
        .on("mouseover", function (d) {
            handleMouseOver(d, this, tooltip, barWidth, xOffset, maxY, minY, svg, svgOffsetLeft, x, y)
        })
        .on('mousedown', function(d){
            if('ontouchstart' in document.documentElement !== true) {
                return true
            }
            handleMouseOver(d, this, tooltip, barWidth, xOffset, maxY, minY, svg, svgOffsetLeft, x, y)
            if(window.dash_usage_selected_date !== undefined
                && window.dash_usage_selected_date !== null
                && window.dash_usage_selected_date !== ""){
                    if(window.dash_usage_selected_date.equals(d.data.billStartDate)){
                        barClickCallback(DateTime.fromISO(d.data.billStartDate),
                            DateTime.fromISO(d.data.billEndDate),
                            isNetBilled ? dailyUsageChartTypeConstants.NET_ENERGY : dailyUsageChartTypeConstants.USAGE)
                    }
            }
            d3.event.stopPropagation()
            window.dash_usage_selected_date = DateTime.fromISO(d.data.billStartDate)
        })
        .on("mouseout", function (d) { handleMouseOut(d, this, tooltip) })
        .on("click", function (d) {
            if('ontouchstart' in document.documentElement) {
                return true
            }
            barClickCallback(DateTime.fromISO(d.data.billStartDate).setZone('MST').startOf('day'),
                DateTime.fromISO(d.data.billEndDate).setZone('MST').startOf('day'),
                isNetBilled ? dailyUsageChartTypeConstants.NET_ENERGY : dailyUsageChartTypeConstants.USAGE)
        })
        .attr("height", 0)
        .attr("d", function (d) {
            return drawVerticalBars(d,data,x,y,minY,maxY,xOffset,barWidth,"date")
        })

    drawAxisTicks(data, g, height, yOffset, width,displaySize, x, y, isMPower, t)
    adjustLegend(margin, width, data)
}

function getCostMax(monthlyCostList){
    return monthlyCostList.length > 0
    ? Math.max(...monthlyCostList.map(o => o.total)) * 1.3
    : 0
}

function drawAxisTicks(data, g, height, yOffset, width, displaySize, x, y, isMPower, t) {
   if(displaySize != displaySizeConstants.MEDIUM)
    {
        
        let labelArray = [t("Last month"), t("Current"), t("Last year")]
        
        g.append("g")
        .attr("class", "axis")
        .attr("class", "date-axis")
        .attr("transform", "translate(0," + (height - yOffset) + ")")
        .call(d3.axisBottom(x).tickValues(x.domain())
            .tickFormat(function(d,i){
                if(data.length === 1)
                    return labelArray[1]
                return labelArray[i]

            }))
    }

    let notApplicable = t("N/A")
    g.append("g")
        .attr("class", "axis")
        .attr("class",function(){return displaySize == displaySizeConstants.MEDIUM ?
            "date-axis-md":
            "date-axis"})
        .attr("transform", "translate(0," + (height - yOffset + 14) + ")")
        .call(d3.axisBottom(x).tickValues(x.domain())
            .tickFormat(function(d){
                if(d.equals(DateTime.fromISO('0001-01-01'))  || d.equals(DateTime.fromISO('0002-01-01')))
                    return notApplicable
                else{
                    if (isMPower) {
                        return d.toFormat('MMM yy')
                    }
                    else {
                        return d.toFormat('M/d/yy')
                    }
                }
            }))

        g.append("g")
            .attr("class", "axis")
            .call(d3.axisLeft(y).ticks(2)
                .tickFormat(d3.format('$,.1s')))
            .append("text")
            .attr("x", 2)
            //.attr("y", y(y.ticks().pop()) + 0.5)
            .attr("fill", "#000")
            .attr("font-weight", "bold")
            .attr("text-anchor", "start")
}
function getChartSvgSize(displaySize, marginRight) {
    switch(displaySize){

        case displaySizeConstants.EXTRA_LARGE:
        return {
            height: 200,
            width: 300,
            marginRight: marginRight,
        }
        case displaySizeConstants.LARGE:
        return {
            height: 200,
            width: 240,
            marginRight: marginRight,
        }
        case displaySizeConstants.MEDIUM:
        return {
            height: 250,
            width: 650,
            marginRight: (marginRight/2),
        }
        case displaySizeConstants.SMALL:
        return {
            height: 200,
            width :480,
            marginRight: marginRight,
        }
        case displaySizeConstants.EXTRA_SMALL:
        return {
            height: 200,
            width :400,
            marginRight: marginRight,
        }
        case displaySizeConstants.MOBILE_LARGE:
        return {
            height: 200,
            width :340,
            marginRight: marginRight,
        }
        case displaySizeConstants.MOBILE:
        return {
            height: 200,
            width :300,
            marginRight: marginRight,
        }
        case displaySizeConstants.MOBILE_SMALL:
        return {
            height: 200,
            width :260,
            marginRight: marginRight,
        }
    }
}

function calculateOffsetLeft(svg) {
    let offsetLeft = svg.node().offsetLeft
    if (typeof offsetLeft != "undefined") {
        return offsetLeft
    }
    let svgElement = document.getElementById(svg.node().id)
    let svgParent = svgElement.parentNode
    let left = svgElement.getBoundingClientRect().left -
        svgParent.getBoundingClientRect().left

    return left;
}

function getCssClass(text) {
    if (text.includes("offset")) {
        return "viz-invisible"
    }
    return "viz-" + text
}

function handleMouseOver(d, element, tooltip, barWidth, xOffset, maxY, minY, svg, svgOffsetLeft, x, y) {
    $(element).addClass("current-hover")
    createTooltip(d, tooltip, barWidth, xOffset, maxY, minY, svg, svgOffsetLeft, x, y)
    return true
}

function createTooltip(d, tooltip, barWidth, xOffset, maxY, minY, svg, svgOffsetLeft, x, y) {
    let barTop = maxY - d.data.max

    let xPosition = x(d.data.date) + xOffset + margin.left +
        (barWidth - 20) / 2 + calculateOffsetLeft(svg)
    let yPosition = y(barTop)

    tooltip
        .style("left", xPosition + "px")
        .style("top", yPosition + "px")
        .style("display", "block")
    let tooltipInner = tooltip.select(".viz-tooltip-inner")
    tooltipInner.selectAll("*").remove()
    tooltipInner.append("div")
            .attr("class", "viz-tooltip-text-line")
            .text("$" + d.data.total.toFixed(2))
}

function handleMouseOut(d, element, tooltip) {
    $(element).removeClass("current-hover")
    tooltip.style("display", "none")
}

function addTooltipToChart(chart) {
    let initialTt = chart.select(".viz-tooltip-arrow-main")
    initialTt.remove()
    let tooltip = chart
        .append("div")
        .attr("class", "viz-tooltip-arrow-main")
    let tooltipArrow =
        tooltip
            .append("div")
            .attr("class", "viz-tooltip-arrow")

    tooltipArrow.append("div")
        .attr("class", "viz-tooltip-main")
        .append("div")
        .attr("class", "viz-tooltip-inner")
        .attr("id", "viz-tooltipText")
    return tooltip
}

function getMothlyComparisonData(accountHistoryData){
    let data = []
    if(accountHistoryData[1] === undefined)
        data.push({'date': '0002-01-01', 'total': 0, 'isCredit': false})
    else
        data.push(accountHistoryData[1])
    if(accountHistoryData[0] !== null)
        data.push(accountHistoryData[0])
    else
        data.push(accountHistoryData[1])
    if(accountHistoryData[12] === undefined)
        data.push({'date': '0001-01-01', 'total': 0, 'isCredit': false})
    else
        data.push(accountHistoryData[12])
    return JSON.parse(JSON.stringify(data))
}

//convert data to an array of generic objects to work with credit and prepay
function convertData(billData, monthlyPrepayData, isMPower) {
    let adjustedData = [];
    if(monthlyPrepayData.length > 0 && isMPower) {
        
        monthlyPrepayData.forEach(function(item) {
            let firstDayOfMonth = new Date(item.year, parseInt(item.month) - 1, 1)
            let lastDayOfMonth = getLastDayOfMonth(item.year, item.month, item.lastMeterReadDate)
            adjustedData.push({
                date: DateTime.fromObject({ year: item.year, month: item.month, day: 1 }).toISODate(),
                total: item.cost,
                billStartDate: firstDayOfMonth,
                billEndDate: lastDayOfMonth
            })
        })
        return adjustedData
    }

    if (billData.length > 0 ) {
        billData.forEach(function(item)
        {
            adjustedData.push(
                {
                    "date": item.billEndDate,
                    "billStartDate": item.billStartDate,
                    "billEndDate": item.billEndDate,
                    "total": Math.abs(item.totalUsageCharges),
                    "isCredit": item.totalUsageCharges < 0
                }
            );
        })
    }
    return adjustedData;
}

function adjustLegend(margin, width, monthlyData) {
    let legendContainer = d3.select('#monthlyDashChartLegendContainer')
    legendContainer
        .style("left", margin.left + "px")
        .style("width", width + "px")
    if(monthlyData.length === 3 && (monthlyData[0].isCredit || monthlyData[0].isCredit < 0 || monthlyData[0].isCredit < 0)){
        d3.select('#creditLegendItem').attr("class", "chart-legend-item-show")
    }
}

function getLastDayOfMonth(year, month, lastMeterReadDate) {
    let lastDayOfMonth = DateTime.fromObject({ year, month }).setZone('MST').endOf('month').startOf('day')
    let lastUsageDate = DateTime.fromISO(lastMeterReadDate).startOf('day')
    return DateTime.min(lastDayOfMonth, lastUsageDate).toJSDate()
}