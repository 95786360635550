import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Card from '@mui/material/Card'

import {
    getAutopayEligible,
    getAutopayEnrolled
} from '../../../actions/auth/payment/autopay-actions'
import { getEnrolledProgramsDetails, getCustomDueDateEligible } from '../../../actions/auth/payment/payment-actions'
import { getNotifications } from '../../../actions/auth/notification/notification-actions'
import { getBankAccountList } from '../../../actions/auth/payment/bank-account-actions'
import { rateMetaDataConstants } from '../../../constants/rate-meta-data-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

let updateDocumentDataIfNeeded = (props) => {
    if (props.selectedBillAccount === 0)
        return

    // Autopay
    if (props.isAutopayEligibleToEnrollStatus === loadingStatus.LOADING_STATUS_INIT)
        props.getIsAutopayEligible(props.selectedBillAccount)

    if (props.isAutopayEnrolledStatus === loadingStatus.LOADING_STATUS_INIT)
        props.getIsAutopayEnrolled(props.selectedBillAccount)

    // Budget Billing
    if (props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_INIT)
        props.getUserEnrolledPrograms(props.selectedBillAccount)

    // Alerts & eBill
    if (props.notificationsStatus === loadingStatus.LOADING_STATUS_INIT ||
        props.eBillStateStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getNotificationsInfo(props.selectedBillAccount)
    }

    // Bank
    if (props.bankAccountListStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getBankAccountList(props.selectedBillAccount)
    }

    //Custom due date eligibility
    if (props.isCustomDueDateEligibleStatus === loadingStatus.LOADING_STATUS_INIT) {
        props.getIsCustomDueDateEligible(props.selectedBillAccount)
    }
}

class ProfileStatusCard extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        updateDocumentDataIfNeeded(this.props)
    }

    componentDidUpdate() {
        updateDocumentDataIfNeeded(this.props)
    }

    render() {

        let requirementUnmet = {
            icon: 'radio_button_unchecked',
            style: { opacity: '0' }
        }
        let requirementMet = {
            icon: 'check_circle',
            style: { color: '#737b4c' }
        }

        let alertStatusDisplay = null
        if (this.props.notificationsStatus === loadingStatus.LOADING_STATUS_SUCCESS) {

            let alertStatus = this.props.notifications.subscribed.length > 0
            let alertStatusStyle = alertStatus ? requirementMet : requirementUnmet

            let alertStatusLink = {
                text: alertStatus ? this.props.t("Go to alerts") : this.props.t("Sign up"),
                link: alertStatus ? '/myaccount/profile/alerts' : '/myaccount/profile/alerts'
            }

            alertStatusDisplay = (<div className="d-flex align-items-center pl-2 pt-3 h-100">
                <i className="col-1 material-icons" style={alertStatusStyle.style} name="minChars">{alertStatusStyle.icon}</i>
                <div className="col-7">{this.props.t("Sign up for alerts")}</div>
                <div className="col text-right"><Link to={alertStatusLink.link}>{alertStatusLink.text}</Link></div>
            </div>)
        }

        let bankProfileStatusDisplay = null
        if (this.props.bankAccountListStatus === loadingStatus.LOADING_STATUS_SUCCESS) {

            let bankProfileStatus = this.props.bankAccountList.length > 0
            let bankProfileStyle = bankProfileStatus ? requirementMet : requirementUnmet

            let bankProfileLink = {
                text: bankProfileStatus ? this.props.t("Edit") : this.props.t("Sign up"),
                link: bankProfileStatus ? '/myaccount/profile/banks' : '/myaccount/profile/banks'
            }

            bankProfileStatusDisplay = (<div className="d-flex align-items-center pl-2 pt-3 h-100">
                <i className="col-1 material-icons" style={bankProfileStyle.style} name="minChars">{bankProfileStyle.icon}</i>
                <div className="col-7">{this.props.t("Attach a bank account to your profile")}</div>
                <div className="col text-right"><Link to={bankProfileLink.link}>{bankProfileLink.text}</Link></div>
            </div>)
        }

        let autopayStatusDisplay = null
        if (this.props.isAutopayEligible === true && this.props.isAutopayEligibleToEnrollStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.isAutopayEnrolledStatus === loadingStatus.LOADING_STATUS_SUCCESS) {

            let autopayStyle = this.props.isAutopayEnrolled ? requirementMet : requirementUnmet

            let autopayLink = {
                text: this.props.isAutopayEnrolled ? this.props.t("Edit") : this.props.t("Sign up"),
                link: this.props.isAutopayEnrolled ? "/myaccount/payment/autopay/edit" : "/myaccount/payment/autopay"
            }

            autopayStatusDisplay = (<div className="d-flex align-items-center pl-2 pt-3 h-100">
                <i className="col-1 material-icons" style={autopayStyle.style} name="minChars">{autopayStyle.icon}</i>
                <div className="col-7">{this.props.t("Enroll in automated payments (SurePay)")}</div>
                <div className="col text-right"><Link to={autopayLink.link}>{autopayLink.text}</Link></div>
            </div>)
        }

        let budgetBillingStatusDisplay = null
        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.rateMetaData.summaryBilling !== rateMetaDataConstants.SUMMARY_BILLING_CHILD
            && !this.props.selectedBillAccountDetails.isPrePay) {
            let budgetBillingStyle = this.props.isBudgetBillingEnrolled ? requirementMet : requirementUnmet

            let budgetBillingLink = {
                text: this.props.isBudgetBillingEnrolled ? '' : this.props.t("Sign up"),
                link: this.props.isBudgetBillingEnrolled ? '' : '/budgetbilling'
            }

            if (this.props.isBudgetBillingEnrolled || (!this.props.isBudgetBillingEnrolled && !this.props.selectedBillAccountDetails.isCommercial)) {
                budgetBillingStatusDisplay = (<div className="d-flex align-items-center pl-2 pt-3 h-100">
                    <i className="col-1 material-icons" style={budgetBillingStyle.style} name="minChars">{budgetBillingStyle.icon}</i>
                    <div className="col-7">{this.props.t("Enroll in predictable payments (Budget Billing)")}</div>
                    <div className="col text-right"><Link to={budgetBillingLink.link}>{budgetBillingLink.text}</Link></div>
                </div>)
            }
        }

        let customDueDateStatusDisplay = null
        if (this.props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.isCustomDueDateEligibleStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.isCustomDueDateEligible
            && this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.rateMetaData.summaryBilling !== rateMetaDataConstants.SUMMARY_BILLING_CHILD
            && this.props.rateMetaData.isMPower === false && this.props.selectedBillAccountDetails.isCommercial === false) {

            let customDueDateStyle = this.props.isCustomDueDateEnrolled ? requirementMet : requirementUnmet

            let customDueDateLink = {
                text: this.props.isCustomDueDateEnrolled ? this.props.t("Edit") : this.props.t("Sign up"),
                link: this.props.isCustomDueDateEnrolled ? '/customduedate' : '/customduedate'
            }

            customDueDateStatusDisplay = (<div className="d-flex align-items-center pl-2 pt-3 h-100">
                <i className="col-1 material-icons" style={customDueDateStyle.style} name="minChars">{customDueDateStyle.icon}</i>
                <div className="col-7">{this.props.t("Select Custom due date")}</div>
                <div className="col text-right"><Link to={customDueDateLink.link}>{customDueDateLink.text}</Link></div>
            </div>)
        }

        let paperlessBillingStatusDisplay = null
        if (this.props.eBillStateStatus === loadingStatus.LOADING_STATUS_SUCCESS &&
            this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.rateMetaData.isMPower === false) {

            let paperlessBillingStyle = this.props.isEBillEnrolled ? requirementMet : requirementUnmet

            let paperlessBillingLink = {
                text: this.props.isEBillEnrolled ? this.props.t("Edit") : this.props.t("Sign up"),
                link: this.props.isEBillEnrolled ? '/myaccount/profile/alerts' : '/myaccount/profile/alerts'
            }

            paperlessBillingStatusDisplay = (<div className="d-flex align-items-center pl-2 pt-3 h-100">
                <i className="col-1 material-icons" style={paperlessBillingStyle.style} name="minChars">{paperlessBillingStyle.icon}</i>
                <div className="col-7">{this.props.t("Enroll in paperless billing")}</div>
                <div className="col text-right"><Link to={paperlessBillingLink.link}>{paperlessBillingLink.text}</Link></div>
            </div>)
        }

        return (
            <div className="col-lg-4 col-12 mt-lg-0 mt-3">
                <div className="srp-card-header">
                    {this.props.t("Profile status")}
            </div>
                <Card>
                    <div className="mb-4">
                        {alertStatusDisplay}
                        {bankProfileStatusDisplay}
                        {paperlessBillingStatusDisplay}
                        {autopayStatusDisplay}
                        {budgetBillingStatusDisplay}
                        {customDueDateStatusDisplay}
                    </div>
                </Card>
            </div>
        )
    }
}


ProfileStatusCard.propTypes = {
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,
    customerNames: PropTypes.object,
    primaryEmail: PropTypes.string,
    serviceAddress: PropTypes.object,

    //Autopay
    isAutopayEligible: PropTypes.bool,
    getIsAutopayEligible: PropTypes.func,
    isAutopayEligibleToEnrollStatus: PropTypes.string,

    isAutopayEnrolled: PropTypes.bool,
    getIsAutopayEnrolled: PropTypes.func,
    isAutopayEnrolledStatus: PropTypes.string,

    //Budget Billing
    getUserEnrolledPrograms: PropTypes.func,
    enrolledProgramsDetailsStatus: PropTypes.string,
    isBudgetBillingEnrolled: PropTypes.bool,
    accountHistoryStatus: PropTypes.string,
    budgetBillingEstimate: PropTypes.number,
    submitBudgetBillingStatus: PropTypes.string,
    submitBudgetBillingForm: PropTypes.func,


    // Alerts
    notificationsStatus: PropTypes.string,
    notifications: PropTypes.object,

    // eBill
    isEBillEnrolled: PropTypes.bool,
    eBillStateStatus: PropTypes.string,

    // Bank
    bankAccountListStatus: PropTypes.string,
    bankAccountList: PropTypes.array,

    // Custom Due Date
    isCustomDueDateEnrolled: PropTypes.bool,
    isCustomDueDateEligible: PropTypes.bool,
    isCustomDueDateEligibleStatus: PropTypes.string,
    getIsCustomDueDateEligible: PropTypes.func,
    rateMetaData: PropTypes.object,
    rateMetaDataStatus: PropTypes.string,

    // i18n
    t: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        ...state.accountInfo.billAccount,

        //Autopay
        isAutopayEligible: state.accountInfo.autopay.autopayInfo.isEligbleToEnroll,
        isAutopayEligibleToEnrollStatus: state.accountInfo.autopay.getEligibleToEnrollStatus,

        isAutopayEnrolled: state.accountInfo.autopay.autopayInfo.isEnrolled,
        isAutopayEnrolledStatus: state.accountInfo.autopay.getIsEnrolledStatus,

        // Budget Billing
        enrolledProgramsDetailsStatus: state.accountInfo.payment.enrolledProgramsDetailsStatus,
        isBudgetBillingEnrolled: state.accountInfo.payment.isBudgetBilling,
        ...state.accountInfo.contact,

        // Alerts
        notifications: state.accountInfo.notification.notifications,
        notificationsStatus: state.accountInfo.notification.notificationsStatus,

        // eBill
        isEBillEnrolled: state.accountInfo.notification.eBillState,
        eBillStateStatus: state.accountInfo.notification.eBillStateStatus,

        // Bank
        bankAccountList: state.bankAccounts.bankAccountList,
        bankAccountListStatus: state.bankAccounts.bankAccountListStatus,

        // Custom Due Date
        isCustomDueDateEnrolled: state.accountInfo.payment.isCustomDueDate,
        isCustomDueDateEligible: state.accountInfo.payment.customDueDateInfo.isEligible,
        isCustomDueDateEligibleStatus: state.accountInfo.payment.isCustomDueDateEligibleStatus,
        rateMetaData: state.rateMetaData.rateMetaData,
        rateMetaDataStatus: state.rateMetaData.rateMetaDataStatus
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // Autopay
        getIsAutopayEligible: (billAccount) => {
            dispatch(getAutopayEligible(billAccount))
        },
        getIsAutopayEnrolled: (billAccount) => {
            dispatch(getAutopayEnrolled(billAccount))
        },

        // Budget Billing - Cash Only
        getUserEnrolledPrograms: (billAccount) => {
            dispatch(getEnrolledProgramsDetails(billAccount))
        },

        // Alerts - eBill
        getNotificationsInfo: (billAccount) => {
            dispatch(getNotifications(billAccount))
        },

        // Bank Account
        getBankAccountList: (billAccount) => {
            dispatch(getBankAccountList(billAccount))
        },

        getIsCustomDueDateEligible: (billAccount) => {
            dispatch(getCustomDueDateEligible(billAccount))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileStatusCard)