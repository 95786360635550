import React from 'react'
import PropTypes from 'prop-types'

import BankFirstNameField from '../input_page/input-fields/bank-first-name-field'
import BankLastNameField from '../input_page/input-fields/bank-last-name-field'
import BankPhoneNumberField from '../input_page/input-fields/bank-phone-number-field'
import BankEmailAddressField from '../input_page/input-fields/bank-email-address-field'
import BankRoutingNumberField from '../input_page/input-fields/bank-routing-number-field'
import BankAccountNumberField from '../input_page/input-fields/bank-account-number-field'
import BankNicknameField from '../input_page/input-fields/bank-nickname-field'

import BankRoutingNumberAccountNumberInfoIcon from '../../common_payment/bank-routing-number-account-number-info-icon'

const AddBankAccountForm = (props) => {
        let { values, errors, touched, handleChange, handleBlur, handleBankFormCancel, isSubmitting, showCancelButton, t } = props
        let commonFormikProps = {
            onChange: handleChange,
            onBlur: handleBlur,
            errors,
            touched
        }

        let commonTextFieldProps = {
            fullWidth: true
        }

        return (
            <div className="mb-4">
            <div className="mt-1"><BankFirstNameField value={values.bankFirstName} {...commonFormikProps} {...commonTextFieldProps} t={t} /></div>
            <div className="mt-3"><BankLastNameField value={values.bankLastName} {...commonFormikProps} {...commonTextFieldProps} t={t} /></div>
            <div className="mt-3"><BankPhoneNumberField value={values.bankPhoneNumber} {...commonFormikProps} {...commonTextFieldProps} t={t} /></div>
            <div className="mt-3"><BankEmailAddressField value={values.bankEmailAddress} {...commonFormikProps} {...commonTextFieldProps} t={t} /></div>
            
            <div className="mt-3 position-relative">
                <BankRoutingNumberField value={values.bankRoutingNumber} {...commonFormikProps} {...commonTextFieldProps} t={t}
                    endAdornment={
                        <BankRoutingNumberAccountNumberInfoIcon
                            showRoutingNumberDescription={true}
                            showBankAccountNumberDescription={false}
                            className="d-lg-none" />
                    }
                />
            </div>

            <div className="mt-3 position-relative">
                <BankAccountNumberField value={values.bankAccountNumberLeadingZeroesAreSignificant} {...commonFormikProps} {...commonTextFieldProps} t={t}
                    endAdornment={
                        <BankRoutingNumberAccountNumberInfoIcon
                            showRoutingNumberDescription={false}
                            showBankAccountNumberDescription={true}
                            className="d-lg-none" />
                    }
                />
            </div>

            <div className="mt-3"><BankNicknameField value={values.bankNickname} {...commonFormikProps} {...commonTextFieldProps} t={t} /></div>

            {showCancelButton &&
            <div className="d-flex justify-content-end flex-wrap mt-4">
                <button className="btn srp-btn btn-lightblue mr-2" type="button" onClick={() => handleBankFormCancel()} disabled={isSubmitting}>{t("Cancel")}</button>
            </div>
            }
            </div>
        )
}

AddBankAccountForm.propTypes = {
    t:  PropTypes.func.isRequired,
    values: PropTypes.shape({
        bankFirstName: PropTypes.string.isRequired,
        bankLastName: PropTypes.string.isRequired,
        bankPhoneNumber: PropTypes.string.isRequired,
        bankEmailAddress: PropTypes.string.isRequired,
        bankRoutingNumber: PropTypes.string.isRequired,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string.isRequired,
        bankNickname: PropTypes.string.isRequired
    }).isRequired,
    errors: PropTypes.shape({
        bankFirstName: PropTypes.string,
        bankLastName: PropTypes.string,
        bankPhoneNumber: PropTypes.string,
        bankEmailAddress: PropTypes.string,
        bankRoutingNumber: PropTypes.string,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.string,
        bankNickname: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        bankFirstName: PropTypes.bool,
        bankLastName: PropTypes.bool,
        bankPhoneNumber: PropTypes.bool,
        bankEmailAddress: PropTypes.bool,
        bankRoutingNumber: PropTypes.bool,
        bankAccountNumberLeadingZeroesAreSignificant: PropTypes.bool,
        bankNickname: PropTypes.bool
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    showCancelButton: PropTypes.bool.isRequired,
    handleBankFormCancel: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired
}

export default AddBankAccountForm