import React from 'react'
import Dialog from '@mui/material/Dialog'
import format from '../../srp_modules/format'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const CashPaymentReconnectDialog = ({dialogOpen, setDialogOpen, disconnectedAccounts, navUrl, t}) => {
    return (
        <Dialog
            open={dialogOpen}
            sx={{
                ".MuiPaper-root" : {
                    maxWidth : {
                        xs : '420px',
                        lg : '680px'
                    },
                    padding: '20px'
                }
            }}
        >
            <div className="mx-2 mb-3">
                <h4><b>{t("Safety First!")}</b></h4>
            </div>
            <div className="mx-2 mb-3">
                {t("Before making a payment, SRP needs to know if it is safe to reconnect your power.")} <br /><br/>

                {t("please_be_aware_the_risk")}
            </div>
            <div className="mx-2 mb-4 srp-alert-notice-DCO">
                <div className="mb-2 font-weight-bold">{t("power_remain_disconnected")}</div>
                <ul>
                    {disconnectedAccounts.map((account, i)=>{
                        let accountNumber = format.srpAccountNumber(account.account.toString())
                        let accountNumberFormatted = format.formatBillAccountNumber(accountNumber)
                        if(account.accountName.length !== 0){
                            return(<li key={i}>{account.accountName} ({accountNumberFormatted})</li>)
                        }else{
                            return(<li key={i}>{accountNumberFormatted}</li>)
                        }
                    })}
                </ul>
            </div>
            <div className="d-flex justify-content-end mx-2 mb-2">
                <button 
                    className="mx-2 btn srp-btn btn-lightblue"
                    onClick={()=>{
                        setDialogOpen(false)
                        sessionStorage.setItem("SafetyDialogOpened", true)
                }}>
                    {t("Dismiss")}
                </button>
                <button
                    className="btn srp-btn btn-blue"
                    onClick={()=>{
                        setDialogOpen(false)
                        sessionStorage.setItem("SafetyDialogOpened", true)
                        if(navUrl !== "/myaccount/payment/multi/cash"){
                            window.location.replace("/myaccount/payment/multi")
                        }
                    }}>
                        {t("Proceed")}
                </button>
            </div>
        </Dialog>
    )
}

CashPaymentReconnectDialog.propTypes = {
    dialogOpen:PropTypes.bool.isRequired,
    setDialogOpen:PropTypes.func.isRequired,
    disconnectedAccounts:PropTypes.arrayOf(PropTypes.object),
    navUrl:PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
}

export default withTranslation("reconnectNotice")(CashPaymentReconnectDialog)
