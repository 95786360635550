import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as loadingStatus from '../constants/loading-status-constants'

import { getMapScriptRequest, getMapScriptSuccess } from '../actions/guest/map-script-actions'
import config from 'my-account-config'

class MapScriptLoader extends React.Component {
    constructor(props) {
        super(props);

        window.mapLoaded = this.mapLoaded.bind(this)
    }

    componentDidMount(){
        if (this.props.mapScriptLoadedStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.mapLoading()

            if(window.google !== undefined) {
                delete window.google
            }
            const script = document.createElement("script")
            script.src = `https://maps.googleapis.com/maps/api/js?key=${this.props.apiKey}&libraries=geometry,places&callback=mapLoaded${config.googleMapsBeta ? '&v=beta' : ''}`

            document.body.appendChild(script)
        }
    }

    mapLoading() {
        this.props.getMapScriptRequest()
    }

    mapLoaded() {
        this.props.getMapScriptSuccess()
    }

    render() {
        return null
    }
}

MapScriptLoader.propTypes = {
    apiKey: PropTypes.string,
    getMapScriptRequest: PropTypes.func,
    getMapScriptSuccess: PropTypes.func,
    mapScriptLoadedStatus: PropTypes.string
}

const mapStateToProps = state => {
    return { ...state.login }
}

const mapDispatchToProps = dispatch => {
    return {
        getMapScriptRequest: () => {
            dispatch(getMapScriptRequest())
        },
        getMapScriptSuccess: () => {
            dispatch(getMapScriptSuccess())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapScriptLoader)