import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { DocumentTitle } from '../../srp_modules/document-title'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import CircularProgress from '@mui/material/CircularProgress'

import CallAnytime from '../myaccount_header/call-anytime'
import PayLocationMapContainer from './pay-location-map-container'
import PayLocationSearch from './pay-location-search'

import { getAllPayLocations, getCurrentLocation, clearClosestPayLocations } from '../../actions/guest/pay-location-actions'
import { getBillAccounts } from '../../actions/auth/bill_account/bill-account-actions'
import * as loadingStatus from '../../constants/loading-status-constants'

import { withTranslation } from 'react-i18next'

let progressStyle = {
    marginTop: "20%",
    marginBottom: "20%"
}

class PaymentLocationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchAddress: "",
            searchAddressResponsive: "",
            searchAddressError: false,
            searchAddressErrorMessage: ""
        }

        this.getCurrentLocation = this.getCurrentLocation.bind(this)
        this.restrictToArizona = this.restrictToArizona.bind(this)
        this.refreshPage = this.refreshPage.bind(this)
    }

    componentDidMount() {
        if (this.props.isLoggedIn) {
            if (this.props.billAccountListStatus === loadingStatus.LOADING_STATUS_INIT) {
                this.props.getBillAccounts()
            }
        }

        this.getCurrentLocation()

        if (this.props.paymentLocationStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getAllPayLocationsInfo()
        }
    }

    getCurrentLocation() {
        if (navigator.geolocation) {
            let thisMap = this
            navigator.geolocation.getCurrentPosition(function (position) {
                let pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }

                thisMap.props.setCurrentLocationInfo(pos)
            }, function () {
                //user blocks geolocation setting
                thisMap.props.clearClosestPayLocationsOnClick()
            })
        }
        else {
            // Browser doesn't support Geolocation
            this.props.clearClosestPayLocationsOnClick()
        }
    }

    restrictToArizona(address) {
        let isArizonaAddress = {}
        for (let i = 0; i < address.length; i++) {
            for (let j = 0; j < address[i].address_components.length; j++) {
                if (address[i].address_components[j].types[0] === "administrative_area_level_1" && address[i].address_components[j].long_name === "Arizona") {
                    isArizonaAddress = address[i]
                    return isArizonaAddress
                }
            }
        }

        return isArizonaAddress
    }

    refreshPage() {
        this.props.getAllPayLocationsInfo()
    }

    render() {
        let renderedPage = {}
        if ((this.props.paymentLocationStatus === loadingStatus.LOADING_STATUS_INIT || this.props.paymentLocationStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS)
            || this.props.mapScriptLoadedStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            renderedPage = (
                <div className="srp-card-body">
                    <div className="srp-card-details d-flex justify-content-center">
                        <CircularProgress size={80} thickness={5} style={progressStyle} />
                    </div>
                </div>
            )
        }
        else if (this.props.paymentLocationStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.mapScriptLoadedStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            renderedPage = (
                <div className="srp-card-body">
                    <PayLocationSearch restrictToArizona={this.restrictToArizona} t={this.props.t}/>
                    <PayLocationMapContainer
                        getAllPayLocationsInfo={this.props.getAllPayLocationsInfo}
                        restrictToArizona={this.restrictToArizona} 
                        t={this.props.t}/>
                </div>
            )
        }
        else {
            renderedPage = (
                <div className="srp-card-body">
                    <div className="srp-card-details">
                        <div className="text-center">
                            <div className="mb-1 srp-100-red"><i className="material-icons font-50">error_outline</i></div>
                            <div className="mb-1 srp-100-red"><strong>{this.props.t("Something went wrong")}</strong></div>
                            <div className="mb-4">{this.props.t("We were unable to retrieve payment location data.")}</div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <a onClick={() => this.refreshPage()} className="btn srp-btn btn-lightblue text-white mr-2">{this.props.t("Try again")}</a>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <DocumentTitle title="In-Person Payment Locations | SRP Power">
                <div>
                    {this.props.isLoggedIn ?
                        <div className="d-block d-md-none">
                            <Button
                                variant="contained"
                                fullWidth={true}
                                className="justify-content-start nav-button-responsive"
                                component={Link} to="/myaccount/payment"
                                disableRipple>
                                <Icon className="material-icons mr-2" style={{ color: 'rgb(33,108,224)' }}>keyboard_backspace</Icon>
                                {this.props.t("Make payment")}
                            </Button>
                        </div>
                        : null
                    }
                    <div className="container py-3">
                        <CallAnytime />
                        <h3 className="mt-3">{this.props.t("In-Person Payment Locations")}</h3>
                        <h5 className="text-muted mb-4">{this.props.t("Find locations, get directions")}</h5>
                        {this.props.isLoggedIn ?
                            <div>
                                <div className="d-none d-md-block">
                                    <h5 className="d-flex justify-content-start align-items-center">
                                        <Link to="/myaccount/payment" className="mr-2">{this.props.t("Make a payment")}</Link>
                                        <i className="material-icons mr-2" style={{ color: 'rgba(72, 72, 74, 0.376)', fontSize: '18px' }}>keyboard_arrow_right</i>
                                        {this.props.t("In-Person Payment Locations")}
                                    </h5>
                                </div>
                            </div>
                            : <span />
                        }
                        <div className="row">
                            <div className="col-12 mt-4">
                                <div className="srp-card-header">
                                    {this.props.t("Payment locations")}
                                </div>
                                {renderedPage}
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        )
    }
}

const mapStateToProps = state => {
    return { ...state.paymentLocation, ...state.accountInfo.billAccount, ...state.login }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllPayLocationsInfo: () => {
            dispatch(getAllPayLocations())
        },
        getBillAccounts: () => {
            dispatch(getBillAccounts())
        },
        setCurrentLocationInfo: (location) => {
            dispatch(getCurrentLocation(location))
        },
        clearClosestPayLocationsOnClick: () => {
            dispatch(clearClosestPayLocations())
        }
    }
}

PaymentLocationContainer.propTypes = {
    isLoggedIn: PropTypes.bool,
    paymentLocationStatus: PropTypes.string,
    getAllPayLocationsInfo: PropTypes.func,
    billAccountListStatus: PropTypes.string,
    getBillAccounts: PropTypes.func,
    mapScriptLoadedStatus: PropTypes.string,
    clearClosestPayLocationsOnClick: PropTypes.func,
    t: PropTypes.func.isRequired
}

export default withTranslation("paymentLocation")(connect(mapStateToProps, mapDispatchToProps)(PaymentLocationContainer))