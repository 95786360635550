import React from 'react'
import { DocumentTitle } from '../../srp_modules/document-title'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { withTranslation } from 'react-i18next'

import LoginPage from './login-page'
import { completeCreateAccount, completeLoginEmailUpdate, getResponsiveLoginTabValue, beginLogout, loginWasDeleted } from '../../actions/auth/login/login-actions'
import { getBillAccounts } from '../../actions/auth/bill_account/bill-account-actions'
import { LOADING_STATUS_INIT, LOADING_STATUS_SUCCESS, LOADING_STATUS_FAILURE } from '../../constants/loading-status-constants'
import { withRouter } from '../../srp_modules/with-router'

function deriveAccountChangesFromQueryString() {
    let initialAccountChangeState = {
        completeCreateAccount: false,
        updateLoginEmail: false,
        email: '',
        newEmail: '',
        token: '',
        source: ''
    }

    let params = queryString.parse(location.search)

    if (params === undefined)
        return initialAccountChangeState

    if (params.t !== undefined && params.e !== undefined && params.s !== undefined) {
        return {
            ...initialAccountChangeState,
            token: params.t,
            email: params.e,
            source: params.s,
            completeCreateAccount: true
        }
    }

    if (params.o !== undefined && params.n !== undefined && params.t !== undefined) {
        return {
            ...initialAccountChangeState,
            token: params.t,
            email: params.o,
            newEmail: params.n,
            updateLoginEmail: true
        }
    }

    return initialAccountChangeState
}

class LoginPageContainer extends React.Component {
    constructor(props) {
        super(props)

        let pendingAccountChanges = deriveAccountChangesFromQueryString()
        /* pendingAccountChanges shape:
            let pendingAccountChanges = {
                completeCreateAccount: false,
                updateLoginEmail: false,
                email: '',
                newEmail: '',
                token: ''
            }
        */

        this.state = {
            pendingAccountChanges,
            customerLoggedInJustNow: false,
            deletionInformed: false
        }
    }

    componentDidMount() {
        this.processAccountChanges()
        this.logoutOnLogoutPage()

        if (this.state.pendingAccountChanges.completeCreateAccount || this.state.pendingAccountChanges.updateLoginEmail) {
            // NOTE: This is just to remove the query string from the browser's location bar
            this.props.router.navigate(this.props.router.location.pathname, { replace: true })
        }

        sessionStorage.clear()
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (this.props.loginStatus !== LOADING_STATUS_SUCCESS
            && nextProps.loginStatus === LOADING_STATUS_SUCCESS
            && nextProps.isLoggedIn)
            this.setState({ customerLoggedInJustNow: true })
    }

    componentDidUpdate() {
        this.processAccountChanges()
        this.logoutOnLogoutPage()
        
        let qs = new URLSearchParams(document.location.search)
        let deletedValue = qs.get('deleted')
        if (deletedValue && !this.state.deletionInformed && this.props.logoutStatus === LOADING_STATUS_SUCCESS) {
            this.props.loginAccountWasDeleted()
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ deletionInformed: true })
        }
    }

    logoutOnLogoutPage() {
        if (this.props.logoutStatus !== LOADING_STATUS_INIT || this.props.router.location.pathname.indexOf('logout') < 0)
            return

        this.props.beginLogout()
    }

    processAccountChanges() {
        if (this.state.pendingAccountChanges.completeCreateAccount
            && this.props.completeCreateAccountStatus === LOADING_STATUS_INIT) {
            this.props.completeCreateAccount(this.state.pendingAccountChanges.email, this.state.pendingAccountChanges.token)
        }

        if (this.state.pendingAccountChanges.updateLoginEmail
            && this.props.completeLoginUpdateStatus === LOADING_STATUS_INIT) {
            if (this.props.isLoggedIn) {
                this.props.beginLogout()
            }
            this.props.completeLoginEmailUpdate(this.state.pendingAccountChanges.email,
                this.state.pendingAccountChanges.newEmail,
                this.state.pendingAccountChanges.token)
        }
    }

    render() {
        let customerIsCompletingAccountCreation = this.state.pendingAccountChanges.completeCreateAccount
            && this.props.completeCreateAccountStatus !== LOADING_STATUS_SUCCESS
            && this.props.completeCreateAccountStatus !== LOADING_STATUS_FAILURE

        let customerIsUpdatingLoginEmail = this.state.pendingAccountChanges.updateLoginEmail
            && this.props.completeLoginUpdateStatus !== LOADING_STATUS_SUCCESS
            && this.props.completeLoginUpdateStatus !== LOADING_STATUS_FAILURE

        let customerIsLoggingOut = this.props.router.location.pathname.indexOf('logout') >= 0
            && this.props.logoutStatus !== LOADING_STATUS_SUCCESS
            && this.props.logoutStatus !== LOADING_STATUS_FAILURE

        return (
            <DocumentTitle title={`${this.props.t("Login")} | ${this.props.t("SRP My Account")}`}>
                {!customerIsCompletingAccountCreation && !customerIsUpdatingLoginEmail
                    && !customerIsLoggingOut && !this.state.customerLoggedInJustNow
                    ? <LoginPage
                        getResponsiveLoginTabValue={this.props.getResponsiveLoginTabValue}
                        loginResponsiveTab={this.props.loginResponsiveTab}
                    /> : null
                }
            </DocumentTitle>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.accountSetup, ...state.login,
        billAccountListStatus: state.accountInfo.billAccount.billAccountListStatus
    }
}

const mapDispatchToProps = dispatch => {
    return {
        completeCreateAccount: (e, t) => {
            dispatch(completeCreateAccount(e, t))
        },
        completeLoginEmailUpdate: (oldEmail, newEmail, token) => {
            dispatch(completeLoginEmailUpdate(oldEmail, newEmail, token))
        },
        getResponsiveLoginTabValue: (evt, val) => {
            dispatch(getResponsiveLoginTabValue(evt, val))
        },
        beginLogout: () => {
            dispatch(beginLogout())
        },
        getBillAccounts: () => {
            dispatch(getBillAccounts())
        },
        loginAccountWasDeleted: () => {
            dispatch(loginWasDeleted())
        }
    }
}

LoginPageContainer.propTypes = {
    beginLogout: PropTypes.func,
    billAccountListStatus: PropTypes.string.isRequired,
    completeCreateAccount: PropTypes.func.isRequired,
    completeLoginEmailUpdate: PropTypes.func.isRequired,
    completeCreateAccountStatus: PropTypes.string,
    completeCreateAccountResult: PropTypes.object,
    completeLoginUpdateStatus: PropTypes.string,
    getBillAccounts: PropTypes.func,
    getResponsiveLoginTabValue: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    loginAccountWasDeleted: PropTypes.func,
    loginProfileStatus: PropTypes.string,
    loginResponsiveTab: PropTypes.number,
    loginStatus: PropTypes.string,
    logoutStatus: PropTypes.string,
    setMaintenanceTest: PropTypes.func,
    router: PropTypes.shape({
        location: PropTypes.object,
        navigate: PropTypes.func
    }),
    t: PropTypes.func
}

export default withTranslation('login')(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPageContainer)))