import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { withTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import Icon from '@mui/material/Icon'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'

import AnalyticsFormTracking from '../../common_formik/analytics-form-tracking'

import EVCommunitySignupConfirmation from './ev-community-signup-confirmation'
import EVCommunityVINValidation from './ev-community-vin-validation'

import * as loadingStatus from '../../../constants/loading-status-constants'
import { evCommunityConstants } from '../../../constants/ev-community-constants'

import validate from '../../../srp_modules/validate'


const EVCommunityFormikPure = ({ customerName, formattedBillAccount, serviceAddress, formattedPhoneNumber, emailAddress,
    formType, initialChangedState, onStateChanged, submitEVCommunityForm, submitEVCommunityFormStatus, t, canApply, hasNoCredits, unApprovedVehicleTypes }) => {

    let emailRef = useRef()

    return (
        <Formik
            initialValues={{
                formType: formType,
                emailAddress: emailAddress,
                vinNumber: '',
                vinChecked: false,
                vinPassesCheck: false,
                vehicleInfo: {
                    make: '',
                    model: '',
                    year: '',
                    isEV: false
                },
                newUsedVehicle: '',
                chargerLevel: '',
                certified: false,
                taCCollapsed: false,
                shouldScroll: false
            }}
            validate={validateForm}
            onSubmit={(values, actions) => {
                submitEVCommunityForm(values, actions)
            }}>
            {({ values, errors, status, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setStatus, resetForm }) => {
                let certifiedError = touched.certified && errors.notCertified
                let noLevelSelectedError = touched.chargerLevel && errors.noLevelSelected
                let noNewUsedError = touched.newUsedVehicle && errors.noNewUsed

                let emailError = touched.emailAddress && errors.email !== undefined
                let invalidEmailError = touched.emailAddress && errors.invalidEmail !== undefined

                let submitErrors = status?.submitErrors?.submitFailed || status?.submitErrors?.emailFailed

                let vinAlreadyUsed = status?.submitErrors?.registrationErrorVINAlreadyUsed
                let vinPreviouslyNew = status?.submitErrors?.registrationErrorPreviouslyNew
                let registrationErrors = status?.submitErrors?.registrationErrorUnknown || vinAlreadyUsed || vinPreviouslyNew

                let formSubmittedSuccessfully =
                    status && submitEVCommunityFormStatus === loadingStatus.LOADING_STATUS_SUCCESS && !submitErrors && !registrationErrors

                let vehicleIsEligible = values.vinChecked && values.vinPassesCheck && values.vehicleInfo && values.vehicleInfo.isEV && !values.vehicleInfo.isUnApprovedVehicleType && !registrationErrors

                if (values.shouldScroll && (emailError || invalidEmailError)) {
                    values.shouldScroll = false
                    emailRef.current.scrollIntoView()
                }

                let requiredError = (
                    <div className="ml-1" style={{ "color": "#9E2A2B" }}>
                        {t("Required")}
                    </div>
                )

                return (
                    <div>
                        <AnalyticsFormTracking
                            name="EV Community Sign-Up"
                            formStep="sign up for EV community"
                            onStateChanged={onStateChanged}
                            initialChangedState={initialChangedState} />

                        {
                            !formSubmittedSuccessfully
                                ? <React.Fragment>
                                    <React.Fragment>
                                        {canApply && !hasNoCredits
                                            ? <div className="srp-card-details">
                                                <div>
                                                    <p className="srp-dark-blue-color font-weight-bold">
                                                        {t("EV_Join_Now")}
                                                    </p>
                                                    <p>
                                                        {t("EV_Part_of_Community")}
                                                    </p>
                                                    <p>
                                                        {t("EV_Fill_Out_To_Get_Started")}
                                                    </p>
                                                    <p>
                                                        {t("Validate_Your_Vehicle")}
                                                    </p>
                                                </div>
                                            </div>
                                            : null}
                                    </React.Fragment>

                                    <EVCommunityVINValidation
                                        setFieldValue={setFieldValue}
                                        setStatus={setStatus}
                                        resetForm={resetForm}
                                        hasCheckedVIN={values.vinChecked}
                                        hideCheckVINButton={!canApply || vehicleIsEligible}
                                        canApply={canApply}
                                        hasNoCredits={hasNoCredits}
                                        vinAlreadyUsed={vinAlreadyUsed}
                                        vinPreviouslyNew={vinPreviouslyNew}
                                        unApprovedVehicleTypes={unApprovedVehicleTypes}
                                    />

                                    {!registrationErrors && vehicleIsEligible
                                        ? <React.Fragment>
                                            <Divider />

                                            <form
                                                className="mb-2 mt-2 text-muted"
                                                onSubmit={handleSubmit}
                                            >
                                                <div className="srp-card-details">
                                                    <div ref={emailRef} >
                                                        <h4 className="mb-3" style={{ color: 'rgba(51, 51, 51, 0.537)' }}>{t("Account_Details")}</h4>

                                                        <div className="d-lg-block d-none mb-3">
                                                            <div>
                                                                <span className="font-weight-bold mr-2">{t("Name")}</span>
                                                                <span>{customerName}</span>
                                                            </div>
                                                            <div>
                                                                <span className="font-weight-bold mr-2">{t("SRP_Account_Number")}</span>
                                                                <span>{formattedBillAccount}</span>
                                                            </div>
                                                            <div>
                                                                <span className="font-weight-bold mr-2">{t("Service_Address")}</span>
                                                                <span>{serviceAddress.fullStreetAddress}</span>
                                                            </div>
                                                            <div>
                                                                <span className="font-weight-bold mr-2">{t("Phone_Number")}</span>
                                                                <span>{formattedPhoneNumber}</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-lg-none d-block mb-3">
                                                            <div className="row">
                                                                <div className="col-12 font-weight-bold">{t("Name")}</div>
                                                                <div className="col-12">{customerName}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 font-weight-bold">{t("SRP_Account_Number")}</div>
                                                                <div className="col-12">{formattedBillAccount}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 font-weight-bold">{t("Service_Address")}</div>
                                                                <div className="col-12">{serviceAddress.streetAddress}</div>
                                                                <div className="col-12">{serviceAddress.cityStateZip}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 font-weight-bold">{t("Phone_Number")}</div>
                                                                <div className="col-12">{formattedPhoneNumber}</div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-4">
                                                            {emailAddress.length > 0
                                                                ? <>
                                                                    <span>{t("Confirmation_Sent_To")}</span>
                                                                    <span className="font-weight-bold"> {emailAddress}</span>
                                                                </>
                                                                : <div>
                                                                    <TextField
                                                                        fullWidth
                                                                        name="emailAddress"
                                                                        value={values.emailAddress}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        helperText={
                                                                            emailError
                                                                                ? t(errors.email)
                                                                                : invalidEmailError
                                                                                    ? t(errors.invalidEmail)
                                                                                    : ''}
                                                                        error={emailError || invalidEmailError}
                                                                        label={t("Confirmation email address")} />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="mb-3">
                                                            <div className="text-muted pl-1 mt-1">{t("New_or_Used")}</div>
                                                            {noNewUsedError ? requiredError : null}
                                                            <FormControl
                                                                className="pt-1"
                                                            >
                                                                <RadioGroup
                                                                    id="newUsedVehicle"
                                                                    name="newUsedVehicle"
                                                                    value={values.newUsedVehicle}
                                                                    onChange={handleChange}
                                                                >
                                                                    <FormControlLabel
                                                                        value={"new"}
                                                                        control={
                                                                            <Radio
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        className="ml-2"
                                                                        style={{ marginBottom: '0' }}
                                                                        label={<span className="checkmark-label-blue">{t("Vehicle_New")}</span>}
                                                                    />
                                                                    <FormControlLabel
                                                                        value={"used"}
                                                                        control={
                                                                            <Radio
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        className="ml-2"
                                                                        style={{ marginBottom: '0' }}
                                                                        label={<span className="checkmark-label-blue">{t("Vehicle_Used")}</span>}
                                                                    />
                                                                </RadioGroup>
                                                            </FormControl>

                                                        </div>
                                                        <div className="mb-4">
                                                            <div className="text-muted pl-1 mt-2">{t("Vehicle_Charger")}</div>
                                                            {noLevelSelectedError ? requiredError : null}
                                                            <FormControl
                                                                className="pt-1"
                                                            >
                                                                <RadioGroup
                                                                    id="chargerLevel"
                                                                    name="chargerLevel"
                                                                    value={values.chargerLevel}
                                                                    onChange={handleChange}
                                                                >
                                                                    <FormControlLabel
                                                                        value={evCommunityConstants.CHARGER_LEVEL_1}
                                                                        control={
                                                                            <Radio
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        className="ml-2"
                                                                        style={{ marginBottom: '0' }}
                                                                        label={<span className="checkmark-label-blue">{t("Charger_Level_1")}</span>}
                                                                    />
                                                                    <FormControlLabel
                                                                        value={evCommunityConstants.CHARGER_LEVEL_2}
                                                                        control={
                                                                            <Radio
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        className="ml-2"
                                                                        style={{ marginBottom: '0' }}
                                                                        label={<span className="checkmark-label-blue">{t("Charger_Level_2")}</span>}
                                                                    />
                                                                </RadioGroup>
                                                            </FormControl>

                                                        </div>
                                                        <div className="srp-alert-notice">
                                                            <div className="d-flex align-items-center">
                                                                <button type="button" className="font-weight-bold displayAsLink" onClick={() => toggleTermsAndConditions(setFieldValue, values.taCCollapsed)}>
                                                                    <Icon> {values.taCCollapsed ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} </Icon>
                                                                </button>
                                                                <div className="pl-1 font-weight-bold">
                                                                    {t("TaC_Review")}
                                                                </div>
                                                            </div>
                                                            {!values.taCCollapsed
                                                                ? <div className="ml-2 mt-1">
                                                                    <ul>
                                                                        <li>{t("TaC_Reside")}</li>
                                                                        <li>{t("TaC_Own_Lease")}</li>
                                                                        <li>{t("TaC_Registered")}</li>
                                                                        <li>{t("TaC_Highway_Speeds")}</li>
                                                                        <li>{t("TaC_Employees")}</li>
                                                                        <li>{t("TaC_Limited_Credits")}</li>
                                                                    </ul>
                                                                </div>
                                                                : null}
                                                        </div>
                                                        <div className="clearfix" style={{ position: "relative", marginTop: "15px", marginBottom: "15px" }}>
                                                            <div className="d-lg-block d-none">
                                                                <div style={{ float: "left", width: "15%" }}>
                                                                    <Checkbox
                                                                        name="certified"
                                                                        checked={values.certified}
                                                                        onChange={handleChange}
                                                                        color="primary"
                                                                    />
                                                                    {certifiedError ? requiredError : null}
                                                                </div>
                                                                <div style={{ float: "right", width: "85%" }} className="credit-advance-details-acknowledgement">
                                                                    {t("Certify_Information")}
                                                                </div>
                                                            </div>
                                                            <div className="d-lg-none d-block">
                                                                <div style={{ float: "left", width: "25%" }}>
                                                                    <Checkbox
                                                                        name="certified"
                                                                        checked={values.certified}
                                                                        onChange={handleChange}
                                                                        color="primary"
                                                                    />
                                                                    {certifiedError ? requiredError : null}
                                                                </div>
                                                                <div style={{ float: "right", width: "75%" }} className="credit-advance-details-acknowledgement">
                                                                    {t("Certify_Information")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {submitEVCommunityFormStatus === loadingStatus.LOADING_STATUS_FAILURE
                                                        ? <div className="srp-alert-error mb-4">{t("Something_Went_Wrong")}</div>
                                                        : null
                                                    }
                                                    {status?.submitErrors?.submitFailed
                                                        ? <div className="srp-alert-error mb-4">{t("Submission_Failed")}</div>
                                                        : null}
                                                    {status?.submitErrors?.emailFailed
                                                        ? <div className="srp-alert-error mb-4">{t("Email_Failed")}</div>
                                                        : null}

                                                    <div className="d-flex flex-wrap justify-content-end">
                                                        <Link
                                                            className="btn srp-btn btn-lightblue"
                                                            type="button"
                                                            to="/myaccount/programs">
                                                            {t("EV_Go_Back")}
                                                        </Link>
                                                        <button
                                                            className="btn srp-btn btn-green ml-2"
                                                            type="submit"
                                                            disabled={emailError
                                                                || invalidEmailError
                                                                || submitEVCommunityFormStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}>
                                                            {submitEVCommunityFormStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                                                                ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                                                                : t("EV_Submit")
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </React.Fragment>
                                        : null
                                    }
                                </React.Fragment>
                                : <EVCommunitySignupConfirmation formType={formType} />
                        }
                    </div>
                )
            }}
        </Formik>
    )
}

EVCommunityFormikPure.propTypes = {
    customerName: PropTypes.string,
    formattedBillAccount: PropTypes.string,
    serviceAddress: PropTypes.object,
    formattedPhoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
    submitEVCommunityForm: PropTypes.func,
    submitEVCommunityFormStatus: PropTypes.string,
    formType: PropTypes.number,
    history: PropTypes.object,
    initialChangedState: PropTypes.bool,
    onStateChanged: PropTypes.func,
    canApply: PropTypes.bool,
    hasNoCredits: PropTypes.bool,
    unApprovedVehicleTypes: PropTypes.object,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired
}

const EVCommunityFormik = withTranslation("evCommunitySignupForm")(EVCommunityFormikPure)
export { EVCommunityFormik as default, EVCommunityFormikPure }

function toggleTermsAndConditions(setFieldValue, currentValue) {
    setFieldValue('taCCollapsed', !currentValue)
}

function validateForm(values) {
    let errors = {}

    if (values.emailAddress === undefined || values.emailAddress.length === 0) {
        values.shouldScroll = true
        errors.email = "Email_Address_Required"
    }
    if (!validate.emailAddress(values.emailAddress)) {
        values.shouldScroll = true
        errors.invalidEmail = "Email_Address_Error"
    }
    if (values.certified === false)
        errors.notCertified = true
    if (values.chargerLevel === '')
        errors.noLevelSelected = true
    if (values.newUsedVehicle === '')
        errors.noNewUsed = true

    return errors
}