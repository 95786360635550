import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import { withRouter } from '../../../srp_modules/with-router'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'

import { deleteLogin } from '../../../actions/auth/login/login-actions'

class DeleteLoginForm extends React.Component {
    validateDeleteLoginRequest(values) {
        let errors = {}

        if (values.password.length === 0) {
            errors.password = 'Password is required'
        }

        return errors
    }

    render() {
        return (
            <Formik
                initialValues={{
                    password: ''
                }}
                validate={this.validateDeleteLoginRequest}
                onSubmit={this.props.requestDeleteLogin}>
                {({ values, setValues, status, errors, dirty, touched, handleChange, handleBlur, handleSubmit, submitCount, isSubmitting }) => {
                    let formStatus = status || {}
                    let passwordError = (dirty || submitCount > 0) && touched.password && errors.password !== undefined
                    let noPassword = values.password.length === 0

                    return (
                        formStatus.showSuccess ? this.props.router.navigate('/logout?deleted=true', { replace: true }) :
                        <form method="POST" onSubmit={handleSubmit}>
                            <div className="w-100">
                                {formStatus.error ?
                                    (<div className="srp-alert-error">
                                        {formStatus.error}
                                    </div>) : null}
                                <div className="mb-2 srp-dark-blue-color font-weight-bold font-18">Delete my login</div>
                                <div className="mb-1">
                                    For security purposes, please provide your password:
                                </div>
                                <div className="mb-2">
                                    <TextField
                                        fullWidth
                                        id="password"
                                        name="password"
                                        label="Password"
                                        type={values.showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        error={passwordError}
                                        helperText={passwordError ? errors.password : null}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            endAdornment:
                                                <IconButton
                                                    color="primary"
                                                    className="button-focus"
                                                    aria-label={values.showPassword ? 'hide password' : 'show password'}
                                                    alt={values.showPassword ? 'hide password button' : 'show password button'}
                                                    onClick={() => setValues({ ...values, showPassword: !values.showPassword })}
                                                    size="large">
                                                    <Icon>{values.showPassword ? 'visibility_off' : 'visibility'}</Icon>
                                                </IconButton>
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <p>
                                        By deleting your login, the following information will be permanently removed from SRP My Account:
                                    </p>
                                    <ul>
                                        <li>Your login email</li>
                                        <ul>
                                            <li>{this.props.loginEmail}</li>
                                        </ul>
                                        <li>All bank accounts associated to the login email</li>
                                        <li>All Power and Water bill accounts associated to the login email</li>
                                    </ul>
                                    <p>
                                        Note: Any notifications associated to your bill account(s) will remain active.
                                    </p>
                                    <p>
                                        This information can only be restored by creating a new My Account login profile.
                                    </p>
                                </div>
                                <div className="row justify-content-end">
                                    <div className="col-lg text-right">
                                        <button type="submit" disabled={isSubmitting || noPassword} className="btn srp-btn btn-green ml-2 mb-2">
                                            {isSubmitting
                                                ? <CircularProgress size={20} thickness={5} color="secondary" />
                                                : "Yes, delete my login data"}
                                        </button>
                                        <button type="button" className="btn srp-btn btn-lightblue ml-2 mb-2" onClick={this.props.setDisplayDeleteLoginForm(false)}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loginEmail: state.login.loginEmail
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestDeleteLogin: async (values, goodies) => {
            let response = await dispatch(deleteLogin(values.password))
            goodies.setSubmitting(false)

            if (response.error === true) {

                if (response.payload && response.payload.status === 403) {
                    goodies.setStatus({ error: 'The login information you provided does not match our records.' })
                    return
                }

                goodies.setStatus({ error: 'There was an unknown error' })
                return
            }
           
            goodies.setStatus({ showSuccess: true })
            
            localStorage.removeItem('rememberUser')
            localStorage.removeItem('rememberUserLogin')
        }
    }
}

DeleteLoginForm.propTypes = {
    loginEmail: PropTypes.string.isRequired,
    requestDeleteLogin: PropTypes.func.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }).isRequired,
    setDisplayDeleteLoginForm: PropTypes.func.isRequired
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteLoginForm))