import React from 'react'
import PropTypes from 'prop-types'
import { DocumentTitle } from '../../../srp_modules/document-title'
import ContactsContainer from './contacts/contacts-container'
import AlertsContainer from './alerts/alerts-container'
import { withTranslation } from 'react-i18next'

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles'
const muiTheme = theme => createTheme({
    ...theme,
    components: {
        MuiSelect: {
            ...theme.components.MuiSelect,
            defaultProps: {
                size: 'small'
            },
            styleOverrides: {
                root: { color: '#087ED8' },
                icon: { color: '#087ED8' }
            }
        }
    }
})

class ContactInfoAndAlertsCard extends React.Component {
    render() {
        return (
            <DocumentTitle title="Contact info & alerts | SRP Power">
                <div className="row mt-3">
                    <div className="col-lg-4 col-12">
                        <ContactsContainer t={this.props.t}/>
                    </div>
                    <div className="col-lg-8 col-12 mt-lg-0 mt-3">
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={muiTheme}>
                                <AlertsContainer t={this.props.t}/>
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </div>
                </div>
            </DocumentTitle>
        )
    }
}

ContactInfoAndAlertsCard.propTypes = {
    t: PropTypes.func.isRequired
}

export default withTranslation("contactInfoAndAlert")(ContactInfoAndAlertsCard)