import React from 'react'
import PropTypes from 'prop-types'

import Card from "@mui/material/Card"
import { withTranslation } from 'react-i18next'

import CheckDiagramMobile from '../../images/payment/check-diagram-mobile.png'
import CheckDiagramMobileEs from '../../images/payment/check-diagram-mobile-es.png'
import * as loadingStatus from '../../constants/loading-status-constants'

import '../../styles/autopay-page.css'
import AccountInfoBarContainer from '../myaccount_header/info_bar/account-info-bar-container'
import AutopayStep from '../common_autopay/autopay-step'
import AutopaySrpAcctInfo from '../common_autopay/autopay-srp-acct-info'
import CallAnytime from '../myaccount_header/call-anytime'
import EditAutopayFormik from './edit-autopay-formik'
import CardLoading from '../../components/dashboard_page/card-loading'

import { myAccountConstants } from '../../constants/myaccount-constants'

const EditAutopayPage = ({ autopayInfo, autopayInfoStatus, billAccount, selectBillAccountStatus, customerName,
    serviceAddress, t, i18n, isResidential, isPendingRelease }) => {
    const showSpanish = i18n.language==="es"

    const helpPhoneNumber = isResidential
        ? showSpanish
            ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
            : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
        : myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD

    const helpText =
        (<>
            {
                isResidential
                ? t("For_questions_please_contact_SRP", {residentialOrCommercial: "Residential", supportNumber: helpPhoneNumber})
                : t("For_questions_please_contact_SRP_commercial", {supportNumber: helpPhoneNumber})
            }
        </>)

    return (
        <div>
            <div className="container py-3 hidden-sm-down">
                <CallAnytime />

                <h3 className="mt-3">{t("Edit SurePay")}</h3>

                <AccountInfoBarContainer disableSelector={true} billAccount={billAccount} />

                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <div>
                            <label className="h5">{t("Here's your SurePay details")}</label>
                            {autopayInfoStatus !== loadingStatus.LOADING_STATUS_SUCCESS && autopayInfoStatus !== loadingStatus.LOADING_STATUS_FAILURE
                                && selectBillAccountStatus !== loadingStatus.LOADING_STATUS_SUCCESS && selectBillAccountStatus !== loadingStatus.LOADING_STATUS_FAILURE
                                ? (<CardLoading title={t("SurePay")} />) :
                                (
                                    <Card className="px-4 pb-4">
                                        <AutopayStep activeStep={1} t={t} />
                                        {autopayInfo.hasPendingEnrollment && <div className="srp-alert-notice" style={{ backgroundColor: '#F4E7CC', marginBottom: '5px'}}>
                                                <span style={{ fontWeight: "bold" }}>{t("Attention")}: </span>
                                                {t("Recently submitted SurePay enrollment is pending.")}
                                            </div>
                                        }
                                        {autopayInfo.hasPendingChange && !autopayInfo.hasPendingEnrollment && <div className="srp-alert-notice" style={{ backgroundColor: '#F4E7CC', marginBottom: '5px'}}>
                                                <span style={{ fontWeight: "bold" }}>{t("Attention")}: </span>
                                                {t("Recently submitted SurePay changes are pending.")}
                                            </div>
                                        }
                                        {!isPendingRelease
                                           ? <div className="srp-alert-notice">
                                                <span style={{ fontWeight: "bold" }}>{t("NOTE")}: </span>
                                                {t("two_business_days_to_process")}{' '}
                                                {helpText}
                                            </div>
                                            : <div className="srp-alert-warn">
                                                <span style={{ fontWeight: "bold" }}>{t("ATTENTION")} </span>
                                                {t("within_2_days_of_SurePay_payment_date")}{' '}
                                                {helpText}
                                            </div>
                                        }

                                        <AutopaySrpAcctInfo
                                            billAccount={billAccount}
                                            customerName={customerName}
                                            serviceAddress={serviceAddress}
                                            t={t}
                                        />

                                        <EditAutopayFormik
                                            autopayInfo={autopayInfo}
                                            showSpanish={showSpanish}
                                        />
                                    </Card>
                                )}
                        </div>
                    </div>

                    <div className="col-lg-6 mt-3 d-none d-lg-block">
                        <label className="h5">{t("Need help?")}</label>
                        <Card>
                            <div className="srp-alert-warn">
                                <div className="pt-2 pb-2">
                                    {helpText}
                                </div>
                                <Card>
                                    <img src={showSpanish ? CheckDiagramMobileEs : CheckDiagramMobile} className="mx-auto d-block img-fluid" />
                                </Card>
                            </div>
                        </Card>
                    </div>

                </div>
            </div>
        </div>
    )
}

EditAutopayPage.propTypes = {
    autopayInfo: PropTypes.object.isRequired,
    autopayInfoStatus: PropTypes.string.isRequired,
    billAccount: PropTypes.number.isRequired,
    selectBillAccountStatus: PropTypes.string.isRequired,
    customerName: PropTypes.string.isRequired,
    serviceAddress: PropTypes.shape({
        streetAddress: PropTypes.string.isRequired,
        cityStateZip: PropTypes.string.isRequired
    }).isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    isResidential: PropTypes.bool.isRequired,
    isPendingRelease: PropTypes.bool.isRequired
}

export default withTranslation("addUpdateSurePay")(EditAutopayPage)