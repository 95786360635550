import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import { withTranslation } from 'react-i18next'

import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'

import SafetyNetSignupConfirmation from './safety-net-signup-confirmation'
import AnalyticsFormTracking from '../../common_formik/analytics-form-tracking'
import AddressWithAutoComplete from '../../address-with-autocomplete'

import * as loadingStatus from '../../../constants/loading-status-constants'

import validate from '../../../srp_modules/validate'
import format from '../../../srp_modules/format'
import formikInputFilter from '../../../srp_modules/formik-input-filter'

import config from 'my-account-config'

const SafetyNetSignupFormik = ({ customerName, billAccount, serviceAddress, formattedPhoneNumber, emailAddress, safetyNetProgramInfo,
    getSafetyNetDetails, submitSafetyNetFormStatus, initialChangedState, onStateChanged, formLanguage, t }) => {

    return (
        <Formik
            initialValues={{
                firstName: customerName.firstName,
                lastName: customerName.lastName,
                emailAddress: emailAddress,
                billAccount: billAccount,
                partnerName: safetyNetProgramInfo.safetyNetsName || "",
                streetAddress: safetyNetProgramInfo.streetAddress || "",
                zipCode: safetyNetProgramInfo.zipCode || "",
                city: safetyNetProgramInfo.city || "",
                state: safetyNetProgramInfo.state || "",
                isAgreementAckd: false
            }}
            validate={validateForm}
            onSubmit={(values, actions) => {
                getSafetyNetDetails(values, actions)
            }}>
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, status }) => {
                let formStatus = status || {}
                let emailAddressError = touched.emailAddress && errors.emailAddress !== undefined
                let invalidEmailError = touched.emailAddress && errors.invalidEmailAddress !== undefined
                let partnerNameError = touched.partnerName && errors.partnerName !== undefined
                let streetAddrError = touched.streetAddress && errors.streetAddress !== undefined
                let cityError = touched.city && errors.city !== undefined
                let stateError = touched.state && errors.state !== undefined
                let zipCodeError = touched.zipCode && errors.zipCode !== undefined
                let agreementError = touched.isAgreementAckd && errors.isAgreementAckd !== undefined

                let validateAlphaOnly = (value, fieldName) => {
                    if (value.match(formikInputFilter.regex.alphaRegex)) {
                        setFieldValue(fieldName, value, true)
                    }
                }

                let handleAutocompleteAddress = (components) => {
                    setFieldValue("streetAddress", `${components.streetNumber} ${components.street}`, true)
                    setFieldValue("zipCode", components.zipCode, true)
                    setFieldValue("city", components.city, true)
                    setFieldValue("state", components.state, true)
                }

                let validateAddress = (address, fieldName) => {
                    if (address.match(formikInputFilter.regex.streetAddressRegex)) {
                        setFieldValue(fieldName, address, true)
                    }
                }

                let validateZipCode = (zipCode) => {
                    const alphaNumSpaces = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/
                    if (alphaNumSpaces.test(zipCode) || zipCode.length == 0) {
                        setFieldValue('zipCode', zipCode, true)
                    }
                }

                return (
                    <div>
                        <AnalyticsFormTracking
                            name="SRP Safety Net Sign-Up"
                            formStep={"sign-up"}
                            meta={{preferredLanguage: formLanguage}}
                            onStateChanged={onStateChanged}
                            initialChangedState={initialChangedState} />

                        {submitSafetyNetFormStatus !== loadingStatus.LOADING_STATUS_SUCCESS
                            ?
                            <form
                                className="srp-card-body text-muted"
                                onSubmit={handleSubmit}
                            >
                                <div className="srp-card-details">
                                    {formStatus.submitError
                                        ? <div className="srp-alert-error mb-4">{t("We're sorry, something went wrong. Please try again later")}.</div>
                                        : null
                                    }
                                    <div className="mb-2 srp-dark-blue-color font-weight-bold font-18">{t("How it works")}</div>
                                    <div className="mb-4">{t("how_it_works_description")}</div>

                                    <div className="mb-2 srp-dark-blue-color font-weight-bold font-18">{t("How to sign up")}</div>
                                    <div className="font-weight-bold">{t("To add someone to your Safety Net")}:</div>
                                    <div className="mb-4">{t("how_to_add_description")}</div>

                                    <div className="font-weight-bold">{t("To join someone else's Safety Net")}:</div>
                                    <div className="mb-2">{t("how_to_join_description")}</div>

                                    <div className="pt-2 mb-2">
                                        <span>{`${t("Safety Net is a notification service only")} `}</span>
                                        <span>
                                            <a href={t("release_form_link", { srpnetBaseUrl: config.srpnetBaseUrl })} target="_blank">{t("account information release form")}</a>.
                                        </span>
                                    </div>
                                </div>

                                <Divider alt="" />

                                <div className="srp-card-details">
                                    <div className="mb-2 font-24 fw-500">{t("Account Details")}</div>
                                    <div className="d-lg-block d-none mb-3">
                                        <div>
                                            <label className="font-weight-bold mr-2 mb-0">{t("Name")}</label>
                                            <span>{customerName.displayName}</span>
                                        </div>
                                        <div>
                                            <label className="font-weight-bold mr-2 mb-0">{t("SRP account number")}</label>
                                            <span>{format.formatBillAccountNumber(billAccount)}</span>
                                        </div>
                                        <div>
                                            <label className="font-weight-bold mr-2 mb-0">{t("Service address")}</label>
                                            <span>{serviceAddress.fullStreetAddress}</span>
                                        </div>
                                        <div>
                                            <label className="font-weight-bold mr-2 mb-0">{t("Phone number")}</label>
                                            <span>{formattedPhoneNumber}</span>
                                        </div>
                                    </div>
                                    <div className="d-lg-none d-block mb-3">
                                        <div className="row mb-3">
                                            <label className="col-12 font-weight-bold mb-0">{t("Name")}</label>
                                            <div className="col-12">{customerName.displayName}</div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="col-12 font-weight-bold mb-0">{t("SRP account number")}</label>
                                            <div className="col-12">{format.formatBillAccountNumber(billAccount)}</div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="col-12 font-weight-bold mb-0">{t("Service address")}</label>
                                            <div className="col-12">{serviceAddress.streetAddress}</div>
                                            <div className="col-12">{serviceAddress.cityStateZip}</div>
                                        </div>
                                        <div className="row">
                                            <label className="col-12 font-weight-bold mb-0">{t("Phone number")}</label>
                                            <div className="col-12">{formattedPhoneNumber}</div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        {/*  */}
                                        {emailAddress.length > 0
                                            ? <>
                                                <span className="mb-0">{t("A confirmation will be sent to")}</span>
                                                <span className="font-weight-bold"> {emailAddress}</span>
                                              </>
                                            :
                                            <div>
                                                <TextField
                                                    fullWidth
                                                    name="emailAddress"
                                                    value={values.emailAddress}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={
                                                        emailAddressError
                                                            ? t(errors.emailAddress)
                                                            : invalidEmailError
                                                                ? t(errors.invalidEmailAddress)
                                                                : ''}
                                                    error={emailAddressError || invalidEmailError}
                                                    label={t("Confirmation email address")}
                                                />
                                            </div>
                                        }
                                    </div>

                                    {/* <label className="mb-0">{t("Safety Net partner name")}</label> */}
                                    <TextField
                                        fullWidth
                                        name="partnerName"
                                        className="mb-3"
                                        value={values.partnerName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inputProps={{ maxLength: 30 }}
                                        helperText={
                                            partnerNameError
                                                ? t(errors.partnerName)
                                                : ''}
                                        error={partnerNameError} 
                                        label={t("Safety Net partner name")}
                                    />

                                    {/* <label className="mb-0">{t("Safety Net partner mailing address")}</label> */}
                                    <AddressWithAutoComplete
                                        fieldName="streetAddress"
                                        fieldValue={values.streetAddress}
                                        handleAddressChange={(e) => validateAddress(e.target.value, 'streetAddress')}
                                        handleAddressBlur={handleBlur}
                                        errorText={streetAddrError ? t(errors.streetAddress) : ''}
                                        hasError={streetAddrError}
                                        updateAddressCallback={handleAutocompleteAddress}
                                        isDomesticAddress={false} 
                                        label={t("Safety Net partner mailing address")}
                                    />
                                    <div className="row mt-2">
                                        <div className="col-lg-4 col-12 d-flex flex-wrap">
                                            {/* <label className="mb-0">{t("ZIP code")}</label> */}
                                            <TextField
                                                fullWidth
                                                className="mb-3"
                                                name="zipCode"
                                                inputProps={{ maxLength: 9 }}
                                                value={values.zipCode}
                                                onChange={(e) => validateZipCode(e.target.value)}
                                                onBlur={handleBlur}
                                                helperText={zipCodeError ? t(errors.zipCode) : ''}
                                                error={zipCodeError}
                                                label={t("ZIP code")}
                                            />
                                        </div>
                                        <div className="col-lg-4 col-12 d-flex flex-wrap">
                                            {/* <label className="mb-0">{t("City")}</label> */}
                                            <TextField
                                                fullWidth
                                                className="mb-3"
                                                name="city"
                                                inputProps={{ maxLength: 30 }}
                                                value={values.city}
                                                onChange={(e) => validateAddress(e.target.value, 'city')}
                                                onBlur={handleBlur}
                                                helperText={cityError ? t(errors.city) : ''}
                                                error={cityError}
                                                label={t("City")}
                                            />
                                        </div>
                                        <div className="col-lg-4 col-12 d-flex flex-wrap">
                                            {/* <label className="mb-0">{t("State")}</label> */}
                                            <TextField
                                                fullWidth
                                                className="mb-3"
                                                name="state"
                                                inputProps={{ maxLength: 2 }}
                                                value={values.state}
                                                onChange={(e) => validateAlphaOnly(e.target.value, 'state')}
                                                onBlur={handleBlur}
                                                helperText={stateError ? t(errors.state) : ''}
                                                error={stateError}
                                                label={t("State")}
                                            />
                                        </div>
                                    </div>

                                    <div className="font-weight-bold">{t("I agree to the following")}:</div>
                                    <div className="mb-2">{t("agreement_statement")}</div>
                                    <FormControlLabel
                                        className="mb-4"
                                        control={<Checkbox
                                            id="isAgreementAckd"
                                            name="isAgreementAckd"
                                            checked={values.isAgreementAckd}
                                            onChange={handleChange}
                                            color="primary" />}
                                        label={<span className={`font-weight-bold ${agreementError ? "srp-red-text" : "mui-agreement-checkbox-label"}`}>
                                            {t("I agree")} {agreementError ? `*${t(errors.isAgreementAckd)}` : ""}
                                        </span>} />

                                    <div className="d-flex flex-wrap justify-content-end">
                                        <Link
                                            className="btn srp-btn btn-lightblue"
                                            to="/myaccount/programs">
                                            {t("Back to Price plan & programs")}
                                        </Link>
                                        <button
                                            className="btn srp-btn btn-green ml-2"
                                            type="submit"
                                            disabled={submitSafetyNetFormStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}>
                                            {submitSafetyNetFormStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                                                ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                                                : t("Submit application")
                                            }
                                        </button>
                                    </div>
                                </div>
                            </form>
                            : <SafetyNetSignupConfirmation />
                        }
                    </div>
                )
            }}
        </Formik >
    )
}

SafetyNetSignupFormik.propTypes = {
    customerName: PropTypes.object,
    billAccount: PropTypes.number,
    serviceAddress: PropTypes.object,
    formattedPhoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
    getSafetyNetDetails: PropTypes.func,
    submitSafetyNetFormStatus: PropTypes.string,
    initialChangedState: PropTypes.bool,
    onStateChanged: PropTypes.func,
    safetyNetProgramInfo: PropTypes.object,
    formLanguage: PropTypes.string,
    t: PropTypes.func
}

export default withTranslation('safetyNetSignupForm')(SafetyNetSignupFormik)

function validateForm(values) {
    let errors = {}

    if (values.emailAddress.length === 0)
        errors.emailAddress = "Email address is required"
    if (!validate.emailAddress(values.emailAddress))
        errors.invalidEmailAddress = "Please enter a valid email address"

    if (values.partnerName === undefined || values.partnerName === "")
        errors.partnerName = "Required"

    if (values.isAgreementAckd === undefined || !values.isAgreementAckd)
        errors.isAgreementAckd = "Required"

    if (values.streetAddress === undefined || values.streetAddress === "")
        errors.streetAddress = "Required"

    if (values.city === undefined || values.city === "")
        errors.city = "Required"

    if (values.state === undefined || values.state === "")
        errors.state = "Required"

    if (values.zipCode === undefined || values.zipCode === "")
        errors.zipCode = "Required"

    return errors
}