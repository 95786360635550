import React from 'react'
import { Route, Routes } from 'react-router-dom'

import App from './components/app'
import BlankPageTest from './components/blank-page-test'
import MainLayout from './components/main-layout'
import DashboardPageContainer from './components/dashboard_page/dashboard-page-container'
import MyBillPageContainer from './components/bill_page/bill-page-container'
import LandlordPageContainer from './components/landlord_page/landlord-page-container'
import UsagePage from './components/usage_page/usage-page-container'
import OutagesPageContainer from './components/outages_page/outages-page-container'
import ReportOutagePageContainer from './components/outages_page/report-outage-page-container'
import PricingAndProgramsContainer from './components/price_plan_programs_page/price-plan-and-programs-page-container'
import ProfilePage from './components/profile_settings_page/profile-and-settings-page-container'
import LoginPageContainer from './components/login_page/login-page-container'
import LoginRequiredContainer from './containers/login-required-container'
import PasswordResetPageContainer from './components/login_page/password_reset/password-reset-page-container'
import PasswordResetEmailConfirmationPageContainer from './components/login_page/password_reset/password-reset-email-confirmation-page-container'
import PasswordResetValidateNewPasswordContainer from './components/login_page/password_reset/password-reset-validate-new-password-container'
import AccountSetupPageContainer from './components/login_page/account_setup/account-setup-page-container'
import ConfirmSetupPage from './components/login_page/account_setup/confirm-setup-page'
import SingleOrMultiPayment from './components/payment_page/single_or_multi_payment'
import SinglePaymentPageContainer from './components/single_payment_page/single-payment-page-container'
import GuestPaymentPageContainer from './components/guest_payment_page/input_page/guest-payment-page-container'
import GuestPaymentConfirmationPage from './components/guest_payment_page/confirmation_page/guest-payment-confirmation-page'
import GuestPaymentSubmittedPage from './components/guest_payment_page/submitted_page/guest-payment-submitted-page'
import GuestPaymentLockedPage from './components/guest_payment_page/locked_page/guest-payment-locked-page'

import GiftPaymentPageContainer from './components/gift_payment_page/input_page/gift-payment-page-container'
import GiftPaymentConfirmationPage from './components/gift_payment_page/confirmation_page/gift-payment-confirmation-page'
import GiftPaymentSubmittedPage from './components/gift_payment_page/submitted_page/gift-payment-submitted-page'

import SinglePurchasePageContainer from './components/single_purchase_page/input_page/single-purchase-page-container'
import SinglePurchaseConfirmationPage from './components/single_purchase_page/confirmation_page/single-purchase-confirmation-page'
import SinglePurchaseSubmittedPage from './components/single_purchase_page/submitted_page/single-purchase-submitted-page'

import PayUsingBankAccountLoginPage from './components/guest_payment_page/input_page/pay-by-bank-account-login-page'

import MultiPaymentPageContainer from './components/payment_page/multi_payment/multi-payment-page-container'
import VerifyMultiPaymentPageContainer from './components/payment_page/multi_payment/verify-multi-payment-page-container'
import SubmittedMultiSubmittedPageContainer from './components/payment_page/multi_payment/submitted-multi-payment-page-container'

import MultiPaymentNgpPageContainer from './components/payment_page/multi_payment_ngp/multi-payment-ngp-page-container'
import VerifyMultiPaymentNgpPageContainer from './components/payment_page/multi_payment_ngp/verify-multi-payment-ngp-page-container'
import SubmittedMultiPaymentNgpPageContainer from './components/payment_page/multi_payment_ngp/submitted-multi-payment-ngp-page-container'

import ErrorPage from './components/error-page'
import ErrorPageAuthenticated from './components/error-page-authenticated'
import PageNotFound from './components/page-not-found'
import PaymentLocationContainer from './components/payment_location/payment-location-container'
import SinglePaymentConfirmationPage from './components/single_payment_page/confirmation_page/single-payment-confirmation-page'
import SinglePaymentSubmittedPageContainer from './components/single_payment_page/submitted_page/single-payment-submitted-page-container'
import WaterPlaceholder from './components/water-placeholder-page'
import EnrollAutopayPageContainer from './components/enroll_autopay_page/enroll-autopay-page-container'
import VerifyAutopayPageContainer from './components/verify_autopay_page/verify-autopay-page-container'
import AutopaySubmittedPageContainer from './components/autopay_submitted_page/autopay-submitted-page-container'
import AutopayCashOnlyPage from './components/autopay_cash_only_page/autopay-cash-only-page'
import AutopayIneligiblePage from './components/autopay_ineligible_page/autopay-ineligible-page'
import AddBankInfoContainer from './components/profile_settings_page/bank_information/add-bank-info-container'
import EditBankInfoContainer from './components/profile_settings_page/bank_information/edit-bank-info-container'
import EditAutopayPageContainer from './components/edit_autopay_page/edit-autopay-page-container'
import BudgetBillingLandingPage from './components/budget_billing/budget-billing-landing-page'
import StartTransferStop from './components/forms/start-stop-transfer'
import StartServicePage from './components/forms/web_turnon/start-service-page'
import StopServicePage from './components/forms/web_turnoff/stop-service-page'
import TransferServiceContainer from './components/forms/transfer_service/transfer-service-container'
import CustomDueDateContainer from './components/forms/custom_due_date/custom-due-date-container'
import ChangePricePlanContainer from './components/change_price_plan/change-price-plan-container'
import CleanPowerRedirect from './components/clean-power-redirect'
import SolarChoiceSignupContainer from './components/forms/solar-choice/solar-choice-signup-container'
import SolarChoiceChangeContainer from './components/forms/solar-choice/solar-choice-change-container'
import ShareContainer from './components/forms/share/share-container'
import NoBillAccountsAddAccountContainer from './containers/no-bill-accts-add-account/no-bill-accounts-add-account-container'
import EmptyPrint from './components/empty-print'
import BankLockedErrorCard from './components/profile_settings_page/bank_information/bank-locked-error'
import EppFormConfirmation from './components/epp-status/epp-form-confirmation'
import EppApplicationContainer from './components/epp-status/epp-application-container'
import HealthyForestSignUpContainer from './components/forms/healthy_forest/healthy-forest-signup-container'
import HealthyForestChangeContainer from './components/forms/healthy_forest/healthy-forest-change-container'
import SolarNonprofitSignupContainer from './components/forms/solar-nonprofit/solar-nonprofit-signup-container'
import SolarNonprofitChangeContainer from './components/forms/solar-nonprofit/solar-nonprofit-change-container'
import CashPaymentCardComponenent from './components/cash_card_page/cash-payment-card'
import EconomyPricePlanSignupContainer from './components/forms/economy-price-plan/economy-price-plan-signup-container'
import EVCommunitySignupContainer from './components/forms/ev-community/ev-community-signup-container'
import CheckLocalization from './check-localization'
import SafetyNetSigupContainer from './components/forms/safety_net/safety-net-sigup-container'
import MobileVerificationPageContainer from './components/mobile_verification/mobile-verification-page-container'
import AuthorizedPartyContainer from './components/forms/authorized_party/authorized-party-container'

const routes = [
    { path: '/solarchoiceredirect', component: CleanPowerRedirect, preventLocalization: true },
    { path: '/solarestimatorredirect', component: CleanPowerRedirect, preventLocalization: true },
    { path: '/print', component: EmptyPrint, preventLocalization: true },
    { path: '*', component: App, routes: [
        { path: '/blankpagetest', component: BlankPageTest, preventLocalization: true },
        { path: '/mobileverification', component: MobileVerificationPageContainer },
        { path: '/myaccount/cashpaymentcard', component: () => <LoginRequiredContainer><CashPaymentCardComponenent /></LoginRequiredContainer>, preventLocalization: true },
        { path: '/*', component: MainLayout, routes: [
            { path: '/', component: LoginPageContainer, preventLocalization: false },
            { path: '/login', component: LoginPageContainer, preventLocalization: false },
            { path: '/logout', component: LoginPageContainer, preventLocalization: false },

            { path: '/accountSetup', component: AccountSetupPageContainer, preventLocalization: false },
            { path: '/confirmSetup', component: ConfirmSetupPage, preventLocalization: false },
            { path: '/error', component: ErrorPage, preventLocalization: true },
            { path: '/passwordreset', component: PasswordResetPageContainer, preventLocalization: false },
            { path: '/passwordResetEmailConfirmation', component: PasswordResetEmailConfirmationPageContainer, preventLocalization: false },
            { path: '/passwordResetValidateNewPassword', component: PasswordResetValidateNewPasswordContainer, preventLocalization: false },

            { path: '/paymentlocations', component: PaymentLocationContainer, preventLocalization: false },

            // These My Account pages do not require authentication
            { path: '/myaccount/payment/guest', component: GuestPaymentPageContainer },
            { path: '/myaccount/payment/guest/confirmation', component: GuestPaymentConfirmationPage },
            { path: '/myaccount/payment/guest/submitted', component: GuestPaymentSubmittedPage },
            { path: '/myaccount/payment/guest/locked', component: GuestPaymentLockedPage },
            { path: '/myaccount/payment/login', component: PayUsingBankAccountLoginPage, preventLocalization: false },
            { path: '/myaccount/outages', component: OutagesPageContainer, preventLocalization: false },

            { path: '/myaccount/epp/application', component: EppApplicationContainer, preventLocalization: true },

            // Make Find a Payment location page public.
            { path: '/myaccount/paymentlocations', component: PaymentLocationContainer, preventLocalization: false },

            { path: '/myaccount/*', component: LoginRequiredContainer, routes: [
                { path: '/', component: DashboardPageContainer, preventLocalization: false },
                { path: '/addAccount', component: NoBillAccountsAddAccountContainer, preventLocalization: true },
                { path: '/dashboard', component: DashboardPageContainer, preventLocalization: false },
                { path: '/bill', component: MyBillPageContainer, preventLocalization: false },
                { path: '/error', component: ErrorPageAuthenticated, preventLocalization: true },
                { path: '/landlord', component: LandlordPageContainer, preventLocalization: true },
                { path: '/programs', component: PricingAndProgramsContainer, preventLocalization: false },
                { path: '/reportOutage', component: ReportOutagePageContainer, preventLocalization: false },
                { path: '/usage', component: UsagePage, preventLocalization: false },
                { path: '/water', component: WaterPlaceholder, preventLocalization: true },
                { path: '/epp/confirmation', component: EppFormConfirmation },

                { path: '/payment', component: SingleOrMultiPayment, preventLocalization: true },

                { path: '/payment/autopay', component: EnrollAutopayPageContainer, preventLocalization: false },
                { path: '/payment/autopay/cash', component: AutopayCashOnlyPage, preventLocalization: false },
                { path: '/payment/autopay/ineligible', component: AutopayIneligiblePage, preventLocalization: false },
                { path: '/payment/autopay/verify', component: VerifyAutopayPageContainer, preventLocalization: false },
                { path: '/payment/autopay/confirm', component: AutopaySubmittedPageContainer, preventLocalization: false },
                { path: '/payment/autopay/edit', component: EditAutopayPageContainer, preventLocalization: false },

                { path: '/payment/gift', component: GiftPaymentPageContainer, preventLocalization: false },
                { path: '/payment/gift/confirmation', component: GiftPaymentConfirmationPage, preventLocalization: false },
                { path: '/payment/gift/submitted', component: GiftPaymentSubmittedPage, preventLocalization: false },

                { path: '/payment/multi', component: MultiPaymentPageContainer, preventLocalization: false },
                { path: '/payment/multi/cash', component: MultiPaymentPageContainer, preventLocalization: false },
                { path: '/payment/multi/verify', component: VerifyMultiPaymentPageContainer, preventLocalization: false },
                { path: '/payment/multi/submitted', component: SubmittedMultiSubmittedPageContainer, preventLocalization: false },

                { path: '/payment/multi/mpower', component: MultiPaymentNgpPageContainer, preventLocalization: false },
                { path: '/payment/multi/mpower/cash', component: MultiPaymentNgpPageContainer, preventLocalization: false },
                { path: '/payment/multi/mpower/verify', component: VerifyMultiPaymentNgpPageContainer, preventLocalization: false },
                { path: '/payment/multi/mpower/submitted', component: SubmittedMultiPaymentNgpPageContainer, preventLocalization: false },

                { path: '/payment/single', component: SinglePaymentPageContainer, preventLocalization: false },
                { path: '/payment/single/cash', component: SinglePaymentPageContainer, preventLocalization: false },
                { path: '/payment/single/verify', component: SinglePaymentConfirmationPage, preventLocalization: false },
                { path: '/payment/single/submitted', component: SinglePaymentSubmittedPageContainer, preventLocalization: false },

                { path: '/payment/single/mpower', component: SinglePurchasePageContainer, preventLocalization: false },
                { path: '/payment/single/mpower/cash', component: SinglePurchasePageContainer, preventLocalization: false },
                { path: '/payment/single/mpower/confirmation', component: SinglePurchaseConfirmationPage, preventLocalization: false },
                { path: '/payment/single/mpower/submitted', component: SinglePurchaseSubmittedPage, preventLocalization: false },

                { path: '/profile', component: ProfilePage, preventLocalization: true },
                { path: '/profile/account', component: ProfilePage, preventLocalization: true },
                { path: '/profile/alerts', component: ProfilePage, preventLocalization: true },
                { path: '/profile/banks', component: ProfilePage, preventLocalization: true },
                { path: '/profile/banks/addbankaccount', component: AddBankInfoContainer, preventLocalization: false },
                { path: '/profile/banks/editbankaccount', component: EditBankInfoContainer, preventLocalization: false },
                { path: '/profile/banks/locked', component: BankLockedErrorCard, preventLocalization: false },
                { path: '/profile/documents', component: ProfilePage, preventLocalization: true },

                { path: '*', component: PageNotFound, preventLocalization: true } // Must be last
            ]},

            {
                path: '/forms/*', component: LoginRequiredContainer, routes: [
                    { path: '/solarchoice/signup', component: SolarChoiceSignupContainer, preventLocalization: true },
                    { path: '/solarchoice/change', component: SolarChoiceChangeContainer, preventLocalization: true },
                    { path: '/healthyforest/signup', component: HealthyForestSignUpContainer, preventLocalization: true },
                    { path: '/healthyforest/change', component: HealthyForestChangeContainer, preventLocalization: true },
                    { path: '/solarnonprofit/signup', component: SolarNonprofitSignupContainer, preventLocalization: true },
                    { path: '/solarnonprofit/change', component: SolarNonprofitChangeContainer, preventLocalization: true },
                    { path: '/economypriceplan/signup', component: EconomyPricePlanSignupContainer },
                    { path: '/evcommunity/signup', component: EVCommunitySignupContainer, preventLocalization: true },
                    { path: '/safetynet/signup', component: SafetyNetSigupContainer },
                    { path: '/safetynet/change', component: SafetyNetSigupContainer },
                    { path: '/authorizedparty', component: AuthorizedPartyContainer },
                    { path: '*', component: PageNotFound, preventLocalization: true } // Must be last
                ]
            },

            { path: '/budgetbilling', component: BudgetBillingLandingPage, preventLocalization: true },
            { path: '/changepriceplan', component: ChangePricePlanContainer, preventLocalization: true },
            { path: '/customduedate', component: CustomDueDateContainer, preventLocalization: true },
            { path: '/share', component: ShareContainer, preventLocalization: true },

            { path: '/residentialElectric', component: StartTransferStop },
            { path: '/residentialElectric/start', component: StartServicePage },
            { path: '/residentialElectric/*', component: LoginRequiredContainer, routes: [
                { path: '/stop', component: StopServicePage, preventLocalization: true },
                { path: '/transfer', component: TransferServiceContainer, preventLocalization: true },
                { path: '*', component: PageNotFound, preventLocalization: true } // Must be last
            ]},

            { path: '*', component: PageNotFound, preventLocalization: true } // Must be last
        ]}
    ]}
]

// eslint-disable-next-line react/no-multi-comp
const SubRoutesWrapper = (route) => (
    route.routes !== undefined
        ? (
            <route.component {...route.props}>
                <Routes>
                    {route.routes.map((subroute, i) => (
                        <Route key={`route_${i}`} path={subroute.path} element={<SubRoutesWrapper {...subroute} />} />
                    ))}
                </Routes>
            </route.component>
        ) : (
            // NOTE: Localization synchronization only occurs on  "leaf" routes (i.e. only at the page level)
            <CheckLocalization preventLocalization={route.preventLocalization}>
                <route.component {...route.props} />
            </CheckLocalization>
        )
)

// eslint-disable-next-line react/no-multi-comp
const RouteWrapper = () => {
    return (
        <Routes>
            {routes.map((route, i) => (
                <Route key={`route_${i}`} path={route.path} element={<SubRoutesWrapper {...route} />} />
            ))}
        </Routes>
    )
}

export default RouteWrapper