import React from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getCreditCardMultiPaymentUrl } from '../../../actions/auth/payment/payment-actions'

import PayByCreditCardLink from './pay-by-credit-card-link'

class PayByCreditCardLinkContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount(){
        if (this.props.selectedBillAccount !== 0)
            this.props.dispatch(getCreditCardMultiPaymentUrl())
    }

    componentDidUpdate(prevProps){
        if (this.props.selectedBillAccount !== 0 && this.props.selectedBillAccount !== prevProps.selectedBillAccount)
            this.props.dispatch(getCreditCardMultiPaymentUrl())
    }

    render() {
        return (<PayByCreditCardLink 
            creditCardPaymentUrl={this.props.creditCardPaymentUrl}
            selectedBillAccountDetails={this.props.selectedBillAccountDetails}
            isSolar={this.props.isSolar}
            sendReconnectRemark={this.props.sendReconnectRemark}
            t={this.props.t}
            i18n={this.props.i18n}
        />)
    }
}

const mapStateToProps = (state) => {
    return {
        creditCardPaymentUrl: state.accountInfo.payment.creditCardPaymentUrl,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        selectedBillAccountDetails :  state.accountInfo.billAccount.selectedBillAccountDetails,
        isSolar: state.accountInfo.billAccount.currentRateCode.isSolar
    }
}

PayByCreditCardLinkContainer.propTypes = {
    creditCardPaymentUrl: PropTypes.string.isRequired,
    isSolar: PropTypes.bool.isRequired,
    selectedBillAccount: PropTypes.number.isRequired,
    selectedBillAccountDetails: PropTypes.object.isRequired,
    sendReconnectRemark: PropTypes.func,
    dispatch: PropTypes.func,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(PayByCreditCardLinkContainer)