import React from 'react'
import PropTypes from 'prop-types'
import ClosestPaymentLocationsLoading from './closest-payment-locations-loading'

const ClosestPaymentLocations = ({ topFiveList, isClosestPayLocFromSearch, currentLocationLoading, getDirections, display, service,
    clearClosestPayLocationsOnClick, thisMap, t }) => {

    return (
        <div>
            <div className="text-muted mb-lg-3 mb-2">
                {t("five_nearest_locations", {place : t(isClosestPayLocFromSearch ? "searched area" : "current area")})}
            </div>
            {currentLocationLoading
                ? <ClosestPaymentLocationsLoading />
                : (topFiveList.length === 0)
                    ? <div className="row mb-3 ml-4">{t("No results")}</div>
                    : <div className="row mb-3">
                        {topFiveList.map((paymentLocation, i) => (
                            <div className="d-flex" key={i}>
                                <div className="col m-0 pl-2 pr-2 pb-2">
                                    <div className="text-muted d-none d-lg-inline-block payment-location-closest-box-style">
                                        <div className="font-weight-bold">{paymentLocation.name}</div>
                                        <div>
                                            <div>{paymentLocation.street}</div>
                                            <div className="mb-2">
                                                <span>{paymentLocation.city}</span>
                                                {`, ${paymentLocation.stateZip}`}
                                            </div>
                                        </div>
                                        <div className="mb-2">
                                            <span className={paymentLocation.type === 0 ? "paycenter-blue" : "retail-orange"}>
                                                {`${t(paymentLocation.typeName)} `}
                                            </span>
                                            <span>{`• ${paymentLocation.distanceInMiles} mi`}</span>
                                        </div>
                                        <button
                                            className="btn srp-btn paylocation-direction-btn"
                                            id="get-directions-closest-btn"
                                            onClick={() => getDirections(display, service, paymentLocation.startAddress, paymentLocation.address, true, thisMap)}>
                                            {t("Get directions")}
                                        </button>
                                    </div>
                                    <div className="text-muted d-lg-none d-block payment-location-closest-box-style-small">
                                        <div className="row">
                                            <div className="col-8">
                                                <div className="font-weight-bold">{paymentLocation.name}</div>
                                                <div>
                                                    <div>{paymentLocation.street}</div>
                                                    <div className="mb-2">
                                                        <span>{paymentLocation.city}</span>
                                                        {`, ${paymentLocation.stateZip}`}
                                                    </div>
                                                </div>
                                                <div className="mb-2 no-wrap">
                                                    <span className={paymentLocation.type === 0 ? "paycenter-blue" : "retail-orange"}>
                                                        {`${t(paymentLocation.typeName)} `}
                                                    </span>
                                                    <span>{`• ${paymentLocation.distanceInMiles} mi`}</span>
                                                </div>
                                            </div>
                                            <div className="col-4 d-flex justify-content-cener p-0">
                                                <button
                                                    className="btn paylocation-direction-btn"
                                                    onClick={() => getDirections(display, service, paymentLocation.startAddress, paymentLocation.address, true, thisMap)}>
                                                    <div>
                                                        <i className="material-icons">{"directions"}</i>
                                                    </div>
                                                    <div className="font-12">
                                                        {t("Directions")}
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>}
            <div className="d-lg-none d-block d-flex justify-content-end">
                <button onClick={() => clearClosestPayLocationsOnClick()} className="btn srp-btn btn-lightblue">{t("Clear List")}</button>
            </div>
        </div>
    )
}

ClosestPaymentLocations.propTypes = {
    topFiveList: PropTypes.array,
    isClosestPayLocFromSearch: PropTypes.bool,
    currentLocationLoading: PropTypes.bool,
    getDirections: PropTypes.func,
    display: PropTypes.object,
    service: PropTypes.object,
    clearClosestPayLocationsOnClick: PropTypes.func,
    thisMap: PropTypes.object,
    t: PropTypes.func.isRequired
}

export default ClosestPaymentLocations