import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import PromotionalBanner from './promotional-banner'

import { billAccountConstants } from '../../constants/bill-account-constants'

import { getRateCode, getServiceAddress, hashBillAccount } from '../../actions/auth/bill_account/bill-account-actions'
import { getHas12MonthsBillingHistory } from '../../actions/auth/forms/clean-power-actions'
import * as loadingStatus from '../../constants/loading-status-constants'

class PromotionalBannerContainer extends React.Component {

    componentDidMount() {
        if (this.props.selectedBillAccount === 0)
            return

        if (this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getServiceAddressInfo(this.props.selectedBillAccount)
        }

        if (this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getCurrentRateCodeInfo(this.props.selectedBillAccount)
        }

        if (this.props.hasEnoughHistoryStatus === loadingStatus.LOADING_STATUS_INIT) {
            this.props.getHas12MonthsBillingHistoryInfo(this.props.selectedBillAccount)
        }

        if (this.props.hashBillAccountStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.hashBillAccountStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getHashedBillAccount(this.props.selectedBillAccount)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.selectedBillAccount === nextProps.selectedBillAccount || nextProps.selectedBillAccount === 0)
            return

        if (nextProps.serviceAddressStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getServiceAddressInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.currentRateCodeStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getCurrentRateCodeInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.hasEnoughHistoryStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getHas12MonthsBillingHistoryInfo(nextProps.selectedBillAccount)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedBillAccount === prevProps.selectedBillAccount || this.props.selectedBillAccount === 0)
            return
      
        if (this.props.hashBillAccountStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getHashedBillAccount(this.props.selectedBillAccount)
        }
    }

    render() {
        if (this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.selectBillAccountStatus === loadingStatus.LOADING_STATUS_INIT || this.props.selectBillAccountStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_INIT || this.props.currentRateCodeStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.hashBillAccountStatus === loadingStatus.LOADING_STATUS_INIT || this.props.hashBillAccountStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.hasEnoughHistoryStatus === loadingStatus.LOADING_STATUS_INIT || this.props.hasEnoughHistoryStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            return null
        }
        else {
            return (
                <PromotionalBanner
                    activeResidential={this.props.selectedBillAccountDetails.closedState === billAccountConstants.CLOSED_STATE.OPEN
                        && this.props.selectedBillAccountDetails.isCommercial === false}
                    hashedAccountForOpower={this.props.hashedBillAccount}
                    singleBillAccount={this.props.singleBillAccount}
                    displaySolarEstimatorAd={setSolarEstimatorAdDisplay(this.props.currentRateCode.rateCode, this.props.serviceAddress.dwellingTypeCode, this.props.currentRateCode.isSolar)} />
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        selectBillAccountStatus: state.accountInfo.billAccount.selectBillAccountStatus,
        ...state.accountInfo.billAccount,
        singleBillAccount: (state.accountInfo.billAccount.billAccountList?.length ?? 0) == 1,
        ...state.cleanPower,

        hashedBillAccount: state.accountInfo.billAccount.hashedBillAccount,
        hashBillAccountStatus: state.accountInfo.billAccount.hashBillAccountStatus,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCurrentRateCodeInfo: (billAccount) => {
            dispatch(getRateCode(billAccount))
        },
        getServiceAddressInfo: (billAccount) => {
            dispatch(getServiceAddress(billAccount))
        },
        getHas12MonthsBillingHistoryInfo: (billAccount) => {
            dispatch(getHas12MonthsBillingHistory(billAccount))
        },
        getHashedBillAccount: (billAccount) => {
            dispatch(hashBillAccount(billAccount))
        },
    }
}

PromotionalBannerContainer.propTypes = {
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.shape({
        isCommercial: PropTypes.bool.isRequired,
        closedState: PropTypes.number.isRequired,
    }).isRequired,
    selectBillAccountStatus: PropTypes.string.isRequired,
    singleBillAccount: PropTypes.bool.isRequired,

    currentRateCode: PropTypes.object,
    currentRateCodeStatus: PropTypes.string,
    getCurrentRateCodeInfo: PropTypes.func,

    serviceAddress: PropTypes.object,
    serviceAddressStatus: PropTypes.string,
    getServiceAddressInfo: PropTypes.func,

    hasEnoughHistoryStatus: PropTypes.string,
    getHas12MonthsBillingHistoryInfo: PropTypes.func,

    getHashedBillAccount: PropTypes.func.isRequired,
    hashedBillAccount: PropTypes.string.isRequired,
    hashBillAccountStatus: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionalBannerContainer)

function setSolarEstimatorAdDisplay(rateCode, addressDwelling, isSolar) {
    let isValidRateCode = false
    switch (rateCode) {
        case 1:
        case 2:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
            isValidRateCode = true
            break
        default:
            break
    }

    let isValidDwelling = false
    if (addressDwelling !== "2" && addressDwelling !== "7")
        isValidDwelling = true

    return (isValidRateCode && isValidDwelling && !isSolar)
}