import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import UsagePageLoading from './usage-page-loading'
import HourlyUsageContainer from './hourly-usage-container'
import { getRateMetaData } from '../../actions/auth/usage/rate-meta-data-actions'
import { getDailyUsage } from '../../actions/auth/usage/daily-usage-actions'
import HourlyDateRangePickers from './hourly-date-range-pickers'
import { getHourlyUsage } from '../../actions/auth/usage/hourly-usage-actions'
import { rateMetaDataConstants } from '../../constants/rate-meta-data-constants'
import * as loadingStatus from '../../constants/loading-status-constants'
import HourlyNetContainer from './hourly-net-container'
import NoHourlyUsageMessage from './no-hourly-usage-message'
import UnmeteredMessage from './unmetered-message'
import SummaryMasterMessage from './summary-master-message'
import LookingForUsage from './looking_for_usage'
import { withTranslation } from 'react-i18next' 

class HourlyUsageFormContainer extends React.Component{
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT
            && this.props.selectedBillAccount > 0) {
                this.props.getRateMetaDataForAccount(this.props.selectedBillAccount)
        }

        if (this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT && this.props.selectedBillAccount > 0 && !this.props.forceHourlyRequest) {
            this.props.getDailyUsageForAccount(this.props.selectedBillAccount)
        }

        if (this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_SUCCESS && this.props.selectedBillAccount > 0 && !this.props.forceHourlyRequest) {
            this.props.getHourlyUsageForAccount(this.props.selectedBillAccount, this.props.hourlyStartDate.toFormat('MM-dd-yyyy'), this.props.hourlyEndDate.toFormat('MM-dd-yyyy'))
        }

        if (this.props.forceHourlyRequest) {
            this.props.getHourlyUsageForAccount(this.props.selectedBillAccount, this.props.hourlyStartDate.toFormat('MM-dd-yyyy'), this.props.hourlyEndDate.toFormat('MM-dd-yyyy'))
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT && nextProps.selectedBillAccount > 0) {
            this.props.getDailyUsageForAccount(nextProps.selectedBillAccount)
        }

        if (nextProps.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT && nextProps.selectedBillAccount > 0) {
            this.props.getRateMetaDataForAccount(nextProps.selectedBillAccount)
        }

        if (!nextProps.hourlyStartDate.equals(this.props.hourlyStartDate) || !nextProps.hourlyEndDate.equals(this.props.hourlyEndDate)
        ) {
            this.props.getHourlyUsageForAccount(nextProps.selectedBillAccount, nextProps.hourlyStartDate.toFormat('MM-dd-yyyy'), nextProps.hourlyEndDate.toFormat('MM-dd-yyyy'))
        }

        if (nextProps.dailyUsageStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && nextProps.hourlyUsageStatus === loadingStatus.LOADING_STATUS_INIT)
        {
            this.props.getHourlyUsageForAccount(nextProps.selectedBillAccount, nextProps.dailyMaxDate.toFormat('MM-dd-yyyy'), nextProps.dailyMaxDate.toFormat('MM-dd-yyyy'))
        }
    }

    render() {
        if(this.props.isPendingTurnOn)
        return(
            <div>
                <LookingForUsage t={this.props.t}/>
            </div>
        )
        if (this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE
            || this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_FAILURE
            || this.props.hourlyUsageStatus === loadingStatus.LOADING_STATUS_FAILURE
        )
            return(
                <NoHourlyUsageMessage t={this.props.t}/>
            )
        if (this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.dailyUsageStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
            || this.props.hourlyUsageStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.hourlyUsageStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
        )
            return(
                <div>
                    <UsagePageLoading/>
                </div>
            )

            if(this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS
                && this.props.rateMetaData.rateMetaData.summaryBilling === rateMetaDataConstants.SUMMARY_BILLING_PARENT)
                return(
                    <SummaryMasterMessage isCommercial={this.props.isCommercial} t={this.props.t}/>
                )
            if(this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS
                && !this.props.rateMetaData.rateMetaData.isMetered)
                return(
                    <div><UnmeteredMessage isCommercial={this.props.isCommercial} t={this.props.t}/></div>
                )

            if(this.props.dailyUsageList.length < 1)
                return(
                    <div>
                        <HourlyDateRangePickers i18n={this.props.i18n} t={this.props.t} />
                        <NoHourlyUsageMessage t={this.props.t}/>
                    </div>
                )
            if(this.props.rateMetaData.rateMetaData.isNetBilled)
            return(
                <div>
                    <HourlyDateRangePickers i18n={this.props.i18n} t={this.props.t} />
                    <HourlyNetContainer t={this.props.t}/>
                </div>)
            if(this.props.dailyUsageList.length < 1
                || this.props.rateMetaData.rateMetaData.isNonSmartMeterRate)
            return(
                <div>
                    <LookingForUsage t={this.props.t}/>
                </div>
            )
            return(
                <div>
                    <HourlyDateRangePickers i18n={this.props.i18n} t={this.props.t} />
                    <HourlyUsageContainer t={this.props.t}/>
                </div>)
    }
}

const mapStateToProps = state => {
    return {
        ...state.accountInfo.usage,
        ...state.accountInfo.usagePageUI,
        ...state.dailyUsage,
        hourlyUsage: state.hourlyUsage,
        hourlyUsageStatus: state.hourlyUsage.hourlyUsageStatus,
        isTokenless: state.accountInfo.payment.payment.supportsRemotePrepay,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        isCommercial: state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
        rateMetaData: state.rateMetaData,
        isPendingTurnOn: state.accountInfo.billAccount.selectedBillAccountDetails.isPendingTurnOn
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getRateMetaDataForAccount: (billAccount) => {
            dispatch(getRateMetaData(billAccount))
        },
        getDailyUsageForAccount: (billAccount) => {
            dispatch(
             getDailyUsage(billAccount))
        },
        getHourlyUsageForAccount: (billAccount, beginDate, endDate) => {
            dispatch(
                getHourlyUsage(billAccount, beginDate,endDate)
            )
        }
    }
}

HourlyUsageFormContainer.propTypes = {
    showDailyCostVerbiage: PropTypes.object,
    showDailyUsageVerbiage: PropTypes.object,
    dailyButtonClassNames: PropTypes.object,
    rateMetaData: PropTypes.object,
    selectedBillAccount: PropTypes.number,
    getRateMetaDataForAccount: PropTypes.func,
    getDailyUsageForAccount: PropTypes.func,
    dailyUsageStatus: PropTypes.string,
    paymentStatus: PropTypes.string,
    isTokenless: PropTypes.bool,
    dailyUsageList: PropTypes.array,
    hourlyUsageStatus: PropTypes.string,
    getHourlyUsageForAccount: PropTypes.func,
    dailyMaxDate: PropTypes.object,
    hourlyStartDate: PropTypes.object,
    hourlyEndDate: PropTypes.object,
    forceHourlyRequest: PropTypes.bool,
    isCommercial: PropTypes.bool,
    isPendingTurnOn: PropTypes.bool,
    i18n: PropTypes.object,
    t: PropTypes.func
}
export default withTranslation("usageForms")(connect(mapStateToProps, mapDispatchToProps)(HourlyUsageFormContainer))

