import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import IconButton from '@mui/material/IconButton'

import ChangeLoginEmailForm from './change-login-email-form'
import ChangePasswordForm from './change-password-form'
import DeleteLoginForm from './delete-login-form'

const initialState = {
    displayChangeEmail: false,
    displayChangePassword: false,
    displayChangePasswordSuccess: false,
    displayDeleteLogin: false
}

class LoginEmailSettings extends React.Component {
    constructor(props) {
        super(props)

        this.state = initialState

        this.setDisplayChangeEmailForm = this.setDisplayChangeEmailForm.bind(this)
        this.setDisplayChangePassword = this.setDisplayChangePassword.bind(this)
        this.setDisplayDeleteLoginForm = this.setDisplayDeleteLoginForm.bind(this)
        this.setDisplayChangePasswordSuccess = this.setDisplayChangePasswordSuccess.bind(this)
    }

    setDisplayChangeEmailForm(displayChangeEmail) {
        return () => {
            this.setState({ ...initialState, displayChangeEmail })
        }
    }

    setDisplayDeleteLoginForm(displayDeleteLogin) {
        return (() => {
            this.setState({ ...initialState, displayDeleteLogin })
        })
    }

    setDisplayChangePassword(displayChangePassword) {
        return () => {
            this.setState({ ...initialState, displayChangePassword })
        }
    }

    setDisplayChangePasswordSuccess() {
        this.setState({ ...initialState, displayChangePasswordSuccess: true })
    }

    render() {
        let displayChangeEmail = this.state.displayChangeEmail
        let displayChangePassword = this.state.displayChangePassword
        let displayDeleteLogin = this.state.displayDeleteLogin

        return (
            <div className="row border-bottom h-100 mt-1">
                <div className="col-lg-3 mr-2">
                    <div className="text-muted text-nowrap p-2">{this.props.t("Login email")}</div>
                </div>
                <div className="col-lg">
                    <div className="pl-1">
                        {this.state.displayChangePasswordSuccess
                            ? <div className="srp-alert-success mb-2">{this.props.t("You’ve successfully updated your password.")}</div>
                            : null}
                        <div className="d-flex flex-row h-100">
                            <div className="d-flex flex-wrap pt-2" style={{ flex: '1' }}>
                                {
                                    displayDeleteLogin ? null :
                                        <div className="d-flex flex-wrap pr-2 mr-2" style={{ maxWidth: '100%' }}>
                                            <span className={displayChangeEmail ? "text-truncate flex-wrap pr-1" : "d-none"}>{this.props.t("Your current email is")}</span>
                                            <span className={displayChangeEmail ? "text-nowrap font-weight-bold" : "text-truncate"}>{this.props.loginEmail}</span>
                                        </div>
                                }
                                {
                                    (displayChangePassword === false && displayChangeEmail === false && displayDeleteLogin === false) ?
                                        <div className="d-flex text-nowrap pb-2">
                                            <button className="displayAsLink text-nowrap h-100" onClick={this.setDisplayChangePassword(true)}>
                                                <div className="h-100"><small>{this.props.t("Change password")}</small></div>
                                            </button>
                                        </div> : null
                                }
                            </div>
                            {
                                (displayChangePassword === false && displayChangeEmail === false && displayDeleteLogin === false) ?
                                    <div className="d-flex text-right">
                                        <IconButton
                                            className="align-self-center"
                                            color="primary"
                                            onClick={this.setDisplayChangeEmailForm(true)}
                                            size="large"><i className="material-icons">create</i></IconButton>
                                        <IconButton
                                            className="align-self-center"
                                            color="primary"
                                            onClick={this.setDisplayDeleteLoginForm(true)}
                                            size="large"><i className="material-icons">delete</i></IconButton>
                                    </div> : null
                            }
                        </div>
                        { displayChangeEmail === true ? <ChangeLoginEmailForm t={this.props.t} setDisplayChangeEmailForm={this.setDisplayChangeEmailForm} /> : null }

                        {displayChangePassword !== true ? null
                            : <ChangePasswordForm setDisplayChangePassword={this.setDisplayChangePassword}
                                                  setDisplayChangePasswordSuccess={this.setDisplayChangePasswordSuccess} 
                                                  t={this.props.t}/> }

                        { displayDeleteLogin === true ? <DeleteLoginForm setDisplayDeleteLoginForm={this.setDisplayDeleteLoginForm} /> : null }
                    </div>
                </div>
            </div>
        )
    }
}

LoginEmailSettings.propTypes = {
    loginEmail: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
    return {
        loginEmail: state.login.loginEmail,
        emailUpdateRequestSent: state.login.emailUpdateRequestSent
    }
}

export default connect(mapStateToProps)(LoginEmailSettings)