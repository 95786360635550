import config from 'my-account-config'
import { myAccountConstants } from '../constants/myaccount-constants'
import { DateTime, Duration } from 'luxon'
import { rateMetaDataConstants } from '../constants/rate-meta-data-constants'

const basePath = 'api/'

const utils = {
    createUrl,
    toQueryString,
    delimitNumbers,
    formatDayWithOrdinal,
    getFormattedCSPhoneNumber,
    getFormattedCSSolarNumber,
    getCSPhoneNumber,
    getCSSolarPhoneNumber,
    getFormattedCSSolarChoiceNumber,
    getCSSolarChoicePhoneNumber,
    getFormattedCSSpanishPhoneNumber,
    getCSSpanishPhoneNumber,
    GetCustomerServicePhoneNumber,
    GetCustomerServicePhoneNumberFmt,
    getSpanishMonth,
    getSpanishMonthAbbreviation,
    getSpanishDayOfWeekAbbreviation,
    formatPaymentAmountInput,
    isEmptyObject,
    isLocalStorageAvailable,
    isSessionStorageAvailable,
    isSomePurchaseGoingToMeter,
    calcAmountToMeterAndToSRP,
    paymentToNumber,
    parsePaymentDateDefaultToday,
    timeSpanToDuration,
    rateCodeToShortRateCode,
    rateCodeToMetaData,
    mapPathnameToPage,
    serviceAddressSort
}

function createUrl(path, parameters = {}, includePath = true) {
    let queryString = toQueryString(parameters)
    let url = config.apiBaseUrl

    if (includePath) {
        url = url + basePath
    }

    url = url + path
    if (queryString) {
        url += "?" + queryString
    }

    return url
}

function toQueryString(obj) {
    let parts = []
    for (let i in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, i)) {
            parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]))
        }
    }

    return parts.join("&")
}

// takes a number and inserts commas at the thousands separator, and returns it as a string
function delimitNumbers(str) {
    return (str + "").replace(/\b(\d+)((\.\d+)*)\b/g, function (a, b, c) {
        return (b.charAt(0) > 0 && !(c || ".").lastIndexOf(".") ? b.replace(/(\d)(?=(\d{3})+$)/g, "$1,") : b) + c
    })
}

export function formatDayWithOrdinal(dayValue) {
    if ([ 1, 21, 31 ].includes(dayValue)) return `${dayValue}st`
    else if ([ 2, 22 ].includes(dayValue)) return `${dayValue}nd`
    else if ([ 3, 23 ].includes(dayValue)) return `${dayValue}rd`
    else return `${dayValue}th`
}

function getFormattedCSPhoneNumber(isCommercial) {
    if (isCommercial === true)
        return myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
    else
        return myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
}

function getFormattedCSSolarNumber(isCommercial) {
    if (isCommercial === true)
        return myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
    else
        return myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_SOLAR_PHONE_NUMBER_FMTTD
}

function getCSSolarPhoneNumber(isCommercial) {
    if (isCommercial === true)
        return myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER
    else
        return myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_SOLAR_PHONE_NUMBER
}

function getFormattedCSSolarChoiceNumber(isCommercial) {
    if (isCommercial === true)
        return myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
    else
        return myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
}

function getCSSolarChoicePhoneNumber(isCommercial) {
    if (isCommercial === true)
        return myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER
    else
        return myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER
}

function getCSPhoneNumber(isCommercial) {
    if (isCommercial === true)
        return myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER
    else
        return myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER
}

function getFormattedCSSpanishPhoneNumber() {
    return myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
}

function getCSSpanishPhoneNumber() {
    return myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER
}

function GetCustomerServicePhoneNumber(isCommercial, isSpanish) {
    let supportNumber = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER
    if (isCommercial) {
        supportNumber = myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER
    } else if (isSpanish) {
        supportNumber = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER
    }

    return supportNumber
}

function GetCustomerServicePhoneNumberFmt(isCommercial, isSpanish) {
    let supportNumberFmt = myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
    if (isCommercial) {
        supportNumberFmt = myAccountConstants.COMMERCIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
    } else if (isSpanish) {
        supportNumberFmt = myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
    }

    return supportNumberFmt
}

function getSpanishMonth(monthIndex) {
    if (monthIndex < 1 || monthIndex > 12)
        return ""

    // source: https://www.spanish.cl/vocabulary-lists/days-months.htm
    const spanishMonths = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    return spanishMonths[monthIndex-1]
}

function getSpanishMonthAbbreviation(monthIndex) {
    if (monthIndex < 1 || monthIndex > 12)
        return ""

    // source: https://web.library.yale.edu/cataloging/months
    const spanishMonthAbbreviations = ["enero", "feb.", "marzo", "abr.", "mayo", "jun.", "jul.", "agosto", "sept.", "oct.", "nov.", "dic."]
    return spanishMonthAbbreviations[monthIndex-1]
}

function getSpanishDayOfWeekAbbreviation(dayOfWeekIndex) {
    // 0 = Sunday, 6 = Saturday
    if (dayOfWeekIndex < 0 || dayOfWeekIndex > 6)
        return ""

    // Source: https://www.berlitz.com/blog/days-of-the-week-spanish
    const spanishDayOfWeekAbbreviations = ["dom.", "lun.", "mart.", "miérc.", "juev.", "vier.", "sáb."]
    return spanishDayOfWeekAbbreviations[dayOfWeekIndex]
}

function formatPaymentAmountInput(value) {
    if (/^(\$?\d+(,\d{3})*\.?[0-9]?[0-9]?|\$?\.\d{1,2})$/.test(value) === false)
        return value
    // don't append .00 if the payment amount is not valid

    if (/\.\d\d$/g.test(value) === true)
        return value

    if (/\.\d$/g.test(value) === true)
        value += '0'
    else if (/\.$/g.test(value) === true)
        value += '00'
    else
        value += '.00'

    return value
}

function isEmptyObject(obj) {
    return Object.getOwnPropertyNames(obj).length === 0
}

function isSomePurchaseGoingToMeter(purchaseList, paymentInfoList) {
    const authRequired = purchaseList.some(p => p.payAmountOwedToSRP===false) ||
        purchaseList.some(p => p.purchaseAmount > paymentInfoList.find(i => i.billAccount===p.billAccount).paymentInfo.amountDue)
    return authRequired;
}

function calcAmountToMeterAndToSRP({purchaseAmount, amountOwedToSRP, payDownFraction, applyWholePurchaseTowardsArrears}) {
    let amountToSRP = applyWholePurchaseTowardsArrears ? purchaseAmount : (Math.round(purchaseAmount * payDownFraction * 100) / 100);
    amountToSRP = Math.min(amountOwedToSRP, amountToSRP);
    const amountToMeter = purchaseAmount - amountToSRP
    return {amountToMeter, amountToSRP}
}

let localStorageAvailable = undefined
function isLocalStorageAvailable() {
    if (!localStorage)
        return false

    if (localStorageAvailable !== undefined) {
        return localStorageAvailable
    }

    try {
        localStorage.getItem('testItem')
        localStorageAvailable = true
    }
    catch {
        localStorageAvailable = false
    }

    return localStorageAvailable
}

let sessionStorageAvailable = undefined
function isSessionStorageAvailable() {
    "use strict"

    if (!sessionStorage)
        return false

    if (sessionStorageAvailable !== undefined) {
        return sessionStorageAvailable
    }

    try {
        sessionStorage.getItem('testItem')
        sessionStorageAvailable = true
    }
    catch {
        sessionStorageAvailable = false
    }

    return sessionStorageAvailable
}

function paymentToNumber(paymentAmount) {
    let amount = '' + paymentAmount

    return Number(amount.replace(/[^\d.-]/g, ''))
}

function parsePaymentDateDefaultToday(dateToParse) {
    dateToParse = dateToParse || DateTime.now()

    let sixtyDays = DateTime.now().startOf('day').plus({ days: 60 })
    let today = DateTime.now().startOf('day')

    let newDate = (typeof dateToParse === 'string')
                ? DateTime.fromISO(dateToParse)
                : dateToParse

    if (newDate.invalid || newDate < today || newDate > sixtyDays) {
        newDate = DateTime.now()
    }

    return newDate.toFormat('yyyy-MM-dd')
}

function timeSpanToDuration(timeSpan) {
    /* NOTE: C# TimeSpans will be in one of the following formats:
                -[hours]:[minutes]:[seconds]
                -[hours]:[minutes]:[seconds].[fractionalSeconds]
                -[days].[hours]:[minutes]:[seconds]
                -[days].[hours]:[minutes]:[seconds].[fractionalSeconds]
    */
    const timeSpanRegex = /(?<sign>-)?(?:(?<days>[0-9]+)\.)?(?<hours>[0-9]+):(?<minutes>[0-9]+):(?<seconds>[0-9]+(?:\.[0-9]+)?)/

    const { sign, days, hours, minutes, seconds } = timeSpanRegex.exec(timeSpan).groups
    const duration = Duration.fromISO(`${sign || ''}P${days || 0}DT${hours}H${minutes}M${seconds}S`)
    return duration
}

function rateCodeToShortRateCode(rateCode) {
    return rateMetaDataConstants.RATE_TO_META_DATA_TABLE[rateCode].shortCode
}

function rateCodeToMetaData(rateCode, isSolar = false) {
    let customerGenerationCode = 'E27P'
    if (rateCode === 1 && isSolar) {
        return rateMetaDataConstants.RATE_TO_META_DATA_TABLE[customerGenerationCode]
    }
    return rateMetaDataConstants.RATE_TO_META_DATA_TABLE[rateCode]
}

function mapPathnameToPage(path) {
    let pathLower = path.toLowerCase()
    let pageCode = myAccountConstants.TRACKED_PAGES[pathLower]

    if (!pageCode) {
        pageCode = 'dash'
    }

    return pageCode
}

function serviceAddressSort(serviceAddressA, serviceAddressB) {

    let comparison = 1

    comparison = serviceAddressA.streetName.localeCompare(serviceAddressB.streetName, 'en', { numeric: true, sensitivity: 'accent' })

    if (comparison === 0)
        comparison = serviceAddressA.addressDirection.localeCompare(serviceAddressB.addressDirection, 'en', { numeric: true, sensitivity: 'accent' })

    if (comparison === 0)
        comparison = serviceAddressA.addressDirectionSuffix.localeCompare(serviceAddressB.addressDirectionSuffix, 'en', { numeric: true, sensitivity: 'accent' })

    if (comparison === 0)
        comparison = serviceAddressA.houseNumber.toString().localeCompare(serviceAddressB.houseNumber.toString(), 'en', { numeric: true, sensitivity: 'accent' })

    if (comparison === 0)
        comparison = serviceAddressA.buildingNumber.localeCompare(serviceAddressB.buildingNumber, 'en', { numeric: true, sensitivity: 'accent' })

    if (comparison === 0)
        comparison = serviceAddressA.unitNumber.localeCompare(serviceAddressB.unitNumber, 'en', { numeric: true, sensitivity: 'accent' })

    if (comparison === 0)
        comparison = serviceAddressA.uniqueCode.localeCompare(serviceAddressB.uniqueCode, 'en', { numeric: true, sensitivity: 'accent' })

    if (comparison === 0)
        comparison = serviceAddressA.uspsAddressTypeAbbreviation.localeCompare(serviceAddressB.uspsAddressTypeAbbreviation, 'en', { numeric: true, sensitivity: 'accent' })

    return comparison
}

export default utils
