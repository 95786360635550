import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'

import { shareConstants } from '../../../constants/share-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'
import format from '../../../srp_modules/format'

const Review = ({ activeStep, setActiveStepOnClick, accountDetails, donationType, donationAmount, otherDonationAmount,
    submitShareFormOnClick, submitShareFormStatus, t }) => {

    return (
        <form className="srp-card-details" onSubmit={(e) => {
            e.preventDefault()
            submitShareFormOnClick(accountDetails, donationType, donationAmount, otherDonationAmount)
        }}>
            <h4 style={{ color: 'rgba(51, 51, 51, 0.537)' }}>
                {t("Donation information")}
            </h4>
            <div className="d-lg-block d-none mb-4">
                <div className="text-muted">
                    <span className="font-weight-bold mr-2">{t("Donation type")}</span>
                    <span>
                        {donationType === shareConstants.DONATION_TYPE.MONTHLY
                            ? t("monthly pledge")
                            : donationType === shareConstants.DONATION_TYPE.ONE_TIME
                                ? t("one-time pledge")
                                : t("cancellation")
                        }
                    </span>
                </div>
                {donationType !== shareConstants.DONATION_TYPE.DISCONTINUE
                    ? <div className="text-muted">
                        <span className="font-weight-bold mr-2">{t("Donation amount")}</span>
                        <span>
                            {donationAmount === shareConstants.DONATION_AMOUNT.OTHER
                                ? `$${otherDonationAmount}`
                                : `$${donationAmount}.00`
                            }
                        </span>
                    </div>
                    : null
                }
            </div>
            <div className="d-lg-none d-block mb-4">
                <div className="row mt-2 d-flex align-items-center">
                    <div className="col text-muted font-weight-bold align-middle">{t("Donation type")}</div>
                    <div className="col align-middle text-right">
                        {donationType === shareConstants.DONATION_TYPE.MONTHLY
                            ? t("monthly pledge")
                            : donationType === shareConstants.DONATION_TYPE.ONE_TIME
                                ? t("one-time pledge")
                                : t("cancellation")
                        }
                    </div>
                </div>
                <hr className="mt-2" />
                {donationType !== shareConstants.DONATION_TYPE.DISCONTINUE
                    ? <div>
                        <div className="row mt-2 d-flex align-items-center">
                            <div className="col text-muted font-weight-bold align-middle">{t("Donation amount")}</div>
                            <div className="col align-middle text-right">
                                {donationAmount === shareConstants.DONATION_AMOUNT.OTHER
                                    ? `$${otherDonationAmount}`
                                    : `$${donationAmount}.00`
                                }
                            </div>
                        </div>
                        <hr className="mt-2" />
                    </div>
                    : null
                }
            </div>

            <h4 style={{ color: 'rgba(51, 51, 51, 0.537)' }}>
                {accountDetails.accountType === shareConstants.ACOUNT_TYPE.COMMERCIAL ? t("Business information") : t("Customer information")}
            </h4>
            <div className="d-lg-block d-none mb-4">
                {accountDetails.accountType === shareConstants.ACOUNT_TYPE.COMMERCIAL
                    ? <div className="text-muted">
                        <span className="font-weight-bold mr-2">{t("Business name")}</span>
                        <span>{`${accountDetails.businessName}`}</span>
                    </div>
                    : <div className="text-muted">
                        <span className="font-weight-bold mr-2">{t("Name")}</span>
                        <span>{`${accountDetails.firstName} ${accountDetails.lastName}`}</span>
                    </div>
                }

                <div className="text-muted">
                    <span className="font-weight-bold mr-2">{t("SRP account number")}</span>
                    <span>{`${format.formatBillAccountNumber(accountDetails.billAccount)}`}</span>
                </div>
                <div className="text-muted">
                    <span className="font-weight-bold mr-2">{t("Service address")}</span>
                    <span>
                        {`${accountDetails.serviceAddressOne}`}
                        {(accountDetails.serviceAddressTwo).length !== 0
                            ? ` ${accountDetails.serviceAddressTwo}`
                            : null
                        }
                        {` ${accountDetails.city}, AZ ${format.zipcode(accountDetails.zipCode)}`}
                    </span>
                </div>
                <div className="text-muted">
                    <span className="font-weight-bold mr-2">{t("Email address")}</span>
                    <span>{`${accountDetails.emailAddress}`}</span>
                </div>
                <div className="text-muted">
                    <span className="font-weight-bold mr-2">{t("Phone number")}</span>
                    <span>{`${format.formatPhoneNumber(accountDetails.phoneNumber)}`}</span>
                </div>
            </div>

            <div className="d-lg-none d-block mb-4">
                <div className="row d-flex align-items-center">
                    <div className="col text-muted font-weight-bold align-middle">
                        {accountDetails.accountType === shareConstants.ACOUNT_TYPE.COMMERCIAL
                            ? t("Business name")
                            : t("Name")
                        }
                    </div>
                    <div className="col align-middle text-right">
                        {accountDetails.accountType === shareConstants.ACOUNT_TYPE.COMMERCIAL
                            ? accountDetails.businessName
                            : `${accountDetails.firstName} ${accountDetails.lastName}`
                        }
                    </div>
                </div>
                <hr className="mt-2" />
                <div className="row mt-2 d-flex align-items-center">
                    <div className="col text-muted font-weight-bold align-middle">{t("SRP account number")}</div>
                    <div className="col align-middle text-right">
                        {format.formatBillAccountNumber(accountDetails.billAccount)}
                    </div>
                </div>
                <hr className="mt-2" />
                <div className="row mt-2 d-flex align-items-center">
                    <div className="col-5 text-muted font-weight-bold align-middle">{t("Service address")}</div>
                    <div className="col-7 align-middle text-right">
                        <div>
                            {`${accountDetails.serviceAddressOne}`}
                            {(accountDetails.serviceAddressTwo).length !== 0
                                ? ` ${accountDetails.serviceAddressTwo}`
                                : null
                            }
                        </div>
                        <div>{`${accountDetails.city}, AZ ${format.zipcode(accountDetails.zipCode)}`}</div>
                    </div>
                </div>
                <hr className="mt-2" />
                <div className="row mt-2 d-flex align-items-center">
                    <div className="col text-muted font-weight-bold align-middle">{t("Email address")}</div>
                    <div className="col align-middle text-right">
                        {accountDetails.emailAddress}
                    </div>
                </div>
                <hr className="mt-2" />
                <div className="row mt-2 d-flex align-items-center">
                    <div className="col text-muted font-weight-bold align-middle">{t("Phone number")}</div>
                    <div className="col align-middle text-right">
                        {format.formatPhoneNumber(accountDetails.phoneNumber)}
                    </div>
                </div>
                <hr className="mt-2" />
            </div>

            <div className="d-flex flex-wrap justify-content-end">
                <button className="btn srp-btn btn-lightblue ml-2" onClick={() => setActiveStepOnClick(activeStep - 1)}>{t("Previous")}</button>
                <button
                    className="btn srp-btn btn-green ml-2"
                    type="submit"
                    disabled={submitShareFormStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}>
                    {submitShareFormStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                        ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                        : t("Submit")
                    }
                </button>
            </div>
        </form>
    )

}

Review.propTypes = {
    activeStep: PropTypes.number,
    setActiveStepOnClick: PropTypes.func,
    accountDetails: PropTypes.object,
    donationType: PropTypes.number,
    donationAmount: PropTypes.number,
    otherDonationAmount: PropTypes.string,
    submitShareFormOnClick: PropTypes.func,
    submitShareFormStatus: PropTypes.string,

    t: PropTypes.func.isRequired
}

export default Review