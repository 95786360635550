// eslint mistakenly sees the helper method as a React component
/* eslint react/no-multi-comp: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import LoginForm from './login-form'
import SignUpForm from './sign-up-form'
import CallAnytime from '../myaccount_header/call-anytime'

import '../../styles/dashboard-page.css'
import '../../styles/login-page.css'

const LoginPage = ({getResponsiveLoginTabValue, loginResponsiveTab, t}) => (
    <div>
        <div className="container py-3">
            <CallAnytime />
            <h3 className="mt-3">{t("Welcome to My Account")}</h3>
            <h5 className="text-muted">{t("Log in to your account or sign up today")}</h5>

            <div className="d-none d-lg-block">
                <div className="row">
                    <div className="col-lg-6 mt-4">
                        <div className="srp-card-header">
                            {t("Enter login information")}
                        </div>

                        <div className="srp-card-body">
                            <div className="srp-card-details">
                                <LoginForm showSignUpButtonAndBlueLoginButton={false} idSuffix="_desktop" />
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-6 mt-4">
                        <div className="srp-card-header">
                            {t("New to My Account? Sign up for free!")}
                        </div>

                        <div className="srp-card-body ">
                            <SignUpForm />
                        </div>

                    </div>
                </div>
            </div>

            <div className="card border-0 d-lg-none d-block">
                <div className="card-header">
                    {loginResponsiveTab === 0
                        ? t("Enter login information")
                        : t("New to My Account? Sign up for free!")
                    }
                </div>

                <div className="srp-card-body">
                    <AppBar position="static" style={{backgroundColor: 'white', borderBottom: "1px solid lightgray"}} elevation={0}>
                        <Tabs
                            value={loginResponsiveTab}
                            onChange={(evt, value)=>getResponsiveLoginTabValue(evt, value)}
                            indicatorColor="primary"
                            textColor="primary"
                            style={{color: 'black'}}
                            variant="fullWidth"
                        >
                            <Tab className="mui_tab" label={t("Log in")} style={{outline: 'none', boxShadow: 'none', textTransform: 'none'}}/>
                            <Tab className="mui_tab" label={t("Sign up")} style={{outline: 'none', boxShadow: 'none', textTransform: 'none'}}/>
                        </Tabs>
                    </AppBar>

                    {loginResponsiveTab === 0 &&
                        <div className="srp-block srp-card-details" id="login">
                            <LoginForm showSignUpButtonAndBlueLoginButton={false} idSuffix="_mobile" />
                        </div>
                    }
                    {loginResponsiveTab === 1 &&
                        <div className="srp-block" id="signup">
                            <SignUpForm />
                        </div>
                    }
                </div>
            </div>

        </div>
    </div>
)

LoginPage.propTypes = {
    pathname: PropTypes.string,
    getResponsiveLoginTabValue: PropTypes.func,
    loginResponsiveTab: PropTypes.number,
    t: PropTypes.func
}

export default withTranslation('login')(LoginPage)