import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import CircularProgress from '@mui/material/CircularProgress'
import cookies from 'js-cookie'

import { Trans, withTranslation } from 'react-i18next'

import UsernameField from "./username-field"
import UnmaskPasswordIconAndPasswordField from "./unmask-password-icon-and-password-field"
import RememberUser from './remember-user'

import { refreshPasswordReset, submitLoginForm } from '../../actions/auth/login/login-actions'
import { LOADING_STATUS_SUCCESS } from '../../constants/loading-status-constants'
import utils from '../../srp_modules/utils'
import { withRouter } from '../../srp_modules/with-router'

import '../../styles/dashboard-page.css'
import '../../styles/login-page.css'

class LoginForm extends React.Component {
    constructor(props) {
        super(props)

        this.submitLogin = this.submitLogin.bind(this)
        this.toggleRememberUser = this.toggleRememberUser.bind(this)

        let rememberUser = false
        let username = ''
        if (utils.isLocalStorageAvailable()) {
            rememberUser = localStorage.getItem('rememberUser') === 'true'
            username = rememberUser ? localStorage.getItem('rememberUserLogin') || '' : ''

            if (!rememberUser) {
                localStorage.removeItem('rememberUserLogin')
            }
        }

        let passwordErrorCookie = cookies.get('password_error')
        let showPasswordError = passwordErrorCookie !== undefined && passwordErrorCookie !== null
        if (showPasswordError)
            cookies.remove('password_error')

        this.state = {
            rememberUser,
            username,
            showPassword: false,
            showPasswordError
        }
    }

    getCompleteAccountSetupMessage(completeCreateAccountStatus, t) {
        if (completeCreateAccountStatus === LOADING_STATUS_SUCCESS) {
            return (
                <div className="srp-alert-success mb-2">
                    {t("successfully_confirmed_email")}
                </div>
            )
        }
    }

    getCompleteLoginEmailUpdateMessage(completeLoginUpdateStatus, t) {
        if (completeLoginUpdateStatus === LOADING_STATUS_SUCCESS) {
            return (
                <div className="srp-alert-success mb-2">
                    {t("successfully_updated_email")}
                </div>
            )
        }
    }
    
    getLoginEmailDeletedMessage(loginEmailDeleted, t) {
        if (loginEmailDeleted === true) {
            return (
                <div className="srp-alert-success mb-2">
                    {t("successfully_deleted_email")}
                </div>
            )
        }
    }

    toggleRememberUser() {
        let rememberUser = !this.state.rememberUser

        if (rememberUser !== true) {
            localStorage.removeItem('rememberUser')
            localStorage.removeItem('rememberUserLogin')
        }

        this.setState({ rememberUser })
    }

    async submitLogin(values, goodies) {
        await this.props.submitLogin(values, goodies, this.state.rememberUser)
    }

    validateLogin(values) {
        let errors = {}
        if (values.username === undefined || values.username.length === 0)
            errors.username = "Email or username is required"

        if (values.password === undefined || values.password.length === 0)
            errors.password = "Password is required"

        return errors
    }

    render() {
        let initialLoginStatus = this.state.showPasswordError
            ? { loginError: "The username or password provided is incorrect" }
            : {}

        return (
            <Formik
                initialValues={{
                    username: this.state.username,
                    prevUsername: '',
                    password: '',
                    attempts: this.state.showPasswordError ? 1 : 0
                }}
                validate={this.validateLogin}
                onSubmit={async (values, goodies) => await this.submitLogin(values, goodies)}>
                {({ values, errors, status, touched, dirty, handleChange, handleBlur, handleSubmit, submitCount, isSubmitting }) => {
                    let usernameError = (dirty || submitCount > 0) && touched.username && errors.username !== undefined
                    let passwordError = (dirty || submitCount > 0) && touched.password && errors.password !== undefined

                    let loginStatus = status || initialLoginStatus

                    const idSuffix = this.props.idSuffix ?? ''

                    return (
                        <form onSubmit={e => {
                            for (let key in e.target) {
                                let item = e.target[key]
                                if (item.type === 'submit') {
                                    item.focus()
                                    break
                                }
                            }
                            handleSubmit(e)
                        }}>
                            {this.getCompleteAccountSetupMessage(this.props.completeCreateAccountStatus, this.props.t)}
                            {this.getCompleteLoginEmailUpdateMessage(this.props.completeLoginUpdateStatus, this.props.t)}
                            {this.getLoginEmailDeletedMessage(this.props.loginEmailDeleted, this.props.t)}
                            {loginStatus.loginError &&
                            <div className="srp-alert-error mb-2">
                                {
                                loginStatus.loginError !== "account_locked_failed_attempts"
                                ?
                                this.props.t(loginStatus.loginError)
                                :
                                <Trans i18nKey="account_locked_failed_attempts" t={this.props.t}>
                                Your account may be locked after further failed attempts. Have you
                                <Link to="/passwordReset" onClick={() => this.props.refreshPasswordReset(values.username)}>
                                    forgotten your password?
                                </Link>?
                                </Trans>
                                }
                            </div>
                            }

                            <UsernameField
                                username={values.username}
                                usernameOnChange={handleChange}
                                usernameOnBlur={handleBlur}
                                idSuffix={idSuffix}
                                isLoginError={usernameError}
                                loginErrorText={usernameError ? errors.username : ''}
                                loginFailedAttempts={values.attempts}
                                t={this.props.t} />

                            <UnmaskPasswordIconAndPasswordField
                                autoFocus={this.state.username.length > 0}
                                password={values.password}
                                showPassword={this.state.showPassword === true}
                                passwordOnChange={handleChange}
                                passwordOnBlur={handleBlur}
                                idSuffix={idSuffix}
                                togglePasswordVisibility={() => this.setState({ showPassword: !this.state.showPassword })}
                                isLoginError={passwordError}
                                loginErrorText={passwordError ? errors.password : ''}
                                t={this.props.t} />

                            <div className="mt-1">
                                <Link to="/passwordReset" onClick={() => this.props.refreshPasswordReset(values.username)}>{this.props.t("Forgot password?")}</Link>
                            </div>

                            {utils.isLocalStorageAvailable() &&
                                <RememberUser
                                    rememberUser={this.state.rememberUser}
                                    rememberUserOnChange={this.toggleRememberUser}
                                    t={this.props.t} i18n={this.props.i18n} />}

                            <div className="d-flex justify-content-end mt-4">
                                {this.props.showSignUpButtonAndBlueLoginButton &&
                                    <Link to="/accountSetup" className="btn srp-btn btn-lightblue mr-2">{this.props.t("Sign up")}</Link>
                                }

                                <button type="submit" disabled={isSubmitting}
                                    className={'btn srp-btn ' + (this.props.showSignUpButtonAndBlueLoginButton ? 'btn-blue' : 'btn-green')}>
                                    {isSubmitting ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} aria-label="logging in" alt="progress icon" /> : this.props.t("Log in")}
                                </button>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        )
    }
}

LoginForm.propTypes = {
    completeCreateAccountStatus: PropTypes.string.isRequired,
    completeLoginUpdateStatus: PropTypes.string.isRequired,
    loginEmailDeleted: PropTypes.bool.isRequired,
    refreshPasswordReset: PropTypes.func.isRequired,
    submitLogin: PropTypes.func.isRequired,
    idSuffix: PropTypes.string,
    showSignUpButtonAndBlueLoginButton: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        location: PropTypes.object,
        navigate: PropTypes.func
    }),
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

const mapStateToProps = state => {
    return { ...state.login }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        refreshPasswordReset: (username) => {
            dispatch(refreshPasswordReset(username))
        },
        submitLogin: async (values, goodies, rememberUser) => {
            let username = values.username
            let password = values.password
            let prevUsername = values.prevUsername

            let generalError = "something_went_wrong_error"

            let path = !ownProps.router.location.state || !ownProps.router.location.state.from
                ? '/myaccount/dashboard'
                : ownProps.router.location.state.from.pathname

            let loginResult = await dispatch(submitLoginForm(username, password, rememberUser))

            if (loginResult === undefined) {
                goodies.setStatus({ loginError: generalError })
                goodies.setSubmitting(false)
                return
            }

            if (loginResult.error === true) {
                let invalidCredentials =
                    (loginResult.payload !== undefined
                        && loginResult.payload.response !== undefined
                        && loginResult.payload.response.error === 'invalid_grant')

                let errorMessage
                if (invalidCredentials) {
                    let attempts = (prevUsername === username) ? values.attempts + 1 : 1
                    let newValues = { ...values, attempts, prevUsername: username }
                    errorMessage = newValues.attempts > 2
                        ? "account_locked_failed_attempts"
                        : "The username or password provided is incorrect"
                    goodies.setValues(newValues)
                }
                else {
                    errorMessage = generalError
                }

                goodies.setStatus({ loginError: errorMessage })
                goodies.setSubmitting(false)
                return
            }

            if (loginResult.payload.redirectUrl !== undefined && loginResult.payload.redirectUrl !== '') {
                window.location = loginResult.payload.redirectUrl
            } else {
                ownProps.router.navigate(path)
            }
        }
    }
}

export default withTranslation('login')(withRouter(connect(
    mapStateToProps, mapDispatchToProps
)(LoginForm)))