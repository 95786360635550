import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import transferModule from '../../../srp_modules/forms/transfer-module'

const TransferServiceStartUpCosts = ({ pricePlan, t, i18n }) => {
    return (
        <div>
            <div className="srp-card-header">{t("Your estimated start-up costs")}</div>
            <div className="srp-card-body" style={{ backgroundColor: 'rgb(244, 231, 204)' }}>
                <div className="srp-card-details">
                        <div>
                            <div className="mb-3">
                                {`${t("You have selected the")} ${transferModule.getPricePlanDescription(pricePlan, i18n.language)}. 
                                ${t("These are your anticipated intial costs")}:`}
                            </div>
                            <li className="mb-3">{t("non_refundable_service_fee_$30")}</li>
                            <div>{t("if_currently_have_deposit")}</div>
                        </div>
                </div>
            </div>
        </div>
    )
}

TransferServiceStartUpCosts.propTypes = {
    pricePlan: PropTypes.number,
    t: PropTypes.func,
    i18n: PropTypes.object
}

export default withTranslation('transferService')(TransferServiceStartUpCosts)