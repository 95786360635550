import React from "react"
import PropTypes from 'prop-types'

import TextSignUpAlertMessage from './text-signup-alert-message'
import CommonAlert from "./common-alert"


const OutageAlerts = ({ allAlerts, phoneContacts, emailContacts, addNotificationOnClick, deleteNotificationOnClick, deleteAllNotificationsOnClick, t }) => {
    return (
        <div className="srp-card-details">
            <TextSignUpAlertMessage t={t}/>

            <div className="d-lg-block d-none">
                <div className="row mb-3">
                    <div className="col-lg-8" />
                    <div className="col-lg-2 text-muted">{t("Email")}</div>
                    <div className="col-lg-2 text-muted">{t("Text")}</div>
                </div>
            </div>

            {allAlerts.outageAlert.isAvailable
                ? <CommonAlert
                    alert={allAlerts.outageAlert}
                    alertTitle={t("Outage")}
                    alertDescription={allAlerts.outageAlert.notificationInfo.description}
                    phoneContacts={phoneContacts}
                    emailContacts={emailContacts}
                    addNotification={addNotificationOnClick}
                    deleteNotification={deleteNotificationOnClick}
                    deleteAllNotificationsOnClick={deleteAllNotificationsOnClick}
                    allowEmailSubscription={true}
                    allowTextSubscription={true}
                    t={t}
                />
                : null}
            
        </div>
    )
}

OutageAlerts.propTypes = {
    allAlerts: PropTypes.object,
    phoneContacts: PropTypes.arrayOf(PropTypes.object),
    emailContacts: PropTypes.arrayOf(PropTypes.object),

    addNotificationOnClick: PropTypes.func,
    deleteNotificationOnClick: PropTypes.func,
    deleteAllNotificationsOnClick: PropTypes.func,

    t: PropTypes.func.isRequired
}

export default OutageAlerts