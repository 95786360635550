/* eslint react/no-did-mount-set-state: "off" */

import React from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import SrpTooltip from '../../common_tooltip/srp-tooltip'
import { Formik } from 'formik'
import { updateBillAccountNickname } from '../../../actions/auth/bill_account/bill-account-actions'

class AssociatedAccountRow extends React.Component {
    constructor(props) {
        super(props)

        this.accountName = React.createRef()
        this.accountNumber = React.createRef()
        this.serviceAddress = React.createRef()

        this.state = {
            showEditNickname: false,
            accountNameTooltip: false,
            accountNumberTooltip: false,
            serviceAddressTooltip: false
        }

        this.updateNickname = this.updateNickname.bind(this)
        this.handleResize = this.handleResize.bind(this)
        this.toggleEditNickname = this.toggleEditNickname.bind(this)
        window.addEventListener('resize', this.handleResize)
    }

    componentDidMount() {
        if (this.accountName.current && this.accountNumber.current && this.serviceAddress.current)
        {
            this.setState({ ...this.state, 
                accountNameTooltip: this.accountName.current.scrollWidth > this.accountName.current.clientWidth,
                accountNumberTooltip: this.accountNumber.current.scrollWidth > this.accountNumber.current.clientWidth,
                serviceAddressTooltip: this.serviceAddress.current.scrollWidth > this.serviceAddress.current.clientWidth })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    handleResize() {
        if (this.accountName.current && this.accountNumber.current && this.serviceAddress.current)
        {
            let nextAccountNameTooltip = this.accountName.current.scrollWidth > this.accountName.current.clientWidth
            let nextAccountNumberTooltip = this.accountNumber.current.scrollWidth > this.accountNumber.current.clientWidth
            let nextServiceAddressTooltip = this.serviceAddress.current.scrollWidth > this.serviceAddress.current.clientWidth

            if (nextAccountNameTooltip !== this.state.accountNameTooltip || 
                nextAccountNumberTooltip !== this.state.accountNumberTooltip ||
                nextServiceAddressTooltip !== this.state.serviceAddressTooltip) {
                    this.setState({ ...this.state, 
                        accountNameTooltip: nextAccountNameTooltip,
                        accountNumberTooltip: nextAccountNumberTooltip,
                        serviceAddressTooltip: nextServiceAddressTooltip })
                }
        }
    }

    toggleEditNickname(show) {
        this.setState({  ...this.state, showEditNickname: show})
    }

    async updateNickname(values, formikGoodies) {
        let updateResponse = await this.props.updateNickname(values)
        formikGoodies.setSubmitting(false)

        if (!updateResponse || updateResponse.error === true || updateResponse.payload.success === false) {
            formikGoodies.setStatus({ error: "We're sorry, there was an error. Please try again later." })
        }
        else {
            this.setState({ ...this.state, showEditNickname: false })
        }
    }

    render() {
        let { account, accountName, accountText, isFinalAccount } = this.props.billAccountItem
        let disableEditAndDelete = this.props.disableEditAndDelete

        return (
            <React.Fragment>
                {this.state.showEditNickname
                    ? <Formik
                        onSubmit={this.updateNickname}
                        initialValues={{
                            billAccount: account,
                            nickname: accountName || ''
                        }}>
                        {({ values, status, handleBlur, handleChange, handleSubmit, isSubmitting }) => {
                            let formStatus = status || {}

                            return (
                                <form className="border-bottom p-2" onSubmit={handleSubmit}>
                                    {formStatus.error ? <div className="row mt-2 mb-2">
                                        <div className="col">
                                            <span className="srp-alert-error">{formStatus.error}</span>
                                        </div>
                                    </div> : null}
                                    <div className="row">
                                        <div className="col">
                                        <TextField value={accountText} fullWidth={true} disabled={true} label={this.props.t("SRP account number")}/>
                                        <TextField
                                            autoFocus={true}
                                            name="nickname"
                                            value={values.nickname}
                                            fullWidth={true}
                                            onFocus={e => e.target.select()}
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            label={this.props.t("Nickname")}
                                        />
                                        </div>
                                        <div className="col align-self-end text-right text-nowrap pt-2">
                                            <button type="button" className="btn srp-btn btn-lightblue"
                                                disabled={isSubmitting}
                                                onClick={() => this.toggleEditNickname(false)}>
                                                {this.props.t("Cancel")}
                                            </button>
                                            <button type="submit" className="btn srp-btn btn-green ml-2" disabled={isSubmitting || values.nickname === accountName}>
                                                {isSubmitting ? <CircularProgress size={20} thickness={5} color="secondary" /> : this.props.t("Update")}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            )
                        }}
                    </Formik>
                    : <div className="row pt-1 associated-account-row d-flex">
                        <div className="col-3 d-flex align-self-center pr-0" style={{ color: isFinalAccount ? 'rgb(158,42,43)' : 'inherit'}}>
                            {
                                accountName !== null && accountName.length > 0
                                    ? <div className="text-truncate">
                                        {this.state.accountNameTooltip ? 
                                            <SrpTooltip
                                                content={<span>{accountName}</span>}
                                            >
                                                <div className="text-truncate" ref={this.accountName}>
                                                    {accountName}
                                                </div>
                                            </SrpTooltip> :
                                            <div className="text-truncate" ref={this.accountName}>{accountName}</div>}
                                    </div>
                                    : <div ref={this.accountName}>-&nbsp;-</div>
                            }
                        </div>
                        <div className="d-flex col-9 pr-2">
                            <div className="col-3 px-0 d-flex align-self-center" style={{ width: '110px' }}>
                                {this.state.accountNumberTooltip ? 
                                    <SrpTooltip
                                        content={<span>{accountText}</span>}
                                    >
                                        <div ref={this.accountNumber}>
                                            {accountText}
                                        </div>
                                    </SrpTooltip> : 
                                    <div ref={this.accountNumber}>{accountText}</div>
                                }
                            </div>
                            <div className="d-flex col-6 px-0 align-self-center ml-3">
                                <div className="text-truncate">
                                    {this.state.serviceAddressTooltip ? 
                                        <SrpTooltip
                                            content={<span>{this.props.billAccountItem.serviceAddress.fullStreetAddress}</span>}
                                        >
                                            <div className="text-truncate" ref={this.serviceAddress}>
                                                {this.props.billAccountItem.serviceAddress.fullStreetAddress}
                                            </div>
                                        </SrpTooltip> : 
                                        <div className="text-truncate" ref={this.serviceAddress}>
                                            {this.props.billAccountItem.serviceAddress.fullStreetAddress}
                                        </div>
                                    } 
                                </div>
                            </div>
                            <div className="d-flex associated-account-row-action-buttons">
                                <IconButton color="primary" size="large"
                                    onClick={() => this.toggleEditNickname(true)}
                                    disabled={disableEditAndDelete} >
                                    <i className="material-icons">create</i>
                                </IconButton>
                                {
                                    this.props.hideDelete ? null :
                                        <IconButton disabled={disableEditAndDelete} color="primary" size="large"
                                            onClick={this.props.deleteBillAccountOnClick(account, accountName, false)}>
                                            {disableEditAndDelete ? <CircularProgress className="mr-1" size={20} thickness={5} /> : <i className="material-icons">delete</i>}
                                        </IconButton>
                                }
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

AssociatedAccountRow.propTypes = {
    billAccountItem: PropTypes.object.isRequired,
    hideDelete: PropTypes.bool.isRequired,
    disableEditAndDelete: PropTypes.bool.isRequired,
    deleteBillAccountOnClick: PropTypes.func.isRequired,
    updateNickname: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => {
    return {
        updateNickname: async (values) => {
            return await dispatch(updateBillAccountNickname(values.billAccount, values.nickname))
        }
    }
}

export default connect(null, mapDispatchToProps)(AssociatedAccountRow)