import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Switch from '@mui/material/Switch'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const SingleAccountReconnectDialog = ({setReconnectDialogOpen, isSolar, navUrl, selectedBillAccountDetails, sendReconnectRemark, t}) => {

    const [reconnectPower, setReconnectPower] = useState(null)
    const [choiceSelected, setChoiceSelected] = useState(false)

    let billAccount = selectedBillAccountDetails.account
    let authorizeReconnect = reconnectPower === "yes" ? true : false
   
    return (
        <Dialog
            open={!sessionStorage.getItem("SafetyDialogOpened")}
            sx={{
                ".MuiPaper-root" : {
                    maxWidth : {
                        xs : '420px',
                        lg : '680px'
                    },
                    padding: '20px'
                }
            }}
        >
            <div className="mx-3 mb-3">
                <h4><b>{t("Safety First!")}</b></h4>
            </div>
            <div className="mx-3 mb-3">
                {t("Before making a payment, SRP needs to know if it is safe to reconnect your power.")} <br /><br />

                {t("please_be_aware_the_risk")}
            </div>
            <div className="mx-2 mb-4 srp-alert-notice-DCO">
                <div className="font-weight-bold srp-dark-blue-color ml-2">{t("Is it safe to reconnect power at this time?")}</div>
                <RadioGroup
                    sx={{
                        margin:'0 25px'
                    }}
                >
                    <FormControlLabel
                        control={<Radio color="primary"/>}
                        value="yes"
                        checked={reconnectPower === "yes"}
                        onChange={()=>{
                            setChoiceSelected(false)
                            setReconnectPower("yes")  
                        }}
                        label={t("Yes, reconnect power")} 
                        sx={{
                            '& .MuiFormControlLabel-label': {
                                fontWeight: 'bold', // Customize the font size
                                color: '#087ED8', // Customize the label color
                            }
                        }}
                    />
                    <FormControlLabel
                        control={<Radio color="primary"/>}
                        value="no"
                        checked={reconnectPower === "no"}
                        onChange={()=>{
                            setChoiceSelected(false)
                            setReconnectPower("no") 
                        }}
                        label={t("No, I will call when ready")} 
                        sx={{
                            '& .MuiFormControlLabel-label': {
                                fontWeight: 'bold', // Customize the font size
                                color: '#087ED8', // Customize the label color
                            },
                            marginTop: '-20px'
                        }}
                    />
                </RadioGroup>

                {reconnectPower === null ?
                    null :
                    <div>
                        <div className="font-weight-bold srp-dark-blue-color ml-2 mb-1">{t("Understand your choice")}</div>
                        {reconnectPower === "yes" ? 
                            <div className="mb-2">
                                <div className="d-flex align-items-start ml-4 mb-2">
                                    <FormControlLabel
                                        value="yes"
                                        control={<Switch color="primary" />}
                                        checked={choiceSelected}
                                        labelPlacement="end"
                                        onChange={(e)=>{
                                            setChoiceSelected(e.target.checked)
                                        }}
                                    />
                                    {isSolar?
                                        <div className="reconnection-notice pt-1">{t("by_selecting_yes_solar")}</div>
                                        :  
                                        <div className="reconnection-notice pt-1">{t("by_selecting_yes_non_solar")}</div>
                                    }
                                </div>
                            </div> :
                            <div className="mb-2">
                                <div className="d-flex align-items-start ml-4 mb-2">
                                <FormControlLabel
                                    value="no"
                                    control={<Switch color="primary" />}
                                    checked={choiceSelected}
                                    labelPlacement="end"
                                    onChange={(e)=>setChoiceSelected(e.target.checked)}
                                />
                                <div className="reconnection-notice pt-1">{t("by_selecting_no")}</div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
            <div className="d-flex justify-content-end mx-2 mb-2">
                <button 
                    className="mx-2 btn srp-btn btn-lightblue"
                    onClick={()=>{
                        setReconnectDialogOpen(false)
                        sessionStorage.setItem("SafetyDialogOpened", true)
                }}>
                    {t("Dismiss")}
                </button>
                <button
                    className="btn srp-btn btn-blue"
                    disabled={!choiceSelected}
                    type="button"
                    onClick={()=>{
                        sendReconnectRemark(billAccount, authorizeReconnect)
                        setReconnectDialogOpen(false)
                        window.open(navUrl, "_blank")
                        sessionStorage.setItem("SafetyDialogOpened", true)
                    }}
                >
                    {t("Proceed")}
                </button>
            </div>
        </Dialog>
    )
}

SingleAccountReconnectDialog.propTypes = {
    setReconnectDialogOpen:PropTypes.func.isRequired,
    isSolar:PropTypes.bool.isRequired,
    navUrl:PropTypes.string.isRequired,
    selectedBillAccountDetails:PropTypes.object,
    sendReconnectRemark:PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
}

export default withTranslation("reconnectNotice")(SingleAccountReconnectDialog)
