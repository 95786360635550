import * as React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as loadingStatus from '../../constants/loading-status-constants'
import drawHorizontalChart from  '../../srp_modules/charting/daily-net-horizontal'
import drawVerticalChart from  '../../srp_modules/charting/daily-net-vertical'
import { convertDailyDataForCharting } from '../../srp_modules/charting/daily-data-dto'
import { DateTime } from 'luxon'
import { dailyUsageChartTypeConstants } from '../../constants/daily-usage-chart-type-constants'
import { initHourlyUsagePage } from '../../actions/auth/usage/usage-actions'
let initializeChart = (dailyUsageList,
    weatherData,
    targetId,
    currentChartType,
    startDate,
    endDate,
    barClickCallback,
    t
    ) => {
    let isCost = currentChartType === dailyUsageChartTypeConstants.COST
    if(dailyUsageList.length > 0){
        let usage =  sliceUsageData(dailyUsageList,startDate,endDate)
        let weather = sliceWeatherData(weatherData,startDate,endDate)

        window.innerWidth < 1024
            ? drawHorizontalChart(usage, weather, isCost, currentChartType, barClickCallback, t)
            : drawVerticalChart(usage, weather, isCost, barClickCallback, t)
    }
}

class DailyNetChartContainer extends React.Component{
    constructor(props) {
        super(props)
      this.state = {
          resizeListener: this.updateChart.bind(this)
      }
    }
    componentDidMount() {
        window.addEventListener("resize", this.state.resizeListener)
        if (this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.dailyWeather.dailyWeatherStatus === loadingStatus.LOADING_STATUS_SUCCESS)

        this.updateChart()
    }

    shouldComponentUpdate(nextProps) {
        return (this.props.dailyUsage.dailyUsageStatus !== nextProps.dailyUsage.dailyUsageStatus
                || this.props.rateMetaData.rateMetaDataStatus !== nextProps.rateMetaData.rateMetaDataStatus
                || this.props.dailyWeather.dailyWeatherStatus !== nextProps.dailyWeather.dailyWeatherStatus
                || this.props.currentDailyChart !== nextProps.currentDailyChart
                || this.props.dailyStartDate !== nextProps.dailyStartDate
                || this.props.dailyEndDate !== nextProps.dailyEndDate
                || this.props.t !== nextProps.t
            )
        }

    componentDidUpdate() {
        if (this.props.dailyUsage.dailyUsageStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.dailyWeather.dailyWeatherStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS
        )
            this.updateChart()
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.state.resizeListener)
    }

    updateChart() {
        initializeChart(
            convertDailyDataForCharting(this.props.dailyUsage.dailyUsageList, this.props.currentDailyChart),
            this.props.dailyWeather.dailyWeatherDataList,
            "chart",
            this.props.currentDailyChart,
            this.props.dailyStartDate,
            this.props.dailyEndDate,
            this.props.initHourlyUsagePage,
            this.props.t
        )
    }

    render() {

        return(
            <div>
                <div id="usagePageChartContainer">
                    <div id="dailyUsageLegendContainer" className="chart-legend-container">
                        <div id="superOffLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Super off-peak")}</span>
                            <div className="usage-chart-legend-circle viz-SuperOffPeak" />
                        </div>
                        <div id="offPeakLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Off-peak")}</span>
                            <div className="usage-chart-legend-circle viz-OffPeak" />
                        </div>
                        <div id="shoulderLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Shoulder")}</span>
                            <div className="usage-chart-legend-circle viz-Shoulder" />
                        </div>
                        <div id="onPeakLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("On-peak")}</span>
                            <div className="usage-chart-legend-circle viz-OnPeak" />
                        </div>
                        <div id="totalLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Total")}</span>
                            <div className="usage-chart-legend-circle viz-Total" />
                        </div>
                        <div id="totalLegendItemCost" className="chart-legend-item-hidden">
                            <span>{this.props.t("Cost")}</span>
                            <div className="usage-chart-legend-circle viz-Total" />
                        </div>
                        <div id="highTempLegendItem" className="usage-chart-legend-item-show">
                            <span>{this.props.t("High temperature")}</span>
                            <div className="usage-chart-legend-line viz-high-temp" />
                        </div>
                        <div id="lowTempLegendItem" className="usage-chart-legend-item-show">
                            <span>{this.props.t("Low temperature")}</span>
                            <div className="usage-chart-legend-line viz-low-temp" />
                        </div>
                    </div>
                    <div className=".viz-tooltip-arrow-main" />
                </div>
            </div>
        )
    }

}

DailyNetChartContainer.propTypes = {
    dailyUsage: PropTypes.object,
    dailyUsageList: PropTypes.array,
    selectedBillAccount: PropTypes.number,
    dailyWeather: PropTypes.object,
    getDailyUsageForAccount: PropTypes.func,
    getDailyWeather: PropTypes.func,
    selectedUsagePageChartTab: PropTypes.number,
    rateMetaData: PropTypes.object,
    getRateMetaDataForAccount: PropTypes.func,
    showDailyCostChart: PropTypes.bool,
    dailyStartDate: PropTypes.object,
    dailyEndDate: PropTypes.object,
    currentDailyChart: PropTypes.string,
    initHourlyUsagePage: PropTypes.func,
    t: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        dailyUsage: state.dailyUsage,
        dailyUsageList: state.dailyUsage.dailyUsageList,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        dailyWeather: state.weatherData,
        isCost: PropTypes.bool,
        selectedUsagePageChartTab: state.accountInfo.usagePageUI.selectedUsagePageChartTab,
        rateMetaData: state.rateMetaData,
        ...state.accountInfo.usagePageUI
    }
}

function sliceUsageData(data, startDate, endDate){
    let startIndex = data.findIndex(getIndexOfUsageDate, startDate)
    let endIndex = data.findIndex(getIndexOfUsageDate, endDate)

    return (startIndex >= 0 && endIndex >= 0)
        ?  data.slice(startIndex, endIndex + 1)
        :  []
}

function sliceWeatherData(data, startDate, endDate) {
    let startIndex = data.findIndex(getIndexOfWeatherDate, startDate)
    let endIndex = data.findIndex(getIndexOfWeatherDate, endDate)

    return (startIndex >= 0 && endIndex >= 0)
        ?  data.slice(startIndex, endIndex + 1)
        :  []
}

function getIndexOfUsageDate(element) {
    let elementDate = DateTime.fromISO(element.date)
    return elementDate.hasSame(this, 'day')
}
function getIndexOfWeatherDate(element) {
    let elementDate = DateTime.fromISO(element.weatherDate)
    return elementDate.hasSame(this, 'day')
}

const mapDispatchToProps = (dispatch) => {
    return {
       initHourlyUsagePage: (date, chartType) => {
           dispatch(
            initHourlyUsagePage(date, chartType))
       }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DailyNetChartContainer)
