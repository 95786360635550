import { Duration } from 'luxon'
import { outageConstants } from '../../../constants/outage-constants'
import { billAccountConstants } from '../../../constants/bill-account-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

const initialState = {
    customerInOutageInfo: {
        isInOutageArea: false,
        outageId: "",
        callBackNumber: "",
        estimatedRestorationTime: "",
        reportedOutageTime: "",
        hasReportedTheOutage: false,
        requestCallback: false,
        outageBegan: "",
        estimatedUsersImpacted: 0,
        crossRoadText: "",
        outageProblem: ""
    },
    customerInOutageInfoStatus: loadingStatus.LOADING_STATUS_INIT,
    outageHistoryList: [],
    serviceReliability: 0,
    outageHistoryCount: "",
    totalOutageHistoryDuration: '',
    outageHistoryTimeSpan: "",
    outageHistoryStatus: loadingStatus.LOADING_STATUS_INIT,

    //outageMap
    defaultProps: {
        center: { lat: 33.548264, lng: -112.191696 },
        zoom: 10
    },
    marker: {},
    outageMarkerInfo: {},
    legendStyle: { width: '0' },
    legendButtonStyle: { display: 'block' },
    outageDetailStyle: { width: '0', top: '0' },
    customerOutageDetailStyle: { width: '0', top: '0' },

    allOutagesInfo: [],
    outagesAreLoading: false,
    outagesHaveLoaded: false,
    outagesHaveFailed: false,
    allOutagesStatus: loadingStatus.LOADING_STATUS_INIT,

    iceReimbursementInfo: {
        showIceReimbursementOffer: false,
        minOutageHoursRequired: 0,
        maxReimbursementAmount: 0,
        sendEmailTo: ""
    },
    iceReimbursementInfoStatus: loadingStatus.LOADING_STATUS_INIT,

    outageHistoryIndex: 0
}

/* NOTE: C# TimeSpans will be in one of the following formats:
            -[hours]:[minutes]:[seconds]
            -[hours]:[minutes]:[seconds].[fractionalSeconds]
            -[days].[hours]:[minutes]:[seconds]
            -[days].[hours]:[minutes]:[seconds].[fractionalSeconds]
*/
let timeSpanRegex = /(?<sign>-)?(?:(?<days>[0-9]+)\.)?(?<hours>[0-9]+):(?<minutes>[0-9]+):(?<seconds>[0-9]+(?:\.[0-9]+)?)/

let getServiceHistoryDisplay = (serviceHistoryDuration) => {
    const maxNumberOfMonthsForHistory = 36
    let { sign, days, hours, minutes, seconds } = timeSpanRegex.exec(serviceHistoryDuration).groups
    let duration = Duration.fromISO(`${sign || ''}P${days || 0}DT${hours}H${minutes}M${seconds}S`).shiftTo('months')

    // Limit max to 36 months after rounding
    let numberMonthsBillAccountOpened = Math.min(maxNumberOfMonthsForHistory, Math.round(duration.months))
    if (numberMonthsBillAccountOpened === 1) {
        return "1 month"
    }
    else {
        return numberMonthsBillAccountOpened + " months"
    }
}

export default function outageReducer(state = initialState, action) {
    switch (action.type) {
        case outageConstants.GET_IS_IN_OUTAGE_REQUEST:
            return { ...state, customerInOutageInfoStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case outageConstants.GET_IS_IN_OUTAGE_SUCCESS:
            return { ...state, customerInOutageInfo: action.payload, customerInOutageInfoStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case outageConstants.GET_IS_IN_OUTAGE_FAILURE:
            return { ...state, customerInOutageInfo: { ...initialState.customerInOutageInfo }, customerInOutageInfoStatus: loadingStatus.LOADING_STATUS_FAILURE }
        case outageConstants.UPDATE_IS_IN_OUTAGE_SUCCESS:
            return { ...state, customerInOutageInfo: action.payload, customerInOutageInfoStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case outageConstants.UPDATE_IS_IN_OUTAGE_FAILURE:
            return { ...state, customerInOutageInfo: { ...initialState.customerInOutageInfo }, customerInOutageInfoStatus: loadingStatus.LOADING_STATUS_FAILURE }
        case outageConstants.GET_OUTAGE_HISTORY_REQUEST:
            return { ...state, outageHistoryStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case outageConstants.GET_OUTAGE_HISTORY_SUCCESS:
            return {
                ...state,
                outageHistoryList: action.payload.outageHistoryList,
                outageHistoryCount: action.payload.outageHistoryList.length == 1
                    ? "1 outage"
                    : `${action.payload.outageHistoryList.length} outages`,
                totalOutageHistoryDuration: action.payload.totalOutageDuration,
                outageHistoryTimeSpan: getServiceHistoryDisplay(action.payload.serviceHistoryDuration),
                serviceReliability: action.payload.serviceReliability,
                outageHistoryStatus: loadingStatus.LOADING_STATUS_SUCCESS
            }
        case outageConstants.GET_OUTAGE_HISTORY_FAILURE:
            return {
                ...state,
                outageHistoryList: { ...initialState.outageHistoryList },
                outageHistoryCount: { ...initialState.outageHistoryCount },
                totalOutageHistoryDuration: { ...initialState.totalOutageHistoryDuration },
                outageHistoryTimeSpan: { ...initialState.outageHistoryTimeSpan },
                serviceReliability: { ...initialState.serviceReliability },
                outageHistoryStatus: loadingStatus.LOADING_STATUS_FAILURE
            }

        //OutagesMap
        case outageConstants.HIDE_LEGEND:
            return {
                ...state,
                legendStyle: { width: '0' },
                legendButtonStyle: { display: 'block' },
                outageDetailStyle: { width: '0' },
                customerOutageDetailStyle: { width: '0' }
            }
        case outageConstants.SHOW_LEGEND:
            return {
                ...state,
                legendStyle: { width: '300px' },
                legendButtonStyle: { display: 'none' },
                outageDetailStyle: { width: '0' },
                customerOutageDetailStyle: { width: '0' }
            }
        case outageConstants.HIDE_OUTAGE_DETAILS:
            return {
                ...state,
                outageDetailStyle: { width: '0' },
                customerOutageDetailStyle: { width: '0' },
                legendStyle: { width: '0' },
                legendButtonStyle: { display: 'block' }
            }
        case outageConstants.SHOW_OUTAGE_DETAILS:
            return {
                ...state,
                outageMarkerInfo: action.outageMarkerInfo,
                outageDetailStyle: { width: '300px' },
                customerOutageDetailStyle: { width: '0' },
                legendStyle: { width: '0' },
                legendButtonStyle: { display: 'none' }
            }
        case outageConstants.HIDE_CUSTOMER_IN_OUTAGE_DETAILS:
            return {
                ...state,
                customerOutageDetailStyle: { width: '0' },
                outageDetailStyle: { width: '0' },
                legendStyle: { width: '0' },
                legendButtonStyle: { display: 'block' }
            }
        case outageConstants.SHOW_CUSTOMER_IN_OUTAGE_DETAILS:
            return {
                ...state,
                customerOutageDetailStyle: { width: '300px' },
                outageDetailStyle: { width: '0' },
                legendStyle: { width: '0' },
                legendButtonStyle: { display: 'none' }
            }

        case outageConstants.GET_ALL_OUTAGES_REQUEST:
            return { ...state, allOutagesStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case outageConstants.GET_ALL_OUTAGES_SUCCESS:
            return { ...state, allOutagesInfo: action.payload, allOutagesStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case outageConstants.GET_ALL_OUTAGES_FAILURE:
            return { ...state, allOutagesInfo: initialState.allOutagesInfo, allOutagesStatus: loadingStatus.LOADING_STATUS_FAILURE }
        case outageConstants.UPDATE_ALL_OUTAGES_SUCCESS:
            return { ...state, allOutagesInfo: action.payload, allOutagesStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case outageConstants.UPDATE_ALL_OUTAGES_FAILURE:
            return { ...state, allOutagesInfo: initialState.allOutagesInfo, allOutagesStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case outageConstants.GET_ICE_REIMBURSEMENT_INFO_REQUEST:
            return { ...state, iceReimbursementInfoStatus: loadingStatus.LOADING_STATUS_IN_PROGRESS }
        case outageConstants.GET_ICE_REIMBURSEMENT_INFO_SUCCESS:
            return { ...state, iceReimbursementInfo: action.payload, iceReimbursementInfoStatus: loadingStatus.LOADING_STATUS_SUCCESS }
        case outageConstants.GET_ICE_REIMBURSEMENT_INFO_FAILURE:
            return { ...state, iceReimbursementInfo: initialState.iceReimbursementInfo, iceReimbursementInfoStatus: loadingStatus.LOADING_STATUS_FAILURE }

        case billAccountConstants.SELECT_BILL_ACCOUNT_SUCCESS:
            return {
                ...state,
                customerInOutageInfoStatus: loadingStatus.LOADING_STATUS_INIT,
                outageHistoryStatus: loadingStatus.LOADING_STATUS_INIT,
                allOutagesStatus: loadingStatus.LOADING_STATUS_INIT,
                iceReimbursementInfoStatus: loadingStatus.LOADING_STATUS_INIT,
                outageHistoryIndex: 0
            }
        case outageConstants.GET_NEXT_OUTAGE_HISTORY:
            return { ...state, outageHistoryIndex: state.outageHistoryIndex >= (state.outageHistoryList.length - 1) ? state.outageHistoryIndex : state.outageHistoryIndex + 1 }
        case outageConstants.GET_PREV_OUTAGE_HISTORY:
            return { ...state, outageHistoryIndex: state.outageHistoryIndex <= 0 ? 0 : state.outageHistoryIndex - 1 }
        default:
            return state;
    }
}