/*eslint no-unused-vars: "warn"*/
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import * as loadingStatus from '../../constants/loading-status-constants'
import { billAccountConstants } from '../../constants/bill-account-constants'
import { getAutopayInfo } from '../../actions/auth/payment/autopay-actions'
import { getServiceAddress, getCustomerNames } from '../../actions/auth/bill_account/bill-account-actions'
import EnrollAutopayPage from './enroll-autopay-page'
import AutopayCashOnlyPage from '../autopay_cash_only_page/autopay-cash-only-page'
import AutopayIneligiblePage from '../autopay_ineligible_page/autopay-ineligible-page'
import AutopayLoadingPage from '../common_autopay/autopay-loading-page'
import { withRouter } from '../../srp_modules/with-router'

class EnrollAutopayPageContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount(){
        this.updateDataIfNeeded(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateDataIfNeeded(nextProps)
    }

    hasLoaded(props){
        let statusList = [loadingStatus.LOADING_STATUS_INIT, loadingStatus.LOADING_STATUS_IN_PROGRESS]

        return (statusList.indexOf(props.autopayInfoStatus) === -1 &&
            statusList.indexOf(props.serviceAddressStatus) === -1 &&
            statusList.indexOf(props.customerNamesStatus) === -1)
    }

    updateDataIfNeeded(props){
        if (props.selectedBillAccount === 0) {
            return
        }

        if (props.autopayInfoStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.actions.getAutopayInfo(props.selectedBillAccount)
        }

        if (props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.actions.getServiceAddress(props.selectedBillAccount)
        }

        if (props.customerNamesStatus === loadingStatus.LOADING_STATUS_INIT) {
            props.actions.getCustomerNameInfo(props.selectedBillAccount)
        }
    }

    render() {
        if(!this.hasLoaded(this.props)) {
            return (<AutopayLoadingPage
                        billAccount={this.props.selectedBillAccount}
                        isResidential={this.props.isResidential}
                    />)
        }

        if(this.props.autopayInfo.isEnrolled) {
            // redirect to bank profile page
            this.props.router.navigate('/myaccount/profile/banks', { replace: true })
        }

        if(this.props.autopayInfo.isCashOnly) {
            return (
                <AutopayCashOnlyPage
                    billAccount={this.props.selectedBillAccount}
                    autopayInfoStatus={this.props.autopayInfoStatus}
                    selectBillAccountStatus={this.props.selectBillAccountStatus}
                    t={this.props.t}
                />
            )
        }

        if(!this.props.autopayInfo.isEligibleToEnroll
            || this.props.closedState === billAccountConstants.CLOSED_STATE.CLOSED) {
            // diplay not eligible page
            return(
                <AutopayIneligiblePage
                    billAccount={this.props.selectedBillAccount}
                    customerName={this.props.autopayInfo.isResidential ?
                        this.props.customerNames.firstName + " " + this.props.customerNames.lastName :
                        this.props.customerNames.customerName}
                    serviceAddress={{
                        streetAddress: this.props.serviceAddress.streetAddress,
                        cityStateZip: this.props.serviceAddress.cityStateZip
                    }}
                    autopayInfoStatus={this.props.autopayInfoStatus}
                    selectBillAccountStatus={this.props.selectBillAccountStatus}
                    isResidential={this.props.autopayInfo.isResidential}
                    t={this.props.t}
                    i18n={this.props.i18n}
                />)
        }

        return (
            <EnrollAutopayPage
                autopayInfo={this.props.autopayInfo}
                autopayInfoStatus={this.props.autopayInfoStatus}
                billAccount={this.props.selectedBillAccount}
                selectBillAccountStatus={this.props.selectBillAccountStatus}
                customerName={this.props.autopayInfo.isResidential ?
                    this.props.autopayInfo.customerInfo.firstName + " " + this.props.autopayInfo.customerInfo.lastName :
                    this.props.autopayInfo.businessInfo.businessName}
                serviceAddress={{
                    streetAddress: this.props.serviceAddress.streetAddress,
                    cityStateZip: this.props.serviceAddress.cityStateZip
                }}
                isResidential={this.props.autopayInfo.isResidential}
                t={this.props.t}
                i18n={this.props.i18n}
            />
        )
    }
}

EnrollAutopayPageContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    autopayInfo: PropTypes.object.isRequired,
    autopayInfoStatus: PropTypes.string.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func
    }).isRequired,
    selectedBillAccount: PropTypes.number.isRequired,
    selectBillAccountStatus: PropTypes.string.isRequired,
    serviceAddress: PropTypes.object.isRequired,
    serviceAddressStatus: PropTypes.string.isRequired,
    isResidential: PropTypes.bool.isRequired,
    closedState: PropTypes.number,
    customerNames: PropTypes.object,
    customerNamesStatus: PropTypes.string,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

const mapStateToProps = (state) => {
    return {
        autopayInfo: state.accountInfo.autopay.autopayInfo,
        autopayInfoStatus: state.accountInfo.autopay.autopayInfoStatus,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        selectBillAccountStatus: state.accountInfo.billAccount.selectBillAccountStatus,
        serviceAddress: state.accountInfo.billAccount.serviceAddress,
        serviceAddressStatus: state.accountInfo.billAccount.serviceAddressStatus,
        isResidential: !state.accountInfo.billAccount.selectedBillAccountDetails.isCommercial,
        closedState: state.accountInfo.billAccount.selectedBillAccountDetails.closedState,
        customerNames: state.accountInfo.billAccount.customerNames,
        customerNamesStatus: state.accountInfo.billAccount.customerNamesStatus
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            getAutopayInfo: async (billAccount) => {
                await dispatch(getAutopayInfo(billAccount))
            },
            getServiceAddress: async (billAccount) => {
                await dispatch(getServiceAddress(billAccount))
            },
            getCustomerNameInfo: (billAccount) => {
                dispatch(getCustomerNames(billAccount))
            }
        }
    }
}

export default withTranslation("addUpdateSurePay")(withRouter(connect(mapStateToProps, mapDispatchToProps)(EnrollAutopayPageContainer)))
