import React from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import PropTypes from 'prop-types'

import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'

import format from '../../../srp_modules/format'
import { formatDayWithOrdinal } from '../../../srp_modules/utils'

import validate from '../../../srp_modules/validate'

class CustomDueDateConfirmation extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            dueDateChanged: false,
            showLongerBillingCycleNotice: false
        }

        this.validateForm = this.validateForm.bind(this)
        this.submitForm = this.submitForm.bind(this)
        this.onCustomDueDateChange = this.onCustomDueDateChange.bind(this)
    }

    validateForm(values) {
        let errors = {}

        if (values.emailAddress === undefined || values.emailAddress.length === 0)
            errors.email = 'Email address is required.'
        if (!validate.emailAddress(values.emailAddress))
            errors.invalidEmail = 'Please enter a valid email address.'

        return errors
    }

    async submitForm(values, goodies) {
        let result = await this.props.submitCustomDueDate()(values, goodies, this.props.isCommercial)

        if (result.payload.success) {
            this.props.nextDueDateStep(values.emailAddress)
        }
    }

    onCustomDueDateChange(newDay, initialDay) {
        let dueDateChanged = (newDay !== initialDay)

        this.setState({
            dueDateChanged,
            showLongerBillingCycleNotice: this.props.isCustomDueDateEnrolled && dueDateChanged
        })
    }

    render() {
        const t = this.props.t

        let currentOrDefaultCustomDate = this.props.customDueDayEnrolled === 0 ? 1 : this.props.customDueDayEnrolled
        let hasEmailOnFile = !!this.props.stateData.submittedEMail

        return (
            <Formik
                initialValues={{
                    emailAddress: this.props.stateData.submittedEMail,
                    customDate: currentOrDefaultCustomDate,
                    customDueDateData: this.props.stateData.customDueDateData
                }}
                validate={this.validateForm}
                onSubmit={this.submitForm}>
                {({ values, initialValues, status, handleSubmit, isSubmitting, handleChange, handleBlur, errors, touched }) => {
                    let emailError = touched.emailAddress && errors.email !== undefined
                    let invalidEmailError = touched.emailAddress && errors.invalidEmail !== undefined

                    let changeStatus = status || {}

                    let confirmationData = (<React.Fragment>
                        <div style={{ paddingTop: "10px" }}><span className="font-weight-bold" style={{ paddingRight: '.8em' }}>{t("Name")}</span> {this.props.stateData.submittedName}</div>
                        <div><span className="font-weight-bold" style={{ paddingRight: '.8em' }}>{t("SRP account number")}</span> {format.formatBillAccountNumber(this.props.stateData.submittedBillAccount)}</div>
                        <div><span className="font-weight-bold" style={{ paddingRight: '.8em' }}>{t("Service Address")}</span> {this.props.stateData.submittedAddress}</div>
                        <div><span className="font-weight-bold" style={{ paddingRight: '.8em' }}>{t("Phone number")}</span> {format.formatPhoneNumber(this.props.stateData.submittedPhone)}</div>
                        <div style={{ paddingTop: "20px" }}><span>{t("A confirmation email will be sent to")} </span>
                            {hasEmailOnFile
                                ?
                                <span className="font-weight-bold">{this.props.stateData.submittedEMail}</span>
                                :
                                <TextField
                                    fullWidth
                                    name="emailAddress"
                                    value={values.emailAddress}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={
                                        emailError
                                            ? t(errors.email)
                                            : invalidEmailError
                                                ? t(errors.invalidEmail)
                                                : ''}
                                    error={emailError || invalidEmailError} />
                            }
                        </div>
                    </React.Fragment>)

                    if (this.props.isCommercial) {
                        confirmationData = (<React.Fragment>
                            <div style={{ paddingTop: "10px" }}><span className="font-weight-bold" style={{ paddingRight: '.8em' }}>{t("Business name")}</span> {this.props.stateData.submittedName}</div>
                            <div><span className="font-weight-bold" style={{ paddingRight: '.8em' }}>{t("SRP account number")}</span> {format.formatBillAccountNumber(this.props.stateData.submittedBillAccount)}</div>
                            {this.props.stateData.customDueDateData.businessInfoModel.dbaName ?
                                <div><span className="font-weight-bold" style={{ paddingRight: '.8em' }}>{t("DBA name")}</span> {this.props.stateData.customDueDateData.businessInfoModel.dbaName}</div> :
                                null
                            }
                            {this.props.stateData.customDueDateData.businessInfoModel.contactName ?
                                <div><span className="font-weight-bold" style={{ paddingRight: '.8em' }}>{t("Contact name")}</span> {this.props.stateData.customDueDateData.businessInfoModel.contactName}</div> :
                                null
                            }
                            {this.props.stateData.customDueDateData.businessInfoModel.contactPosition ?
                                <div><span className="font-weight-bold" style={{ paddingRight: '.8em' }}>{t("Contact position")}</span> {this.props.stateData.customDueDateData.businessInfoModel.contactPosition}</div> :
                                null
                            }
                            <div><span className="font-weight-bold" style={{ paddingRight: '.8em' }}>{t("Service Address")}</span> {this.props.stateData.submittedAddress}</div>
                            <div><span className="font-weight-bold" style={{ paddingRight: '.8em' }}>{t("Phone number")}</span> {format.formatPhoneNumber(this.props.stateData.submittedPhone)}</div>
                            <div style={{ paddingTop: "20px" }}><span>{t("A confirmation email will be sent to")} </span>
                            {hasEmailOnFile
                                ?
                                <span className="font-weight-bold">{this.props.stateData.submittedEMail}</span>
                                :
                                <TextField
                                    fullWidth
                                    name="emailAddress"
                                    value={values.emailAddress}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={
                                        emailError
                                            ? t(errors.email)
                                            : invalidEmailError
                                                ? t(errors.invalidEmail)
                                                : ''}
                                    error={emailError || invalidEmailError} />
                            }
                            </div>
                        </React.Fragment>)
                    }

                    return (
                        <div>
                            <h3 className="text-muted">{t("SRP account information")}</h3>
                            {changeStatus.submitError &&
                            <div className="srp-alert-error mb-2" style={{ marginTop: '20px' }}>
                                {t("We're sorry, something went wrong. Please try again later.")}
                            </div>
                            }
                            {confirmationData}
                            <hr style={{ width: "100%" }} />
                            { this.props.isCustomDueDateEnrolled &&
                            <span>{t("Current_Custom_Date_ordinal", {currentCustomDate: formatDayWithOrdinal(currentOrDefaultCustomDate)})}</span>
                            }
                            <div style={{ paddingTop: "10px" }}>{t("Select the due date for your monthly bill.")}</div>

                            <form onSubmit={handleSubmit}>
                                <div style={{ paddingTop: "15px" }}>
                                    {t("Custom due date")}<br />
                                    <Select
                                        id="customDate"
                                        name="customDate"
                                        value={values.customDate}
                                        onChange={event => {handleChange(event); this.onCustomDueDateChange(event.target.value, initialValues.customDate)}}
                                        style={{ verticalAlign: 'middle', marginBottom: "4px", width: "50%" }}
                                    >
                                        {this.props.isCustomDueDateEnrolled &&
                                            <MenuItem key={currentOrDefaultCustomDate} value={currentOrDefaultCustomDate}>{t("Select a new date")}</MenuItem>
                                        }
                                        {[...Array(28).keys()].map(dayIndex => {
                                            let day = dayIndex + 1
                                            let disableDay = (this.props.isCustomDueDateEnrolled && day===currentOrDefaultCustomDate)
                                            return <MenuItem key={disableDay ? 0 : day} value={disableDay ? 0 : day} disabled={disableDay}>{t("customDateOrdinal", {customDateOrdinal: formatDayWithOrdinal(day)})}</MenuItem>
                                        })}
                                    </Select>
                                </div>
                                {this.state.showLongerBillingCycleNotice &&
                                <div className="alert srp-orange-background mt-2" role="alert">
                                {t("next_billing_cycle_longer")}
                                </div>
                                }
                                <div className="justify-content-end">
                                    <div className="col-md text-right pr-0 mt-2">
                                        {this.props.isLoggedIn
                                        ?
                                        <Link
                                            className="btn srp-btn btn-lightblue p-1 ml-2 mb-2"
                                            type="button"
                                            to="/myaccount/programs">
                                            {t("Back to Price plan & programs")}
                                        </Link>
                                        :
                                        <button type="button" disabled={isSubmitting}
                                            className="btn srp-btn btn-lightblue ml-2 mb-2"
                                            onClick={() => this.props.previousDueDateStep()}>
                                            {t("Previous")}
                                        </button>
                                        }
                                        <button type="submit" className="btn srp-btn btn-green-cdd ml-2 mb-2"
                                            disabled={(this.props.isCustomDueDateEnrolled && !this.state.dueDateChanged) || isSubmitting
                                                || emailError || invalidEmailError} >
                                            {isSubmitting ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} /> : t('Submit')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )
                }}
            </Formik>
        )
    }
}

CustomDueDateConfirmation.propTypes = {
    nextDueDateStep: PropTypes.func,
    submitCustomDueDate: PropTypes.func,
    customDueDateData: PropTypes.object,
    isCustomDueDateEnrolled: PropTypes.bool.isRequired,
    customDueDayEnrolled: PropTypes.number.isRequired,
    newDueDate: PropTypes.number,
    stateData: PropTypes.object,
    billAccount: PropTypes.number,
    isLoggedIn: PropTypes.bool.isRequired,
    previousDueDateStep: PropTypes.func,
    isCommercial: PropTypes.bool,
    t: PropTypes.func.isRequired
}

export default CustomDueDateConfirmation