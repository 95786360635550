import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import ChangeBillingAddressForm from './change-billing-address-form';

const initialState = {
    displayChangeBillingAddress: false,
    displayChangeBillingAddressSuccess: false
}

class BillingAddressSettings extends React.Component {

    constructor(props) {
        super(props)

        this.state = initialState

        this.setDisplayChangeBillingAddressForm = this.setDisplayChangeBillingAddressForm.bind(this)
        this.setDisplayChangePasswordSuccess = this.setDisplayChangePasswordSuccess.bind(this)
    }

    /* eslint react/no-did-update-set-state: "off" */
    /* It's ok to call setState in componentDidUpdate. See https://reactjs.org/docs/react-component.html . */
    componentDidUpdate(prevProps) {
        if(prevProps.selectedBillAccount != this.props.selectedBillAccount){
            this.setState({ ...initialState })
        }
    }

    setDisplayChangePasswordSuccess() {
        this.setState({ ...initialState, displayChangeBillingAddressSuccess: true })
    }

    setDisplayChangeBillingAddressForm(displayChangeBillingAddress) {
        return () => {
            this.setState({ ...initialState, displayChangeBillingAddress })
        }
    }

    formattedBillingAddress(billingAddress) {
        let formattedAddress = `${billingAddress.streetAddress} ${billingAddress.city}, ${billingAddress.state} ${this.formattedZipcode(billingAddress.zipcode)}`
        return formattedAddress !== ' ,  ' ? formattedAddress : ''
    }

    formattedStreetAddress(billingAddress) {
        let formattedAddress = `${billingAddress.streetAddress}`
        return formattedAddress
    }

    formattedCityStateZip(billingAddress) {
        let formattedAddress = `${billingAddress.city}, ${billingAddress.state} ${this.formattedZipcode(billingAddress.zipcode)}`
        return formattedAddress !== ',  ' ? formattedAddress : ''
    }

    formattedZipcode(zipcode) {
        switch (zipcode.length) {
            case 6:
                return `${zipcode.substring(0, 3)} ${zipcode.substring(3, 6)}`
            case 9:
                return `${zipcode.substring(0, 5)}-${zipcode.substring(5, 9)}`
            default:
                return zipcode
        }
    }

    render() {

        let billingAddressComponent = (
            <div className="d-flex flex-row h-100">
                <div className="d-flex flex-wrap align-self-center" style={{ flex: '1' }}>
                    <div className="d-flex flex-wrap pr-2 mr-2 p-lg-0 pl-1 pb-2" style={{ maxWidth: '100%' }}>
                        <span className="d-none d-sm-block text-nowrap">{this.formattedBillingAddress(this.props.billingAddress)}</span>
                        <span className="d-sm-none text-nowrap">{this.formattedStreetAddress(this.props.billingAddress)}</span>
                        <span className="d-sm-none text-nowrap">{this.formattedCityStateZip(this.props.billingAddress)}</span>
                    </div>
                </div>
                <div className="d-flex text-right">
                    <IconButton
                        className="align-self-center"
                        color="primary"
                        onClick={this.setDisplayChangeBillingAddressForm(true)}
                        size="large"><i className="material-icons">create</i></IconButton>
                </div>
            </div>
        )

        if (this.state.displayChangeBillingAddress === true)
            billingAddressComponent = (<ChangeBillingAddressForm
                setDisplayChangeBillingAddressForm={this.setDisplayChangeBillingAddressForm}
                setDisplayChangePasswordSuccess={this.setDisplayChangePasswordSuccess} 
                t={this.props.t}/>)

        return (
            <div className="row border-bottom h-100">
                <div className="col-lg-3 mr-2">
                    <div className="text-muted text-nowrap p-lg-2 pt-2 pl-2 pb-0 mt-lg-1">{this.props.t("Mailing address")}</div>
                </div>
                <div className="col-lg">
                    <div className="pl-1">
                        {this.state.displayChangeBillingAddressSuccess
                            ? <div className="srp-alert-success mb-2 mt-2">{this.props.t("You’ve successfully updated your mailing address.")}</div>
                            : null}
                        {billingAddressComponent}
                    </div>
                </div>
            </div>
        )
    }
}

BillingAddressSettings.propTypes = {
    billingAddress: PropTypes.object,
    selectedBillAccount: PropTypes.number,
    t: PropTypes.func.isRequired
}

export default BillingAddressSettings