import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { Formik } from 'formik'
import { withTranslation } from 'react-i18next'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'

import '../../../styles/price-plan.css'

import { pricePlanConstants } from '../../../constants/price-plan-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'
import transferModule from '../../../srp_modules/forms/transfer-module'
import format from '../../../srp_modules/format'
import CardBlue from '../../common_error/card-blue'

import { formatDayWithOrdinal } from '../../../srp_modules/utils'

import LearnAboutMPowerCosts from './learn-about-mpower-costs'
import LowBalanceNotifications from './low-balance-notifications'

const ReviewTransferServiceInfo = ({ activeStep, setFormStep, billAccount, isMPower, currentServiceAddress, startServiceDate, endServiceDate, selectedPricePlan,
    selectedSolarChoice, currentAutoPayEnrollment, currentCustomDueDateEnrollment, hasEBill, serviceBillingInfo, customerInfo, agreeToSafetyStmt,
    setSafetyStmtAgreementOnClick, sendMPowerBox, sendMPowerBoxOnClick, isOnCentralPrepay,
    submitTransferServiceStatus, submitForm, t, i18n }) => {
    return (
        <Formik
            enableReinitialize={false}
            initialValues={{
                agreeToSafetyStmt: agreeToSafetyStmt,
                sendMPowerBox: sendMPowerBox,
            }}
            validate={(values) => validateForm(values)}
            onSubmit={() => {
                submitForm()
            }}>
            {({ values, errors, touched, handleSubmit, setFieldValue, setFieldTouched }) => {
                let agreeToSafetyStmtError = (touched.agreeToSafetyStmt && errors.agreeToSafetyStmt !== undefined)

                let handleSafetyAgreement = value => {
                    setFieldValue('agreeToSafetyStmt', value)
                    setSafetyStmtAgreementOnClick(value)
                }

                let handleMPowerBoxOnChange = value => {
                    setFieldValue('sendMPowerBox', value)
                    sendMPowerBoxOnClick(value)
                }

                return (
                    <form
                        className="mb-2 mt-2 text-muted"
                        onSubmit={handleSubmit}
                    >
                        <div className="mb-3">
                            {`${t("You have requested to start service at")} `}
                            <span className="font-weight-bold">
                                {serviceBillingInfo.serviceAddressTwo.length === 0
                                    ? serviceBillingInfo.serviceAddressOne
                                    : `${serviceBillingInfo.serviceAddressOne} ${serviceBillingInfo.serviceAddressTwo}`
                                }
                            </span>
                            {` ${t("on")} `}
                            <span className="font-weight-bold">{DateTime.fromISO(startServiceDate).toFormat('M/d/yyyy')}</span>
                            {`. ${t("Your power may be turned on at any time during the day.")}`}
                        </div>

                        {selectedPricePlan === pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.MPOWER &&
                        <div className="mt-3 pb-3">
                        <LearnAboutMPowerCosts t={t} i18n={i18n} />
                        </div>
                        }

                        <div className="mt-1 mb-4">
                            <CardBlue
                                open={true}
                                message={t("we_may_need_to_call")}
                            />
                        </div>

                        <div className="mb-2 mt-lg-0 mt-2 section-header">
                            {t("About your new account")}
                        </div>
                        <div className="d-lg-block d-none mb-4">
                            <div>
                                <span className="font-weight-bold mr-2">{t("Price Plan")}</span>
                                <span>{transferModule.getPricePlanDescription(selectedPricePlan, i18n.language)}</span>
                            </div>
                            {selectedSolarChoice !== pricePlanConstants.SOLAR_CHOICE.NONE &&
                                <div>
                                    <span className="font-weight-bold mr-2">{t("SRP Solar Choice")}</span>
                                    <span>{transferModule.getSolarChoiceDisplay(selectedSolarChoice)}</span>
                                </div>
                            }
                            {currentAutoPayEnrollment.isEnrolled && !isMPower &&
                                <div>
                                    <span className="font-weight-bold mr-2">{t("SurePay")}</span>
                                    <span>
                                        {`${t("Your SurePay settings")} `}
                                        <span className="font-weight-bold">{serviceBillingInfo.maintainAutopay ? t("will") : t("will not")}</span>
                                        {` ${t("transfer to this account")}`}
                                    </span>
                                </div>
                            }
                            {currentCustomDueDateEnrollment.isEnrolled && !isMPower &&
                                <div>
                                    <span className="font-weight-bold mr-2">{t("Custom Due Date")}</span>
                                    <span>{formatDayWithOrdinal(serviceBillingInfo.customDueDate)} {t("of each month")}</span>
                                </div>
                            }
                            {hasEBill && !isMPower &&
                                <div>
                                    <span className="font-weight-bold mr-2">{t("Paperless billing")}</span>
                                    <span>
                                        {`${t("Your paperless billing settings")} `}
                                        <span className="font-weight-bold">{serviceBillingInfo.maintainPaperlessBill ? t("will") : t("will not")}</span>
                                        {` ${t("transfer to this account")}`}
                                    </span>
                                </div>
                            }
                            <div>
                                <span className="font-weight-bold mr-2">{t("Mailing address")}</span>
                                <span>
                                    {serviceBillingInfo.serviceAddIsMailingAdd
                                        ? serviceBillingInfo.serviceAddressTwo.length === 0
                                            ? `${serviceBillingInfo.serviceAddressOne}, ${serviceBillingInfo.serviceCity}, AZ`
                                            : `${serviceBillingInfo.serviceAddressOne} ${serviceBillingInfo.serviceAddressTwo}, ${serviceBillingInfo.serviceCity}, AZ`

                                        : serviceBillingInfo.mailingAddressTwo.length === 0
                                            ? `${serviceBillingInfo.mailingAddressOne}, ${serviceBillingInfo.mailingCity},
                                                    ${serviceBillingInfo.mailingState} ${serviceBillingInfo.mailingZipCode} ${serviceBillingInfo.mailingCountry}`
                                            : `${serviceBillingInfo.mailingAddressOne} ${serviceBillingInfo.mailingAddressTwo},
                                                    ${serviceBillingInfo.mailingCity}, ${serviceBillingInfo.mailingState} ${serviceBillingInfo.mailingZipCode}
                                                    ${serviceBillingInfo.mailingCountry}`
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="d-lg-none d-block mb-4">
                            <div className="row d-flex align-items-center">
                                <div className="col-5 text-muted font-weight-bold">{t("Price Plan")}</div>
                                <div className="col-7 text-right">{transferModule.getPricePlanDescription(selectedPricePlan, i18n.language)}</div>
                            </div>
                            <hr className="mt-1 mb-1" />
                            {selectedSolarChoice !== pricePlanConstants.SOLAR_CHOICE.NONE &&
                                <div>
                                    <div className="row d-flex align-items-center">
                                        <div className="col-7 text-muted font-weight-bold">{t("SRP Solar Choice")}</div>
                                        <div className="col-5 text-right">{transferModule.getSolarChoiceDisplay(selectedSolarChoice)}</div>
                                    </div>
                                    <hr className="mt-1 mb-1" />
                                </div>
                            }
                            {currentAutoPayEnrollment.isEnrolled && !isMPower &&
                                <div>
                                    <div className="row d-flex align-items-center">
                                        <div className="col-5 text-muted font-weight-bold">{t("SurePay")}</div>
                                        <div className="col-7 text-right">
                                            <span>
                                                {`${t("Your SurePay settings")} `}
                                                <span className="font-weight-bold">{serviceBillingInfo.maintainAutopay ? t("will") : t("will not")}</span>
                                                {` ${t("transfer to this account")}`}
                                            </span>
                                        </div>
                                    </div>
                                    <hr className="mt-1 mb-1" />
                                </div>
                            }
                            {currentCustomDueDateEnrollment.isEnrolled && !isMPower &&
                                <div>
                                    <div className="row d-flex align-items-center">
                                        <div className="col-5 text-muted font-weight-bold">{t("Custom Due Date")}</div>
                                        <div className="col-7 text-right">
                                            {formatDayWithOrdinal(serviceBillingInfo.customDueDate)} {t("of each month")}
                                        </div>
                                    </div>
                                    <hr className="mt-1 mb-1" />
                                </div>
                            }
                            {hasEBill && !isMPower &&
                                <div>
                                    <div className="row d-flex align-items-center">
                                        <div className="col-5 text-muted font-weight-bold">{t("Paperless billing")}</div>
                                        <div className="col-7 text-right">
                                            <span>
                                                {`${t("Your paperless billing settings")} `}
                                                <span className="font-weight-bold">{serviceBillingInfo.maintainPaperlessBill ? t("will") : t("will not")}</span>
                                                {` ${t("transfer to this account")}`}
                                            </span>
                                        </div>
                                    </div>
                                    <hr className="mt-1 mb-1" />
                                </div>
                            }
                            <div className="row d-flex align-items-center">
                                <div className="col-5 text-muted font-weight-bold">{t("Mailing address")}</div>
                                <div className="col-7 text-right">
                                    {serviceBillingInfo.serviceAddIsMailingAdd
                                        ? serviceBillingInfo.serviceAddressTwo.length === 0
                                            ? <div>
                                                <div>{serviceBillingInfo.serviceAddressOne}</div>
                                                <div>{`${serviceBillingInfo.serviceCity}, AZ`}</div>
                                            </div>
                                            : <div>
                                                <div>{`${serviceBillingInfo.serviceAddressOne} ${serviceBillingInfo.serviceAddressTwo}`}</div>
                                                <div>{`${serviceBillingInfo.serviceCity}, AZ`}</div>
                                            </div>
                                        : serviceBillingInfo.mailingAddressTwo.length === 0
                                            ? <div>
                                                <div>{serviceBillingInfo.mailingAddressOne}</div>
                                                <div>{`${serviceBillingInfo.mailingCity}, ${serviceBillingInfo.mailingState} ${serviceBillingInfo.mailingZipCode}`}</div>
                                                <div>{`${serviceBillingInfo.mailingCountry}`}</div>
                                            </div>
                                            : <div>
                                                <div>{`${serviceBillingInfo.mailingAddressOne} ${serviceBillingInfo.mailingAddressTwo}`}</div>
                                                <div>{`${serviceBillingInfo.mailingCity}, ${serviceBillingInfo.mailingState} ${serviceBillingInfo.mailingZipCode}`}</div>
                                                <div>{`${serviceBillingInfo.mailingCountry}`}</div>
                                            </div>
                                    }
                                </div>
                            </div>
                            <hr className="mt-1" />
                        </div>

                        <div className="mb-2 section-header">
                            {`About your current account`}
                        </div>
                        <div className="d-lg-block d-none mb-4">
                            <div>
                                <span className="font-weight-bold mr-2">{t("Stop service on")}</span>
                                <span>{DateTime.fromISO(endServiceDate).toFormat('EEEE,  MMMM d, yyyy')}</span>
                            </div>
                            <div>
                                <span className="font-weight-bold mr-2">{t("Bill account")}</span>
                                <span>{format.formatBillAccountNumber(billAccount)}</span>
                            </div>
                            <div>
                                <span className="font-weight-bold mr-2">{t("Service address")}</span>
                                <span className="text-capitalize">
                                    {`${(currentServiceAddress.streetAddress).toLowerCase()}, ${(currentServiceAddress.cityName.toLowerCase())}, AZ`}
                                </span>
                            </div>
                        </div>
                        <div className="d-lg-none d-block mb-4">
                            <div className="row d-flex align-items-center">
                                <div className="col-5 text-muted font-weight-bold">{t("Stop service on")}</div>
                                <div className="col-7 text-right">{DateTime.fromISO(endServiceDate).toFormat('EEEE,  MMMM d, yyyy')}</div>
                            </div>
                            <hr className="mt-1 mb-1" />
                            <div className="row d-flex align-items-center">
                                <div className="col-5 text-muted font-weight-bold">{t("Bill account")}</div>
                                <div className="col-7 text-right">{format.formatBillAccountNumber(billAccount)}</div>
                            </div>
                            <hr className="mt-1 mb-1" />
                            <div className="row d-flex align-items-center">
                                <div className="col-5 text-muted font-weight-bold">{t("Service address")}</div>
                                <div className="col-7 text-right text-capitalize">
                                    <div>{`${(currentServiceAddress.streetAddress).toLowerCase()}`}</div>
                                    <div>{`${(currentServiceAddress.cityName.toLowerCase())}, AZ`}</div>
                                </div>
                            </div>
                            <hr className="mt-1" />
                        </div>

                        <div className="mb-2 section-header">
                            {`About you`}
                        </div>
                        <div className="d-lg-block d-none mb-4">
                            <div>
                                {customerInfo.middleName.length !== 0
                                    ? `${customerInfo.firstName} ${customerInfo.middleName} ${customerInfo.lastName}`
                                    : `${customerInfo.firstName} ${customerInfo.lastName}`
                                }
                            </div>
                            <div>
                                <span className="font-weight-bold mr-2">{t("ID Type")}</span>
                                <span>{transferModule.getIDTypeDisplay(customerInfo.identificationType)}</span>
                            </div>
                            <div>
                                <span className="font-weight-bold mr-2">{t("ID")}</span>
                                <span>{transferModule.formatIDNumber(customerInfo.identificationNum)}</span>
                            </div>
                            <div>
                                <span className="font-weight-bold mr-2">{t("Email address")}</span>
                                <span>{customerInfo.emailAddress}</span>
                            </div>
                            <div>
                                <span className="font-weight-bold mr-2">{t("Phone number")}</span>
                                <span>{format.formatPhoneNumber(customerInfo.phoneNumber)}</span>
                            </div>
                        </div>
                        <div className="d-lg-none d-block mb-4">
                            <div className="row d-flex align-items-center">
                                <div className="col text-right">
                                    {customerInfo.middleName.length !== 0
                                        ? `${customerInfo.firstName} ${customerInfo.middleName} ${customerInfo.lastName}`
                                        : `${customerInfo.firstName} ${customerInfo.lastName}`
                                    }
                                </div>
                            </div>
                            <hr className="mt-1 mb-1" />
                            <div className="row d-flex align-items-center">
                                <div className="col-5 text-muted font-weight-bold">{t("ID Type")}</div>
                                <div className="col-7 text-right">{transferModule.getIDTypeDisplay(customerInfo.identificationType)}</div>
                            </div>
                            <hr className="mt-1 mb-1" />
                            <div className="row d-flex align-items-center">
                                <div className="col-5 text-muted font-weight-bold">{t("ID")}</div>
                                <div className="col-7 text-right">{transferModule.formatIDNumber(customerInfo.identificationNum)}</div>
                            </div>
                            <hr className="mt-1 mb-1" />
                            <div className="row d-flex align-items-center">
                                <div className="col-5 text-muted font-weight-bold">{t("Email address")}</div>
                                <div className="col-7 text-right">{customerInfo.emailAddress}</div>
                            </div>
                            <hr className="mt-1 mb-1" />
                            <div className="row d-flex align-items-center">
                                <div className="col-5 text-muted font-weight-bold">{t("Phone number")}</div>
                                <div className="col-7 text-right">{format.formatPhoneNumber(customerInfo.phoneNumber)}</div>
                            </div>
                            <hr className="mt-1" />
                        </div>

                        {selectedPricePlan === pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.MPOWER &&
                        <LowBalanceNotifications cssClass="mb-3" />
                        }

                        {
                            selectedPricePlan === pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.MPOWER && !isOnCentralPrepay &&
                                <div className="mb-3">
                                    <div className="mb-2 section-header">
                                        {t("Return in-home display unit")}
                                    </div>
                                    <div className="mb-3">{t("after_power_has_been_turned_off")}</div>
                                    <div>{t("Do you need us to send you a postage-paid box?")}</div>
                                    <FormControlLabel
                                        control={<Checkbox
                                            className="ml-1"
                                            name="agreeToSafetyStmt"
                                            checked={values.sendMPowerBox}
                                            onChange={() => handleMPowerBoxOnChange(!values.sendMPowerBox)}
                                            color="primary"
                                        />}
                                        label={<span className="font-weight-bold">{t("Yes, please send me a box")}</span>}
                                    />
                                </div>
                        }

                        {
                        <>
                        <div className="section-header">{t("Safety Statement")}</div>
                        <div className="mt-2 mb-3">
                            {t("safety_statement")}
                        </div>
                        {selectedPricePlan === pricePlanConstants.PRICE_PLAN_RATE_CODE_MAP.MPOWER &&
                        <div className="mb-2">{t("mpower_purchase_acknowledgement")}</div>
                        }

                        <div className="row mx-2">
                        <FormControlLabel
                            control={<Checkbox
                                className="ml-1"
                                name="agreeToSafetyStmt"
                                checked={values.agreeToSafetyStmt}
                                onChange={() => handleSafetyAgreement(!values.agreeToSafetyStmt)}
                                onBlur={() => setFieldTouched('agreeToSafetyStmt')}
                                color="primary"
                            />}
                            label={
                                !agreeToSafetyStmtError
                                    ? t("I have read and agree to the above safety statement.")
                                    : <div>
                                        <span className="mr-2">{t("I have read and agree to the above safety statement.")}</span>
                                        <span className="srp-red-text">{errors.agreeToSafetyStmt}</span>
                                    </div>
                            }
                        />
                        </div>
                        </>
                        }

                        <div className="d-flex flex-wrap justify-content-end mt-4">
                            <button
                                className="btn srp-btn btn-lightblue ml-2"
                                type="button"
                                onClick={() => setFormStep(activeStep - 1)}>
                                {t("Previous")}
                                </button>
                            <button
                                className="btn srp-btn btn-green ml-2"
                                type="submit"
                                disabled={submitTransferServiceStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS}>
                                {submitTransferServiceStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                                    ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} />
                                    : t("Submit")
                                }
                            </button>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

ReviewTransferServiceInfo.propTypes = {
    activeStep: PropTypes.number,
    setFormStep: PropTypes.func,

    billAccount: PropTypes.number,
    isMPower: PropTypes.bool,
    currentServiceAddress: PropTypes.object,
    startServiceDate: PropTypes.object,
    endServiceDate: PropTypes.object,
    selectedPricePlan: PropTypes.number,
    selectedSolarChoice: PropTypes.string,
    currentAutoPayEnrollment: PropTypes.object,
    currentCustomDueDateEnrollment: PropTypes.object,
    hasEBill: PropTypes.bool,
    serviceBillingInfo: PropTypes.object,
    customerInfo: PropTypes.object,

    agreeToSafetyStmt: PropTypes.bool,
    setSafetyStmtAgreementOnClick: PropTypes.func,

    sendMPowerBox: PropTypes.bool,
    sendMPowerBoxOnClick: PropTypes.func,

    submitTransferServiceStatus: PropTypes.string,
    submitForm: PropTypes.func,

    isOnCentralPrepay: PropTypes.bool.isRequired,

    t: PropTypes.func,
    i18n: PropTypes.object
}

export default withTranslation('transferService')(ReviewTransferServiceInfo)

function validateForm(values) {
    let errors = {}

    if (values.agreeToSafetyStmt === undefined || !values.agreeToSafetyStmt)
        errors.agreeToSafetyStmt = '*Required'

    return errors
}