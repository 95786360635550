import { DateTime } from 'luxon'
import { RSAA } from 'redux-api-middleware'
import { outageConstants } from '../../../constants/outage-constants'
import config from 'my-account-config'

//Actions for isInOutageArea api call
export const getIsInOutageArea = (billAccount) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: config.apiBaseUrl + 'api/outages/userinoutage?billAccount=' + encodeURIComponent(billAccount),
                method: 'GET',
                credentials: 'include',
                types: [
                    outageConstants.GET_IS_IN_OUTAGE_REQUEST,
                    outageConstants.GET_IS_IN_OUTAGE_SUCCESS,
                    outageConstants.GET_IS_IN_OUTAGE_FAILURE
                ]
            }
        })
    }
}

export const updateIsInOutageArea = (billAccount) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: config.apiBaseUrl + 'api/outages/userinoutage?billAccount=' + encodeURIComponent(billAccount),
                method: 'GET',
                credentials: 'include',
                types: [
                    outageConstants.UPDATE_IS_IN_OUTAGE_REQUEST,
                    outageConstants.UPDATE_IS_IN_OUTAGE_SUCCESS,
                    outageConstants.UPDATE_IS_IN_OUTAGE_FAILURE
                ]
            }
        })
    }
}

//Actions for getting outage history
export const getOutageHistory = (billAccount) => {
    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: config.apiBaseUrl + 'api/outages/history'
                    + '?billAccount=' + encodeURIComponent(billAccount),
                method: 'GET',
                credentials: 'include',
                types: [
                    outageConstants.GET_OUTAGE_HISTORY_REQUEST,
                    outageConstants.GET_OUTAGE_HISTORY_SUCCESS,
                    outageConstants.GET_OUTAGE_HISTORY_FAILURE
                ]
            }
        })
    }
}

//action for updating outage info
export const getOutageActions = (billAccount) => {
    return (dispatch) => {
        dispatch(getIsInOutageArea(billAccount))
        dispatch(getOutageHistory(billAccount))
    }
}
