import React from 'react'
import PropTypes from 'prop-types'

import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'

import { myAccountConstants } from '../../../constants/myaccount-constants'
import { paperlessBillingState as paperlessBillingEnum } from '../../../constants/paperless-billing-state-enum'

import PaymentConfirmationDetailsTableContainer from './payment-confirmation-details-table-container'
import GoPaperlessBanner from '../../common/go-paperless-banner'
import JustGonePaperlessBanner from '../common/just-gone-paperless-banner'

import PaperlessBillingDialog from './paperless-billing-dialog'

import { formEvent, autoFormStartEvent } from '../../../srp_modules/analytics-events'
import { paymentConstants } from '../../common_payment/payment-constants'
import { withRouter } from '../../../srp_modules/with-router'

export const ConfirmPaymentDetailsCardInternal = ({isSubmitting, submitPayment, goBackToEditPaymentDetails,
    errorSubmittingPayment, paperlessBillingState, turnOnPaperlessBilling, showPaperlessBillingDialog,
    closePaperlessBillingDialogOnClick, eBillEmailAddrPhoneNo, billAccount, paymentInfo,
    formStartEventEmitted, setFormStartEventEmitted, router, t, i18n}) => {

    const isSubmittingPaperlessBilling = paperlessBillingState==paperlessBillingEnum.ENROLLING
    const disableButtons = isSubmitting || isSubmittingPaperlessBilling 

    const disableSubmitButton = isSubmitting

    const [ confirmPaymentFormEventEmitted, setConfirmPaymentFormEventEmitted ] = React.useState(false)

    if (billAccount === 0 || paymentInfo.paymentAmount === 0) {
        router.navigate('/myaccount/payment/single', { replace: true })
        return null
    }

    const formName = paymentConstants.ADOBE_ANALYTICS_FORM_NAME

    const goBackToEditPaymentDetailsAndTrack = () => {
        const stepName = paymentConstants.PAYMENT_STEPS[0]
        formEvent(formName, stepName)

        goBackToEditPaymentDetails()
    }

    if (confirmPaymentFormEventEmitted === false) {
        if (formStartEventEmitted === false) {
            // user going to verification screen without touching the pre-filled form
            // need to auto generate formStartEvent
            const stepName = paymentConstants.PAYMENT_STEPS[0]
            autoFormStartEvent(formName, stepName)

            setFormStartEventEmitted()
        }

        const stepName = paymentConstants.PAYMENT_STEPS[1]
        formEvent(formName, stepName)

        setConfirmPaymentFormEventEmitted(true)
    }

    const submitPaymentAndTrack = () => {
        submitPayment(paymentInfo)
    }

    return (
    <div>
        <label className="h5">{t("Confirm payment details")}</label>
        <Card>
            {errorSubmittingPayment &&
            <div className="srp-alert-error mx-3 mt-3">
                {`${t("We are unable to complete your payment at this time. Please try again or contact us at")} ${myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD}.`}
            </div>
            }

            <PaymentConfirmationDetailsTableContainer />

            {(paperlessBillingState==paperlessBillingEnum.NOT_ENROLLED
            || paperlessBillingState==paperlessBillingEnum.ENROLLING
            || paperlessBillingState==paperlessBillingEnum.JUST_ENROLLED) &&
            <div className="m-3">
                {paperlessBillingState==paperlessBillingEnum.JUST_ENROLLED
                ? <JustGonePaperlessBanner
                    t={t}
                    i18n={i18n}
                />
                : <GoPaperlessBanner
                    turnOnPaperlessBilling={turnOnPaperlessBilling}
                    isSubmitting={isSubmittingPaperlessBilling}
                    disabled={disableButtons}
                    t={t}
                />}

                <PaperlessBillingDialog
                    eBillEmailAddrPhoneNo={eBillEmailAddrPhoneNo}
                    showDialog={showPaperlessBillingDialog}
                    closeDialogOnClick={closePaperlessBillingDialogOnClick}
                    t={t}
                    i18n={i18n}
                />
            </div>
            }

            <div className="px-3 pb-3">
                <h6 className="text-muted">{t("Click_submit_payment_text")}</h6>

                <div className="d-flex justify-content-end mt-4">
                    <button
                        className={"btn srp-btn btn-lightblue mr-2"}
                        disabled={disableButtons}
                        type="button"
                        onClick={goBackToEditPaymentDetailsAndTrack}
                        {...isSubmitting && {'aria-disabled': true, tabIndex: -1 }}>
                        <span className="d-lg-none">{t("Edit")}</span>
                        <span className="d-none d-lg-block">{t("Edit payment details")}</span>
                    </button>
                    <button className="btn srp-btn btn-green" type="button" onClick={()=>submitPaymentAndTrack()} disabled={disableSubmitButton}>
                        {isSubmitting ? <CircularProgress size={20} thickness={5} style={{color:'white'}} /> : t("Submit payment")}
                    </button>
                </div>
            </div>
        </Card>
    </div>
    )
}

ConfirmPaymentDetailsCardInternal.propTypes = {
    isSubmitting: PropTypes.bool.isRequired,
    submitPayment: PropTypes.func.isRequired,
    goBackToEditPaymentDetails: PropTypes.func.isRequired,
    errorSubmittingPayment: PropTypes.bool.isRequired,
    paperlessBillingState: PropTypes.string.isRequired,
    turnOnPaperlessBilling: PropTypes.func.isRequired,
    showPaperlessBillingDialog: PropTypes.bool.isRequired,
    closePaperlessBillingDialogOnClick: PropTypes.func.isRequired,
    eBillEmailAddrPhoneNo: PropTypes.string.isRequired,
    billAccount: PropTypes.number.isRequired,
    paymentInfo: PropTypes.shape({
        paymentAmount: PropTypes.number.isRequired,
        paymentDate: PropTypes.string.isRequired,
        shareAmount: PropTypes.number.isRequired,
    }).isRequired,
    formStartEventEmitted: PropTypes.bool.isRequired,
    setFormStartEventEmitted: PropTypes.func.isRequired,

    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired
    }).isRequired,
    selectedBillAccountDetails: PropTypes.shape({
        billingStatusCode: PropTypes.string.isRequired
    }).isRequired,
    sendReconnectRemark: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired
}

export default withRouter(ConfirmPaymentDetailsCardInternal)