import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { DocumentTitle } from '../../../srp_modules/document-title'
import Card from '@mui/material/Card'

import { withTranslation } from 'react-i18next'

import CustomDueDateInputsResidential from './custom-due-date-inputs-residential'
import CustomDueDateInputsCommercial from './custom-due-date-inputs-business'
import CustomDueDateConfirmation from './custom-due-date-confirmation'
import CustomDueDateStepper from './custom-due-date-stepper'
import CustomDueDateIneligible from './custom-due-date-ineligible'
import CustomDueDateCommercialToggle from './custom-due-date-commercial-toggle'
import CustomDueDateSuccess from './custom-due-date-success'
import LoginPrefillFormBanner from '../../common/login-prefill-form-banner'

import {
    setCustomDueDate
} from '../../../actions/auth/payment/payment-actions'
import CardLoading from '../../../components/dashboard_page/card-loading'
import * as loadingStatus from '../../../constants/loading-status-constants'
import format from '../../../srp_modules/format'

let initialState = {
    activeStep: 0,
    submittedBillAccount: 0,
    submittedName: '',
    submittedAddress: '',
    submittedPhone: '',
    submittedEMail: '',
    customDueDateData: {
        customerName: '',
        serviceAddress: {},
        customerInfoModel: {},
        businessInfoModel: {},
        newDueDate: 0,
        billAccount: 0,
        language: 'en-US',
        isCommercial: false
    }
}

let isLoaded = (props) => {
    if (!props.isLoggedIn)
        return true

    if (props.isCommercial && props.businessInfoStatus !== loadingStatus.LOADING_STATUS_SUCCESS) {
        return false
    }

    return props.isCustomDueDateEligibleStatus === loadingStatus.LOADING_STATUS_SUCCESS &&
        props.enrolledProgramsDetailsStatus === loadingStatus.LOADING_STATUS_SUCCESS &&
        props.customerNamesStatus === loadingStatus.LOADING_STATUS_SUCCESS &&
        props.serviceAddressStatus === loadingStatus.LOADING_STATUS_SUCCESS &&
        props.primaryEmailStatus === loadingStatus.LOADING_STATUS_SUCCESS &&
        props.phoneNumberStatus === loadingStatus.LOADING_STATUS_SUCCESS
}

class CustomDueDateForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = initialState

        this.updateStateData = this.updateStateData.bind(this)

        this.getDisplayName = this.getDisplayName.bind(this)
        this.getCustomerInfoModel = this.getCustomerInfoModel.bind(this)
        this.getBusinessInfoModel = this.getBusinessInfoModel.bind(this)
        this.getEmailAddress = this.getEmailAddress.bind(this)
        this.getPhoneNumber = this.getPhoneNumber.bind(this)
        this.getServiceAddress = this.getServiceAddress.bind(this)
        this.getFullStreetAddress = this.getFullStreetAddress.bind(this)
        this.getAccountNumber = this.getAccountNumber.bind(this)
        this.nextDueDateStep = this.nextDueDateStep.bind(this)
        this.previousDueDateStep = this.previousDueDateStep.bind(this)

        this.updateDocumentDataIfNeeded = this.updateDocumentDataIfNeeded.bind(this)
    }

    componentDidMount() {
        this.updateDocumentDataIfNeeded(this.props, this.state)
    }

    componentDidUpdate() {
        this.updateDocumentDataIfNeeded(this.props, this.state)
    }

    updateDocumentDataIfNeeded(props, readOnlyState) {

        if (props.isLoggedIn && isLoaded(props) === true && !readOnlyState.customDueDateData.initalStateSet) {
            let initialDueDateData = {
                customerName: this.getDisplayName(),
                serviceAddress: this.getServiceAddress(),
                customerInfoModel: this.getCustomerInfoModel(),
                businessInfoModel: this.getBusinessInfoModel(),
                newDueDate: 0,
                billAccount: 0,
                language: 'en-US',
                isCommercial: props.isCommercial,
                initalStateSet: true
            }

            let submittedBillAccount = this.getAccountNumber()
            let submittedName = initialDueDateData.customerName
            let submittedAddress = this.getFullStreetAddress()
            let submittedPhone = this.getPhoneNumber()
            let submittedEMail = this.getEmailAddress()

            initialDueDateData.billAccount = submittedBillAccount
            initialDueDateData.initalStateSet = true
            if (this.props.isCommercial){
                initialDueDateData.businessInfoModel.touched = true
                initialDueDateData.businessInfoModel.emailAddress = submittedEMail
                initialDueDateData.businessInfoModel.phoneNumber = submittedPhone
            } else {
                initialDueDateData.customerInfoModel.touched = true
                initialDueDateData.customerInfoModel.emailAddress = submittedEMail
                initialDueDateData.customerInfoModel.phoneNumber = submittedPhone
            }

            this.setState({ ...this.state, customDueDateData: initialDueDateData,
                 activeStep: 1, submittedBillAccount, submittedName,
                 submittedAddress, submittedPhone, submittedEMail })
       }
    }

    updateStateData(values, callback) {
        this.setState(values, callback)
    }

    getServiceAddress() {

        let serviceAddress = {}

        if (this.props.isLoggedIn && this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            serviceAddress = { ...this.props.serviceAddress, city: this.props.serviceAddress.cityName, addressType: 'Service', state: 'AZ' }
        } else if (this.state.customDueDateData.serviceAddress) {
            serviceAddress.streetAddress = this.state.customDueDateData.serviceAddress.streetAddress
            serviceAddress.streetAddressLineTwo = this.state.customDueDateData.serviceAddress.streetAddressLineTwo
            serviceAddress.city = this.state.customDueDateData.serviceAddress.city
            serviceAddress.zipCode = this.state.customDueDateData.serviceAddress.zipCode
            serviceAddress.addressType = 'Service'
            serviceAddress.state = 'AZ'
        }

        serviceAddress.streetAddress = serviceAddress.streetAddress ?? ""
        serviceAddress.streetAddressLineTwo = serviceAddress.streetAddressLineTwo ?? ""
        serviceAddress.city = serviceAddress.city ?? ""
        serviceAddress.state = serviceAddress.state ?? ""
        serviceAddress.zipCode = serviceAddress.zipCode ?? ""
        serviceAddress.addressType = serviceAddress.addressType ?? ""

        return serviceAddress
    }

    getFullStreetAddress() {

        if (this.props.isLoggedIn) {
            return this.props.serviceAddress.fullStreetAddress
        }
        else {
            let streetAddress = this.state.customDueDateData.serviceAddress.streetAddress

            if (this.state.customDueDateData.serviceAddress.streetAddressLineTwo)
                streetAddress += " " + this.state.customDueDateData.serviceAddress.streetAddressLineTwo

            streetAddress += ", " + this.state.customDueDateData.serviceAddress.city + ", AZ " +
                this.state.customDueDateData.serviceAddress.zipCode

            return streetAddress
        }
    }

    getPhoneNumber() {

        if (this.props.isLoggedIn) {
            if (this.props.isCommercial) {
                if (this.props.businessInfoStatus === loadingStatus.LOADING_STATUS_SUCCESS)
                    return this.props.businessInfoModel.phoneNumber
            }
            else {
                if (this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_SUCCESS)
                    return this.props.phoneNumber
            }
        }
        else {
            if (this.state.customDueDateData.customerInfoModel.phoneNumber)
                return this.state.customDueDateData.customerInfoModel.phoneNumber

            if (this.state.customDueDateData.businessInfoModel.phoneNumber)
                return this.state.customDueDateData.businessInfoModel.phoneNumber
        }

        return ''
    }

    getEmailAddress() {

        if (this.props.isLoggedIn) {
            if (this.props.businessInfoStatus === loadingStatus.LOADING_STATUS_SUCCESS)
                return this.props.businessInfoModel.emailAddress

            if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_SUCCESS)
                return this.props.customerEmailAddress
        }
        else {
            if (this.state.customDueDateData.businessInfoModel.emailAddress)
                return this.state.customDueDateData.businessInfoModel.emailAddress

            if (this.state.customDueDateData.customerInfoModel.emailAddress)
                return this.state.customDueDateData.customerInfoModel.emailAddress
        }

        return ''
    }

    getDisplayName() {

        if (this.props.isLoggedIn) {
            if (this.props.isCommercial) {
                if (this.props.businessInfoStatus === loadingStatus.LOADING_STATUS_SUCCESS)
                    return this.props.businessInfoModel.businessName
            }
            else {
                if (this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_SUCCESS)
                    return this.props.customerNames.displayName
            }
        }
        else {

            if (this.state.customDueDateData.businessInfoModel.businessName)
                return this.state.customDueDateData.businessInfoModel.businessName

            if (this.state.customDueDateData.customerInfoModel) {
                let displayName = this.state.customDueDateData.customerInfoModel.firstName + " "
                if (this.state.customDueDateData.customerInfoModel.middleName) {
                    displayName += this.state.customDueDateData.customerInfoModel.middleName + " "
                }
                displayName += this.state.customDueDateData.customerInfoModel.lastName
                return displayName
            }
        }

        return ''
    }

    getAccountNumber() {

        if (this.state.customDueDateData.billAccount > 0) {
            return format.srpAccountNumber(this.state.customDueDateData.billAccount)
        }

        if (this.props.selectedBillAccount > 0) {
            return format.srpAccountNumber(this.props.selectedBillAccount)
        }

        return ''
    }

    getCustomerInfoModel() {

        let customerInfoModel = {
            firstName: '',
            middleName: '',
            lastName: '',
            ssn: '',
            dob: '',
            emailAddress: '',
            verifyEmailAddress: '',
            phoneNumber: '',
            verifyPhoneNumber: ''
        }

        if (this.state.customDueDateData.customerInfoModel.touched) {
            return this.state.customDueDateData.customerInfoModel
        }

        if (this.props.isLoggedIn &&
            this.props.customerNames !== undefined &&
            this.props.customerNamesStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            customerInfoModel.firstName = this.props.customerNames.firstName
            customerInfoModel.middleName = this.props.customerNames.middleName
            customerInfoModel.lastName = this.props.customerNames.lastName
            customerInfoModel.emailAddress = this.props.customerEmailAddress
            customerInfoModel.phoneNumber = this.props.phoneNumber
            return customerInfoModel
        }

        return customerInfoModel
    }

    getBusinessInfoModel() {

        let businessInfoModel = {
            businessName: '',
            dbaName: '',
            contactName: '',
            contactPosition: '',
            emailAddress: '',
            phoneNumber: '',
            lastFourSSN: ''
        }

        if (this.state.customDueDateData.businessInfoModel.touched) {
            return this.state.customDueDateData.businessInfoModel
        }

        if (this.props.isLoggedIn && this.props.isCommercial && this.props.businessInfoModel !== undefined) {
            businessInfoModel.businessName = this.props.customerNames.displayName
            businessInfoModel.dbaName = this.props.billingAddress.dbaLineOne
            businessInfoModel.contactName = this.props.businessInfoModel.contactName
            businessInfoModel.contactPosition = this.props.businessInfoModel.contactPosition
            businessInfoModel.emailAddress = this.props.customerEmailAddress
            businessInfoModel.phoneNumber = this.props.phoneNumber
            return businessInfoModel
        }

        return businessInfoModel
    }

    nextDueDateStep(submittedEmail) {
        let nextStep = this.state.activeStep
        nextStep++
        let submittedBillAccount = 0
        let submittedName = ''
        let submittedAddress = ''
        let submittedPhone = ''
        let submittedEMail = ''
        if (nextStep > 0) {
            submittedBillAccount = this.state.customDueDateData.billAccount
            submittedName = this.getDisplayName()
            submittedAddress = this.getFullStreetAddress()
            submittedPhone = this.getPhoneNumber()
            submittedEMail = nextStep===2 ? submittedEmail : this.getEmailAddress()
        }
        this.setState({ ...this.state, activeStep: nextStep, submittedBillAccount, submittedName, submittedAddress, submittedPhone, submittedEMail })
    }

    previousDueDateStep() {
        let previousStep = this.state.activeStep
        previousStep--
        this.setState({ ...this.state, activeStep: previousStep })
    }

    render() {
        const t = this.props.t

        if (!isLoaded(this.props)) {
            return (<CardLoading title={t("Custom Due Date Form")} />)
        }

        if (this.props.isLoggedIn &&
            this.props.customDueDateInfo.isEligible === false &&
            this.props.isCustomDueDateEligibleStatus !== loadingStatus.LOADING_STATUS_FAILURE &&
            this.props.setCustomDueDateStatus === loadingStatus.LOADING_STATUS_INIT) {

            return (<CustomDueDateIneligible
                getDisplayName={this.getDisplayName}
                getFullStreetAddress={this.getFullStreetAddress}
                navigateBack={this.props.navigateBack} t={t} />)
        }

        let commercialToggle = null

        if (!this.props.isLoggedIn && this.state.activeStep === 0) {
            commercialToggle = (<CustomDueDateCommercialToggle
                showCommercial={this.props.getIsCommercial()}
                setShowCommercial={this.props.setShowCommercial} t={t} />)
        }

        let displayedStep = null
        let stepper = <CustomDueDateStepper currentStep={this.state.activeStep} t={t} />
        let headerTitle = ''

        let loginPrefill = null

        switch (this.state.activeStep) {
            case 0:
                {
                    {
                        !this.props.isLoggedIn ?
                            loginPrefill = <LoginPrefillFormBanner redirectToForm={'/customduedate'} /> :
                            null
                    }

                    headerTitle = t('Tell us about yourself')
                    if (this.props.getIsCommercial()) {
                        displayedStep = (<CustomDueDateInputsCommercial
                            isLoggedIn={this.props.isLoggedIn}
                            isCommercial={this.props.isCommercial}
                            getDisplayName={this.getDisplayName}
                            getAccountNumber={this.getAccountNumber}
                            getCustomerInfoModel={this.getCustomerInfoModel}
                            getBusinessInfoModel={this.getBusinessInfoModel}
                            getEmailAddress={this.getEmailAddress}
                            getPhoneNumber={this.getPhoneNumber}
                            getServiceAddress={this.getServiceAddress}
                            getIsCommercial={this.props.getIsCommercial}
                            nextDueDateStep={this.nextDueDateStep}
                            stateData={this.state}
                            updateStateData={this.updateStateData} t={t} />)
                    } else {
                        displayedStep = (<CustomDueDateInputsResidential
                            isLoggedIn={this.props.isLoggedIn}
                            isCommercial={this.props.isCommercial}
                            getDisplayName={this.getDisplayName}
                            getAccountNumber={this.getAccountNumber}
                            getCustomerInfoModel={this.getCustomerInfoModel}
                            getBusinessInfoModel={this.getBusinessInfoModel}
                            getEmailAddress={this.getEmailAddress}
                            getPhoneNumber={this.getPhoneNumber}
                            getServiceAddress={this.getServiceAddress}
                            getIsCommercial={this.props.getIsCommercial}
                            nextDueDateStep={this.nextDueDateStep}
                            stateData={this.state}
                            updateStateData={this.updateStateData} t={t} />)
                    }
                }
                break
            case 1:
                {
                    if (this.props.isLoggedIn) { stepper = null }
                    headerTitle = t('Choose your date and confirm account details')
                    displayedStep = (<CustomDueDateConfirmation
                        isLoggedIn={this.props.isLoggedIn}
                        isCustomDueDateEnrolled={this.props.isCustomDueDateEnrolled}
                        customDueDayEnrolled={this.props.customDueDayEnrolled}
                        nextDueDateStep={this.nextDueDateStep}
                        previousDueDateStep={this.previousDueDateStep}
                        submitCustomDueDate={this.props.submitCustomDueDate}
                        stateData={this.state}
                        updateStateData={this.updateStateData}
                        isCommercial={this.props.getIsCommercial()} t={t} />)
                }
                break
            case 2:
                {
                    stepper = null
                    headerTitle = t('Custom due date requested')
                    displayedStep = (<CustomDueDateSuccess
                        customDueDateInfo={this.props.customDueDateInfo}
                        customerEmailAddress={this.state.submittedEMail}
                        isLoggedIn={this.props.isLoggedIn} t={t} />)
                }
                break
        }
        return (
            <DocumentTitle title={t("Custom Due Date Form | SRP Power")}>
                <div>
                    <div className="srp-card-header">
                        <div>{headerTitle}</div>
                    </div>
                    <Card>
                        <div className="container">
                            <div className="pt-2">
                                {loginPrefill}
                            </div>
                            {stepper}
                            <div className="srp-card-details">
                                {commercialToggle}
                                {displayedStep}
                            </div>
                        </div>
                    </Card>
                </div>
            </DocumentTitle>
        )
    }
}

CustomDueDateForm.propTypes = {
    isLoggedIn: PropTypes.bool,
    selectedBillAccount: PropTypes.number.isRequired,
    submitCustomDueDate: PropTypes.func.isRequired,
    isCommercial: PropTypes.bool.isRequired,
    getIsCommercial: PropTypes.func,
    setShowCommercial: PropTypes.func,
    navigateBack: PropTypes.func,

    customDueDateData: PropTypes.object,
    newDueDate: PropTypes.object,
    customerNames: PropTypes.object,
    customerNamesStatus: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    phoneNumberStatus: PropTypes.string,
    displayName: PropTypes.string,
    billingAddress: PropTypes.object,
    dbaLineOne: PropTypes.string,

    goBack: PropTypes.func,
    serviceAddress: PropTypes.object,
    serviceAddressStatus: PropTypes.string,
    customerEmailAddress: PropTypes.string,
    primaryEmailStatus: PropTypes.string,

    customDueDate: PropTypes.number,
    isCustomDueDateEligibleStatus: PropTypes.string.isRequired,
    enrolledProgramsDetailsStatus: PropTypes.string.isRequired,
    isCustomDueDateEnrolled: PropTypes.bool.isRequired,
    customDueDayEnrolled: PropTypes.number.isRequired,
    setCustomDueDateStatus: PropTypes.string.isRequired,
    customDueDateInfo: PropTypes.object,
    businessInfoModel: PropTypes.object,
    businessInfoStatus: PropTypes.string,

    t: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        setCustomDueDateStatus: state.accountInfo.payment.setCustomDueDateStatus,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount
    }
}

const mapDispatchToProps = dispatch => {
    return {
        submitCustomDueDate: () => async (values, goodies, isCommercial) => {

            let customDueDateSubmit = values.customDueDateData

            customDueDateSubmit.newDueDate = values.customDate
            customDueDateSubmit.customerInfoModel.emailAddress = values.emailAddress
            if (isCommercial) {
                customDueDateSubmit.businessInfoModel.emailAddress = values.emailAddress
            }

            let result = await dispatch(setCustomDueDate(customDueDateSubmit))

            if (result.error === true || result.payload.success === false) {
                goodies.setStatus({ submitError: true })
                goodies.setSubmitting(false)
            }
            else {
                goodies.setStatus({ submitError: false })
                goodies.setSubmitting(false)
            }

            return result
        }
    }
}

export default withTranslation("customDueDate")(connect(mapStateToProps, mapDispatchToProps)(CustomDueDateForm))