import * as React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import * as loadingStatus from '../../constants/loading-status-constants'
import drawHorizontalChart from  '../../srp_modules/charting/hourly-net-horizontal'
import drawVerticalChart from  '../../srp_modules/charting/hourly-net-vertical'
import {convertHourlyDataForCharting} from '../../srp_modules/charting/hourly-data-dto'
let initializeChart = (hourlyUsageList, currentChartType, t) => {
    if(hourlyUsageList.length > 0){

        window.innerWidth < 1024
            ? drawHorizontalChart(hourlyUsageList, t)
            : drawVerticalChart(hourlyUsageList, currentChartType, t)
    }
}

class HourlyNetChartContainer extends React.Component{
    constructor(props) {
        super(props)
      this.state = {
          resizeListener: this.updateChart.bind(this)
      }
    }
    componentDidMount() {
        window.addEventListener("resize", this.state.resizeListener)
        if (this.props.hourlyUsageStatus === loadingStatus.LOADING_STATUS_SUCCESS)
            this.updateChart()
    }

    shouldComponentUpdate(nextProps) {
        return (this.props.hourlyUsageStatus !== nextProps.hourlyUsageStatus
                || this.props.rateMetaData.rateMetaDataStatus !== nextProps.rateMetaData.rateMetaDataStatus
                || this.props.hourlyStartDate !== nextProps.hourlyStartDate
                || this.props.currentHourlyChart !== nextProps.currentHourlyChart
                || this.props.t !== nextProps.t
            )
        }

    componentDidUpdate() {
        if (this.props.hourlyUsageStatus === loadingStatus.LOADING_STATUS_SUCCESS
            && this.props.rateMetaData.rateMetaDataStatus === loadingStatus.LOADING_STATUS_SUCCESS
        )
            this.updateChart()
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.state.resizeListener)
    }

    updateChart() {
        let usageData = convertHourlyDataForCharting(this.props.hourlyUsageList,this.props.currentHourlyChart);

        initializeChart(
            usageData,
            this.props.currentChartType,
            this.props.t
        )
    }

    render() {

        return(
            <div>
                <div id="usagePageChartContainer">

                    <div id="usageLegendContainer" className="chart-legend-container">
                        <div id="superOffLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Super off-peak")}</span>
                            <div className="usage-chart-legend-circle viz-SuperOffPeak" />
                        </div>
                        <div id="offPeakLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Off-peak")}</span>
                            <div className="usage-chart-legend-circle viz-OffPeak" />
                        </div>
                        <div id="shoulderLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Shoulder")}</span>
                            <div className="usage-chart-legend-circle viz-Shoulder" />
                        </div>
                        <div id="onPeakLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("On-peak")}</span>
                            <div className="usage-chart-legend-circle viz-OnPeak" />
                        </div>
                        <div id="totalLegendItem" className="chart-legend-item-hidden">
                            <span>{this.props.t("Total")}</span>
                            <div className="usage-chart-legend-circle viz-Total" />
                        </div>
                        <div id="totalLegendItemCost" className="chart-legend-item-hidden">
                            <span>{this.props.t("Cost")}</span>
                            <div className="usage-chart-legend-circle viz-Total" />
                        </div>
                      </div>
                    <div className=".viz-tooltip-arrow-main" />
                </div>
            </div>
        )
    }

}

HourlyNetChartContainer.propTypes = {
    highDemandInfoList: PropTypes.array,
    selectedBillAccount: PropTypes.number,
    selectedUsagePageChartTab: PropTypes.number,
    rateMetaData: PropTypes.object,
    getRateMetaDataForAccount: PropTypes.func,
    hourlyUsageStatus: PropTypes.string,
    hourlyStartDate: PropTypes.object,
    hourlyEndDate: PropTypes.object,
    hourlyUsageList: PropTypes.array,
    currentChartType: PropTypes.string,
    currentHourlyChart: PropTypes.string,
    t: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        ...state.hourlyUsage,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        isCost: PropTypes.bool,
        selectedUsagePageChartTab: state.accountInfo.usagePageUI.selectedUsagePageChartTab,
        rateMetaData: state.rateMetaData,
        ...state.accountInfo.usagePageUI,

    }
}



export default connect(mapStateToProps)(HourlyNetChartContainer)

