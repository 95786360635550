import React from 'react'
import PropTypes from 'prop-types'
import Radio from '@mui/material/Radio'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { NumericFormat } from 'react-number-format'
import TextField from '@mui/material/TextField'

import { shareConstants } from '../../../constants/share-constants'
import alertsModule from '../../../srp_modules/alerts-module'

const SelectDonation = (props) => {
    let otherAmountMonthlyInput = React.useRef()
    let otherAmountOneTimeInput = React.useRef()

    let otherMonthlyError = (props.dirty || props.submitCount > 0)
                            && props.touched.otherAmountMonthly
                            && props.errors.otherAmountMonthly !== undefined
    let otherOneTimeError = (props.dirty || props.submitCount > 0)
                            && props.touched.otherAmountOneTime
                            && props.errors.otherAmountOneTime !== undefined

    let isDonationAmountAllowed = values => {
        return values.value >= 0
                && values.value < 100
                && Math.round(values.value, 0) == values.value
    }

    let setOtherMonthlyAmountOnChange = values => {
        let value = values.value
        props.setFieldValue('otherAmountMonthly', value)
        props.setFieldTouched('otherAmountOneTime', false)
        props.setOtherDonationAmountOnChange(value)
    }

    let formatOtherMonthlyAmountOnBlur = () => {
        let value = props.values.otherAmountMonthly
        let formattedAmount = value === "" ? "0.00" : alertsModule.formatDollarAmount(Number(value))

        props.setFieldTouched('otherAmountMonthly', true)
        props.setFieldValue('otherAmountMonthly', formattedAmount)
        props.setOtherDonationAmountOnChange(formattedAmount)
    }


    let setOtherOneTimeAmountOnChange = values => {
        let value = values.value
        props.setFieldValue('otherAmountOneTime', value)
        props.setFieldTouched('otherAmountMonthly', false)
        props.setOtherDonationAmountOnChange(value)
    }

    let formatOtherOneTimeAmountOnBlur = () => {
        let value = props.values.otherAmountOneTime
        let formattedAmount = value === "" ? "0.00" : alertsModule.formatDollarAmount(Number(value))

        props.setFieldTouched('otherAmountOneTime', true)
        props.setFieldValue('otherAmountOneTime', formattedAmount)
        props.setOtherDonationAmountOnChange(formattedAmount)
    }

    const t = props.t

    return (
        <form className="srp-card-details pb-0 pl-0 pr-0" onSubmit={props.handleSubmit}>
            <div>
                <AppBar
                    position="static"
                    style={{ backgroundColor: 'white', borderBottom: "1px solid lightgray" }}
                    elevation={0}>
                    <Tabs
                        value={props.donationType}
                        onChange={(event, value) => props.setDonationTypeOnClick(value)}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        <Tab
                            className="mui_tab"
                            value={shareConstants.DONATION_TYPE.MONTHLY}
                            label={t("Monthly pledge")}
                            style={{ outline: 'none', boxShadow: 'none', textTransform: 'capitalize' }} />
                        <Tab
                            className="mui_tab"
                            value={shareConstants.DONATION_TYPE.ONE_TIME}
                            label={t("One-time donation")}
                            style={{ outline: 'none', boxShadow: 'none', textTransform: 'capitalize' }} />
                        <Tab
                            className="mui_tab"
                            value={shareConstants.DONATION_TYPE.DISCONTINUE}
                            label={t("Discontinue")}
                            style={{ outline: 'none', boxShadow: 'none', textTransform: 'capitalize' }} />
                    </Tabs>
                </AppBar>
                {props.donationType === shareConstants.DONATION_TYPE.MONTHLY &&
                    (<div className="srp-card-details pb-0">
                        <span>{t("Please add the following amount to my bill each month")}</span>
                        <FormGroup>
                            <FormControlLabel
                                className="mb-0"
                                style={{ height: '40px' }}
                                control={<Radio
                                    checked={props.donationAmount === shareConstants.DONATION_AMOUNT.ONE_DOLLAR}
                                    onChange={() => props.setDonationAmountOnClick(shareConstants.DONATION_AMOUNT.ONE_DOLLAR)}
                                    value={shareConstants.DONATION_AMOUNT.ONE_DOLLAR}
                                    color="primary"
                                />}
                                label={"$1.00"}
                            />
                            <FormControlLabel
                                className="mb-0"
                                style={{ height: '40px' }}
                                control={<Radio
                                    checked={props.donationAmount === shareConstants.DONATION_AMOUNT.TWO_DOLLAR}
                                    onChange={() => props.setDonationAmountOnClick(shareConstants.DONATION_AMOUNT.TWO_DOLLAR)}
                                    value={shareConstants.DONATION_AMOUNT.TWO_DOLLAR}
                                    color="primary"
                                />}
                                label={"$2.00"}
                            />
                            <FormControlLabel
                                className="mb-0"
                                style={{ height: '40px' }}
                                control={<Radio
                                    checked={props.donationAmount === shareConstants.DONATION_AMOUNT.FIVE_DOLLAR}
                                    onChange={() => props.setDonationAmountOnClick(shareConstants.DONATION_AMOUNT.FIVE_DOLLAR)}
                                    value={shareConstants.DONATION_AMOUNT.FIVE_DOLLAR}
                                    color="primary"
                                />}
                                label={"$5.00"}
                            />
                            <FormControlLabel
                                className="mb-0"
                                style={{ height: '40px' }}
                                control={<Radio
                                    checked={props.donationAmount === shareConstants.DONATION_AMOUNT.OTHER}
                                    onChange={() => props.setDonationAmountOnClick(shareConstants.DONATION_AMOUNT.OTHER)}
                                    value={shareConstants.DONATION_AMOUNT.OTHER}
                                    color="primary"
                                />}
                                label={t("Other")}
                            />
                        </FormGroup>
                        {props.donationAmount === shareConstants.DONATION_AMOUNT.OTHER
                            ? <div className="mt-2">
                                <div>
                                    <NumericFormat
                                        name="otherAmountMonthly"
                                        getInputRef={otherAmountMonthlyInput}
                                        value={props.values.otherAmountMonthly}
                                        valueIsNumericString={true}
                                        displayType="input"
                                        type="text"
                                        isAllowed={isDonationAmountAllowed}
                                        onValueChange={setOtherMonthlyAmountOnChange}
                                        onBlur={formatOtherMonthlyAmountOnBlur}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                        decimalScale={2}
                                        allowLeadingZeros={false}
                                        allowNegative={false}
                                        customInput={TextField}
                                        helperText={otherMonthlyError ? t(props.errors.otherAmountMonthly) : ''}
                                        error={otherMonthlyError} 
                                        label={t("Other amount")}
                                    />
                                </div>
                            </div>
                            : null
                        }
                    </div>)
                }
                {props.donationType === shareConstants.DONATION_TYPE.ONE_TIME &&
                    (<div className="srp-card-details pb-0">
                        <span>{t("Please add the following amount to my bill one time")}</span>
                        <FormGroup>
                            <FormControlLabel
                                className="mb-0"
                                style={{ height: '40px' }}
                                control={<Radio
                                    checked={props.donationAmount === shareConstants.DONATION_AMOUNT.FIVE_DOLLAR}
                                    onChange={() => props.setDonationAmountOnClick(shareConstants.DONATION_AMOUNT.FIVE_DOLLAR)}
                                    value={shareConstants.DONATION_AMOUNT.FIVE_DOLLAR}
                                    color="primary"
                                />}
                                label={"$5.00"}
                            />
                            <FormControlLabel
                                className="mb-0"
                                style={{ height: '40px' }}
                                control={<Radio
                                    checked={props.donationAmount === shareConstants.DONATION_AMOUNT.TEN_DOLLAR}
                                    onChange={() => props.setDonationAmountOnClick(shareConstants.DONATION_AMOUNT.TEN_DOLLAR)}
                                    value={shareConstants.DONATION_AMOUNT.TEN_DOLLAR}
                                    color="primary"
                                />}
                                label={"$10.00"}
                            />
                            <FormControlLabel
                                className="mb-0"
                                style={{ height: '40px' }}
                                control={<Radio
                                    checked={props.donationAmount === shareConstants.DONATION_AMOUNT.TWENTY_DOLLAR}
                                    onChange={() => props.setDonationAmountOnClick(shareConstants.DONATION_AMOUNT.TWENTY_DOLLAR)}
                                    value={shareConstants.DONATION_AMOUNT.TWENTY_DOLLAR}
                                    color="primary"
                                />}
                                label={"$20.00"}
                            />
                            <FormControlLabel
                                className="mb-0"
                                style={{ height: '40px' }}
                                control={<Radio
                                    checked={props.donationAmount === shareConstants.DONATION_AMOUNT.OTHER}
                                    onChange={() => props.setDonationAmountOnClick(shareConstants.DONATION_AMOUNT.OTHER)}
                                    value={shareConstants.DONATION_AMOUNT.OTHER}
                                    color="primary"
                                />}
                                label={t("Other")}
                            />
                        </FormGroup>
                        {props.donationAmount === shareConstants.DONATION_AMOUNT.OTHER
                            ? <div className="mt-2">
                                <div>
                                    <NumericFormat
                                        name="otherAmountOneTime"
                                        getInputRef={otherAmountOneTimeInput}
                                        value={props.values.otherAmountOneTime}
                                        valueIsNumericString={true}
                                        displayType="input"
                                        type="text"
                                        isAllowed={isDonationAmountAllowed}
                                        onValueChange={setOtherOneTimeAmountOnChange}
                                        onBlur={formatOtherOneTimeAmountOnBlur}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                        decimalScale={2}
                                        allowLeadingZeros={false}
                                        allowNegative={false}
                                        customInput={TextField}
                                        helperText={otherOneTimeError ? t(props.errors.otherAmountOneTime) : ''}
                                        error={otherOneTimeError} 
                                        label={t("Other amount")}
                                    />
                                </div>
                            </div>
                            : null
                        }
                    </div>)
                }
                {props.donationType === shareConstants.DONATION_TYPE.DISCONTINUE &&
                    (<div className="srp-card-details pb-0">
                        <FormControlLabel
                            className="mb-0"
                            style={{ height: '40px' }}
                            control={<Radio
                                checked={props.donationAmount === shareConstants.DONATION_AMOUNT.OTHER}
                                onChange={() => props.setDonationAmountOnClick(shareConstants.DONATION_AMOUNT.OTHER)}
                                value={shareConstants.DONATION_AMOUNT.OTHER}
                                color="primary"
                            />}
                            label={t("I would no longer like to make my monthly pledge")}
                        />
                    </div>)
                }
            </div>
            <div className="d-flex flex-wrap justify-content-end srp-card-details">
                <button
                    className="btn srp-btn btn-lightblue ml-2"
                    onClick={() => props.setActiveStepOnClick(props.activeStep - 1)}>
                    {t("Previous")}
                </button>
                <button className="btn srp-btn btn-blue ml-2" type="submit">{t("Next")}</button>
            </div>
        </form>
    )
}

SelectDonation.propTypes = {
    activeStep: PropTypes.number,
    setActiveStepOnClick: PropTypes.func,
    donationType: PropTypes.number,
    setDonationTypeOnClick: PropTypes.func,
    donationAmount: PropTypes.number,
    setDonationAmountOnClick: PropTypes.func,
    otherDonationAmount: PropTypes.string,
    setOtherDonationAmountOnChange: PropTypes.func,
    dirty: PropTypes.bool,
    submitCount: PropTypes.number,
    touched: PropTypes.object,
    errors: PropTypes.object,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    handleSubmit: PropTypes.func,
    values: PropTypes.object,

    t: PropTypes.func.isRequired
}

export default SelectDonation