import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { LOADING_STATUS_SUCCESS } from '../../../constants/loading-status-constants'

import { clearMultiPaymentInfo, getSelectedPaymentAccountRateCode, sendReconnectRemark } from '../../../actions/auth/payment/multi-payment-actions'
import { clearFormStartEventEmitted } from '../../../actions/auth/payment/multi-payment-tracking-actions'
import { withRouter } from '../../../srp_modules/with-router'
import MultiPaymentPage from './multi-payment-page'

class MultiPaymentPageContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (this.props.multiPaymentsSubmitted)
            this.props.clearState()
    }

    render() {
        let billAccountList = this.props.billAccountList
        let  isCash = this.props.router.location.pathname === "/myaccount/payment/multi/cash"
        return (
            !this.props.multiPaymentsSubmitted
            && <MultiPaymentPage
                isCash = {isCash}
                billAccountList = {billAccountList}
                isMpower = {this.props.selectedBillAccountDetails.isPrePay}
                sendReconnectRemark={this.props.sendReconnectRemark}
                isSolar={this.props.isSolar}
                getSelectedPaymentAccountRateCode={this.props.getSelectedPaymentAccountRateCode}/>
        )
    }
}

const mapStateToProps = state => ({
    multiPaymentsSubmitted: state.multiPayment.submitMultiPaymentResultStatus === LOADING_STATUS_SUCCESS,
    ...state.accountInfo.billAccount,
    isSolar: state.multiPayment.selectedPaymentAccountIsSolar
})

const mapDispatchToProps = (dispatch) => {
    return {
        clearState: () => {
            dispatch(clearMultiPaymentInfo())
            dispatch(clearFormStartEventEmitted())
        },
        sendReconnectRemark : async (billAccount, authorizeReconnect) => {
            await dispatch(sendReconnectRemark(billAccount, authorizeReconnect))
        },
        getSelectedPaymentAccountRateCode: (billAccount) => {
            dispatch(getSelectedPaymentAccountRateCode(billAccount))
        }
    }
}

MultiPaymentPageContainer.propTypes = {
    multiPaymentsSubmitted: PropTypes.bool.isRequired,
    clearState: PropTypes.func.isRequired,
    billAccountList: PropTypes.array.isRequired,
    router: PropTypes.shape({
        location: PropTypes.object
    }),
    selectedBillAccountDetails: PropTypes.object,
    sendReconnectRemark: PropTypes.func.isRequired,
    isSolar: PropTypes.bool,
    getSelectedPaymentAccountRateCode: PropTypes.func.isRequired
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MultiPaymentPageContainer))