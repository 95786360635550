import React from 'react'
import { Formik } from 'formik'
import PropTypes from 'prop-types'

import SRPAccountNumber from '../../common_formik/srp-account-number'
import PhoneNumberInput from '../../common_formik/phone-number-input'

import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'

import validate from '../../../srp_modules/validate'


class CustomDueDateInputsCommercial extends React.Component {

    constructor(props) {
        super(props)

        this.validateCustomDueDate = this.validateCustomDueDate.bind(this)
        this.onClickNext = this.onClickNext.bind(this)
    }


    onClickNext(values) {
        let newDueDateData = values.customDueDateData
        newDueDateData.businessInfoModel.emailAddress = values.emailAddress
        newDueDateData.businessInfoModel.phoneNumber = values.phoneNumber
        newDueDateData.billAccount = values.accountNumber
        newDueDateData.initalStateSet = true

        newDueDateData.businessInfoModel.touched = true

        this.props.updateStateData({ customDueDateData: newDueDateData }, () => this.props.nextDueDateStep())
    }


    validateCustomDueDate(values) {
        let errors = {}

        if (values.accountNumber === '' || !validate.accountNumber(values.accountNumber))
            errors.accountNumber = 'Account number is required.'

        if (!values.customDueDateData.businessInfoModel.businessName)
            errors.businessName = 'Business name is required.'

        if (!values.customDueDateData.serviceAddress.streetAddress)
            errors.streetAddress = 'Street address is required.'

        if (!values.customDueDateData.serviceAddress.city)
            errors.city = 'City is required.'

        if (!values.customDueDateData.serviceAddress.zipCode || values.customDueDateData.serviceAddress.zipCode.length < 5)
            errors.zipCode = '5 digit zip code is required.'

        if (!validate.emailAddress(values.emailAddress))
            errors.emailAddress = 'Please enter a valid email address.'

        if (!validate.phoneNumber(values.phoneNumber))
            errors.phoneNumber = '10 digit phone number is required.'

        return errors
    }

    render() {
        const t = this.props.t

        return (
            <Formik
                initialValues={{
                    customDueDateData: {
                        serviceAddress: this.props.getServiceAddress(),
                        customerInfoModel: this.props.getCustomerInfoModel(),
                        businessInfoModel: this.props.getBusinessInfoModel(),
                        newDueDate: 0,
                        billAccount: 0,
                        language: 'en-US',
                        isCommercial: this.props.getIsCommercial(),
                    },
                    customDate: this.props.customDueDateData ? (this.props.customDueDateData.newDueDate ? this.props.customDueDateData.newDueDate : 1) : 1,
                    emailAddress: this.props.getEmailAddress(),
                    phoneNumber: this.props.getPhoneNumber(),
                    accountNumber: this.props.getAccountNumber()
                }}
                validate={this.validateCustomDueDate}
                onSubmit={this.onClickNext}>
                {({ values, status, handleSubmit, isSubmitting, handleChange, handleBlur, errors, touched, setFieldValue }) => {

                    let isBusinessNameError = errors.businessName && touched.customDueDateData && touched.customDueDateData.businessInfoModel && touched.customDueDateData.businessInfoModel.businessName
                    let isDBANameError = false
                    let isContactNameError = false
                    let isContactPositionError = false
                    let isEmailAddressError = errors.emailAddress !== undefined && touched.emailAddress

                    let isStreetAddressError = errors.streetAddress !== undefined && touched.customDueDateData && touched.customDueDateData.serviceAddress && touched.customDueDateData.serviceAddress.streetAddress
                    let isStreetAddressLineTwoError = false
                    let isCityError = errors.city !== undefined && touched.customDueDateData && touched.customDueDateData.serviceAddress && touched.customDueDateData.serviceAddress.city
                    let isZipCodeError = errors.zipCode !== undefined && touched.customDueDateData && touched.customDueDateData.serviceAddress && touched.customDueDateData.serviceAddress.zipCode

                    let changeStatus = status || {}

                    let commonFormikProps = {
                        onChange: handleChange,
                        onBlur: handleBlur,
                        errors: errors,
                        touched: touched
                    }

                    let setNameOnChange = (text, fieldValue) => {
                        if (text.match("^[ A-Za-z'-]*$")) {
                            setFieldValue(fieldValue, text)
                        }
                    }

                    let setAlphaNumericOnChange = (text, fieldValue) => {
                        if (text.match("^[ A-Za-z0-9]*$")) {
                            setFieldValue(fieldValue, text)
                        }
                    }

                    return (
                        <div>
                            {changeStatus.submitError}
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <SRPAccountNumber
                                        {...commonFormikProps}
                                        id="accountNumber"
                                        label={t("SRP account number")}
                                        value={values.accountNumber}
                                        onChange={setFieldValue}
                                        labelClass="mt-4"
                                        labelStyle={{ color: 'black' }}
                                        t={t}
                                    />

                                    <TextField
                                        fullWidth={true}
                                        type={'text'}
                                        value={values.customDueDateData.businessInfoModel.businessName}
                                        error={isBusinessNameError}
                                        helperText={isBusinessNameError ? t(errors.businessName) : ''}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="customDueDateData.businessInfoModel.businessName"
                                        name="customDueDateData.businessInfoModel.businessName"
                                        label={t("Business name")}
                                    />

                                    <TextField
                                        fullWidth={true}
                                        type={'text'}
                                        value={values.customDueDateData.businessInfoModel.dbaName}
                                        error={isDBANameError}
                                        helperText={isDBANameError ? errors.customerName : ''}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={(e) => setNameOnChange(e.target.value, "customDueDateData.businessInfoModel.dbaName")}
                                        onBlur={handleBlur}
                                        id="customDueDateData.businessInfoModel.dbaName"
                                        name="customDueDateData.businessInfoModel.dbaName"
                                        label={t("DBA name (optional)")}
                                    />

                                    <TextField
                                        fullWidth={true}
                                        type={'text'}
                                        value={values.customDueDateData.businessInfoModel.contactName}
                                        error={isContactNameError}
                                        helperText={isContactNameError ? errors.contactName : ''}
                                        inputProps={{ maxLength: 50 }}
                                        onChange={(e) => setNameOnChange(e.target.value, "customDueDateData.businessInfoModel.contactName")}
                                        onBlur={handleBlur}
                                        id="customDueDateData.businessInfoModel.contactName"
                                        name="customDueDateData.businessInfoModel.contactName"
                                        label={t("Contact name (optional)")}
                                    />

                                    <TextField
                                        fullWidth={true}
                                        type={'text'}
                                        value={values.customDueDateData.businessInfoModel.contactPosition}
                                        error={isContactPositionError}
                                        helperText={isContactPositionError ? errors.contactPosition : ''}
                                        inputProps={{ maxLength: 50 }}
                                        onChange={(e) => setNameOnChange(e.target.value, "customDueDateData.businessInfoModel.contactPosition")}
                                        onBlur={handleBlur}
                                        id="customDueDateData.businessInfoModel.contactPosition"
                                        name="customDueDateData.businessInfoModel.contactPosition"
                                        label={t("Contact position (optional)")}
                                    />

                                    <TextField
                                        fullWidth={true}
                                        type={'text'}
                                        value={values.customDueDateData.serviceAddress.streetAddress}
                                        error={isStreetAddressError}
                                        helperText={isStreetAddressError ? t(errors.streetAddress) : ''}
                                        inputProps={{ maxLength: 50 }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="customDueDateData.serviceAddress.streetAddress"
                                        name="customDueDateData.serviceAddress.streetAddress" 
                                        label={t("Street address")}
                                    />

                                    <TextField
                                        fullWidth={true}
                                        type={'text'}
                                        value={values.customDueDateData.serviceAddress.streetAddressLineTwo}
                                        error={isStreetAddressLineTwoError}
                                        helperText={isStreetAddressLineTwoError ? errors.streetAddressLineTwo : ''}
                                        inputProps={{ maxLength: 50 }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="customDueDateData.serviceAddress.streetAddressLineTwo"
                                        name="customDueDateData.serviceAddress.streetAddressLineTwo" 
                                        label={t("Apt, suite, etc (optional)")}
                                    />

                                    <TextField
                                        fullWidth={true}
                                        type={'text'}
                                        value={values.customDueDateData.serviceAddress.city}
                                        error={isCityError}
                                        helperText={isCityError ? t(errors.city) : ''}
                                        inputProps={{ maxLength: 30 }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="customDueDateData.serviceAddress.city"
                                        name="customDueDateData.serviceAddress.city" 
                                        label={t("City")}
                                    />

                                    <TextField
                                        fullWidth={true}
                                        type={'text'}
                                        value={values.customDueDateData.serviceAddress.zipCode}
                                        error={isZipCodeError}
                                        helperText={isZipCodeError ? t(errors.zipCode) : ''}
                                        inputProps={{ maxLength: 9 }}
                                        onChange={(e) => setAlphaNumericOnChange(e.target.value, "customDueDateData.serviceAddress.zipCode")}
                                        onBlur={handleBlur}
                                        id="customDueDateData.serviceAddress.zipCode"
                                        name="customDueDateData.serviceAddress.zipCode" 
                                        label={t("Zip code")}
                                    />

                                    <TextField
                                        fullWidth={true}
                                        type={'text'}
                                        value={values.emailAddress}
                                        error={isEmailAddressError}
                                        helperText={isEmailAddressError ? t(errors.emailAddress) : ''}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="emailAddress"
                                        name="emailAddress"
                                        label={t("Email address")}
                                    />

                                    <PhoneNumberInput
                                        {...commonFormikProps}
                                        id="phoneNumber"
                                        label={t("Phone number")}
                                        value={values.phoneNumber}
                                        onChange={setFieldValue}
                                        labelClass="mt-4"
                                        labelStyle={{ color: 'black' }}
                                        t={t}
                                    />

                                    <div className="justify-content-end">
                                        <div className="col-md text-right">
                                            <button type="submit" disabled={isSubmitting} className="btn srp-btn btn-blue mt-3 mb-2">
                                                {isSubmitting ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} /> : t('Next')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )
                }}
            </Formik>
        )
    }
}

CustomDueDateInputsCommercial.propTypes = {
    customDueDateData: PropTypes.shape({
        billAccount: PropTypes.number,
        businessInfoModel: PropTypes.shape({
            emailAddress: PropTypes.string,
            phoneNumber: PropTypes.string,
            touched: PropTypes.bool
        }),
        initalStateSet: PropTypes.bool,
        newDueDate: PropTypes.object
    }),
    getAccountNumber: PropTypes.func,
    getBusinessInfoModel: PropTypes.func,
    getCustomerInfoModel: PropTypes.func,
    getEmailAddress: PropTypes.func,
    getIsCommercial: PropTypes.func,
    getPhoneNumber: PropTypes.func,
    getServiceAddress: PropTypes.func,
    nextDueDateStep: PropTypes.func,
    updateStateData: PropTypes.func,
    t: PropTypes.func
}

export default CustomDueDateInputsCommercial