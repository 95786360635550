import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'

let style = {
    marginLeft: "45%",
    marginTop: "20%",
    marginBottom: "20%"
}

const AccountDetailsLoadingCard = ({t}) => {
    return (
        <div className="col-lg-8 col-12">
            <div className="srp-card-header">
                {t("Account details")}
            </div>
            <div className="srp-card-details">
                <CircularProgress size={80} thickness={5} style={style} />
            </div>
        </div>
    );
}

AccountDetailsLoadingCard.propTypes = {
    t: PropTypes.func.isRequired
}

export default AccountDetailsLoadingCard