import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Checkbox, Menu, MenuItem } from '@mui/material'
import { updateSelectedUnlinkedBillAccounts } from '../../../../actions/auth/bill_account/bill-account-actions'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

class RestoreHiddenAccountsCheckboxes extends React.Component {
    constructor(props) {
        super(props)

        this.displayCheckboxCount = 2
        this.state = this.getInitialState(this.props)

        this.openMenu = this.openMenu.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleCheckChanged = this.handleCheckChanged.bind(this)

        this.selectAll = this.selectAll.bind(this)
        this.selectNone = this.selectNone.bind(this)
        this.selectActive = this.selectActive.bind(this)
        this.toggleSelected = this.toggleSelected.bind(this)
    }

    getInitialState(props) {
        let activeCount = 0
        let closedCount = 0
        let activeSelected = 0
        let closedSelected = 0

        for (let key in props.unlinkedBillAccountList) {
            let unlinkedAccount = props.unlinkedBillAccountList[key]

            if (unlinkedAccount.isFinalAccount) {
                closedCount++
                if (props.selectedUnlinkedBillAccounts[`${unlinkedAccount.account}`] !== undefined)
                    closedSelected++
            }
            else {
                activeCount++
                if (props.selectedUnlinkedBillAccounts[`${unlinkedAccount.account}`] !== undefined)
                    activeSelected++
            }
        }

        let accountsSelected =
            (activeCount === activeSelected && closedCount === closedSelected)
                ? 'all'
                : (activeSelected === 0 && closedSelected === 0) ? 'none' : 'some'

        return {
            accountsSelected,
            activeAvailable: activeCount > 0,
            closedAvailable: closedCount > 0,
            menuAnchor: null
        }
    }

    UNSAFE_componentWillUpdate(nextProps) {
        let accountsSelected = this.getCheckboxState(nextProps)

        if (this.state.accountsSelected !== accountsSelected) {
            this.setState({ ...this.state, accountsSelected })
        }
    }

    getCheckboxState(props) {
        let selectedCount = Object.keys(props.selectedUnlinkedBillAccounts).length

        if (selectedCount === props.unlinkedBillAccountList.length) {
            return 'all'
        }
        else if (selectedCount > 0) {
            return 'some'
        }

        return 'none'
    }

    handleChange(value) {
        return () => {
            this.handleClose()

            switch(value) {
                case 'all':
                    return this.selectAll()
                case 'active':
                    return this.selectActive()
                case 'closed':
                    return this.selectClosed()
                case 'none':
                    return this.selectNone()
            }
        }
    }

    openMenu(event) {
        this.setState({ ...this.state, menuAnchor: event.currentTarget })
    }

    handleClose() {
        this.setState({ ...this.state, menuAnchor: null })
    }

    handleCheckChanged(event) {
        event.stopPropagation()
        this.toggleSelected()
    }

    selectAll() {
        if (this.state.accountsSelected === 'all')
            return

        let selectedUnlinkedBillAccounts = { }

        for (let key in this.props.unlinkedBillAccountList) {
            let unlinkedAccount = this.props.unlinkedBillAccountList[key]

            selectedUnlinkedBillAccounts[`${unlinkedAccount.account}`] = unlinkedAccount
        }

        return this.props.updateSelectedUnlinkedBillAccounts(selectedUnlinkedBillAccounts)
    }

    selectNone() {
        if (this.state.accountsSelected === 'none')
            return

        return this.props.updateSelectedUnlinkedBillAccounts({ })
    }

    selectActive() {
        let selectedUnlinkedBillAccounts = { }

        for (let key in this.props.unlinkedBillAccountList) {
            let unlinkedAccount = this.props.unlinkedBillAccountList[key]

            if (!unlinkedAccount.isFinalAccount) {
                selectedUnlinkedBillAccounts[`${unlinkedAccount.account}`] = unlinkedAccount
            }
        }

        return this.props.updateSelectedUnlinkedBillAccounts(selectedUnlinkedBillAccounts)
    }

    selectClosed() {
        let selectedUnlinkedBillAccounts = { }

        for (let key in this.props.unlinkedBillAccountList) {
            let unlinkedAccount = this.props.unlinkedBillAccountList[key]

            if (unlinkedAccount.isFinalAccount) {
                selectedUnlinkedBillAccounts[`${unlinkedAccount.account}`] = unlinkedAccount
            }
        }

        return this.props.updateSelectedUnlinkedBillAccounts(selectedUnlinkedBillAccounts)
    }

    toggleSelected() {
        if (this.state.accountsSelected === 'none') {
            return this.selectAll()
        }
        else {
            return this.selectNone()
        }
    }

    render() {
        return this.props.unlinkedBillAccountList.length < this.displayCheckboxCount ? null : (
            <div className="col-12 mb-2">
                <Button id="unlinked-checkbox" ref="unlinkedCheckbox" aria-haspopup="true"
                        onClick={this.openMenu} style={{textTransform:'none'}}
                        endIcon={<ArrowDropDownIcon />} size="small">
                    <Checkbox color="primary" onClick={this.handleCheckChanged}
                              indeterminate={this.state.accountsSelected === 'some'}
                              checked={this.state.accountsSelected !== 'none'} />
                </Button>
                <Menu id="select-unlinked-accounts-menu" open={this.state.menuAnchor !== null}
                      anchorEl={this.state.menuAnchor} onClose={this.handleClose}>
                    <MenuItem onClick={this.handleChange('all')}>{this.props.t("All")}</MenuItem>
                    {this.state.activeAvailable ? <MenuItem onClick={this.handleChange('active')}>{this.props.t("Active")}</MenuItem> : null}
                    {this.state.closedAvailable ? <MenuItem onClick={this.handleChange('closed')}>{this.props.t("Closed")}</MenuItem> : null}
                    <MenuItem onClick={this.handleChange('none')}>{this.props.t("None")}</MenuItem>
                </Menu>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        selectedUnlinkedBillAccounts: state.accountInfo.billAccount.selectedUnlinkedBillAccounts,
        unlinkedBillAccountList: state.accountInfo.billAccount.unlinkedBillAccountList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateSelectedUnlinkedBillAccounts: selectedUnlinkedBillAccounts => {
            dispatch(updateSelectedUnlinkedBillAccounts(selectedUnlinkedBillAccounts))
        }
    }
}

RestoreHiddenAccountsCheckboxes.propTypes = {
    selectedUnlinkedBillAccounts: PropTypes.object.isRequired,
    unlinkedBillAccountList: PropTypes.array.isRequired,
    updateSelectedUnlinkedBillAccounts: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(RestoreHiddenAccountsCheckboxes)