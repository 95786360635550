import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { Trans } from 'react-i18next'
import {billAccountConstants} from '../../../constants/bill-account-constants'

import format from '../../../srp_modules/format'

const AmountDueAndDueDateLabel = ({amountDue, dueDate, isValid, pastDue, summaryBillingStatus, isSurePay, t}) => {
    if (!isValid)
        return <div className="alert text-white" style={{backgroundColor: 'rgb(158,42,43)', borderStyle: 'none'}}>{t("Unable to retrieve billing information.")}</div>

    let message = null

    if (amountDue === 0) {
        message = t("You have no amount due at this time.")
    } else if (amountDue < 0) {
        message = (<div><Trans i18nKey="You_have_a_credit_of" t={t}>
        You have a credit of <strong>{{creditAmount: format.formatUsdAmount(-amountDue)}}</strong>.
        </Trans></div>)
    } else if (pastDue > 0) {
        message = (<div><Trans i18nKey="Your_amount_due_is" t={t}>
        Your amount due is <strong>{{amountDue: format.formatUsdAmount(amountDue)}}</strong>, of which <strong>{{amountDueNow: format.formatUsdAmount(pastDue)}}</strong> is due immediately.
        </Trans></div>)
    } else if (summaryBillingStatus === billAccountConstants.SUMMARY_BILLING_STATUS.PARENT && !isSurePay) {
        message = (<div><Trans i18nKey="Your_current_balance_is_see_bill" t={t}>
        Your current balance is <strong>{{balance: format.formatUsdAmount(amountDue)}}</strong>. See bill for due date.
        </Trans></div>)
    } else {
        const luxonDueDate = DateTime.fromISO(dueDate)
        const dueDateIsMinDate = luxonDueDate.equals(DateTime.fromISO("0001-01-01"))

        message = dueDateIsMinDate
            ? (<div><Trans i18nKey="Your_current_balance_is" t={t}>
              Your current balance is <strong>{{balance: format.formatUsdAmount(amountDue)}}</strong>.
              </Trans></div>)
            : (<div><Trans i18nKey="Your_current_balance_is_due_date" t={t}>
              Your current balance of <strong>{{balance: format.formatUsdAmount(amountDue)}}</strong> is due by <strong>{{dueDate: luxonDueDate.toFormat('M/d/yyyy')}}</strong>.
              </Trans></div>)
    }

    return <div className="alert" style={{backgroundColor: 'rgb(224,231,240)', borderStyle: 'none'}}>{message}</div>
}

AmountDueAndDueDateLabel.propTypes = {
    t: PropTypes.func.isRequired,
    amountDue: PropTypes.number.isRequired,
    dueDate: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired,
    pastDue: PropTypes.number,
    summaryBillingStatus: PropTypes.number.isRequired,
    isSurePay: PropTypes.bool.isRequired
}

export default AmountDueAndDueDateLabel