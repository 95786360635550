import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import { withRouter } from '../../../srp_modules/with-router'
import DeleteSafetyNetDialog from '../../forms/safety_net/delete-safety-net-dialog'

class SafetyNetSettingsResponsive extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            showRemoveSafetyNetDialog: false
        }

        this.handleRemoveSafetyNetDialog = this.handleRemoveSafetyNetDialog.bind(this)
    }

    handleRemoveSafetyNetDialog(openDialog) {
        this.setState({
            showRemoveSafetyNetDialog: openDialog
        })
    }

    render() {

        let safetyNetComponent = (
            <div>
                <div className="lg-3 mr-2">
                    {this.props.t("If a bill becomes due, we'll let someone know")}
                </div>
                <div className="lg-3 mr-2" style={{ textAlign: 'right' }}>
                    <Link
                        className=""
                        to="/forms/safetynet/signup">
                        {this.props.t("Add partner")}
                    </Link>
                </div>
            </div>
        )

        if (this.props.safetyNetProgramInfo.isEnrolled)
            safetyNetComponent = (
                <div>
                    <div className="lg-3 mr-2 text-capitalize">
                        {this.props.safetyNetProgramInfo.safetyNetsName.toLowerCase()}
                    </div>
                    <div className="lg-3 mr-2" style={{ textAlign: 'right' }}>
                        <IconButton
                            className="align-self-center"
                            onClick={() => this.props.router.navigate('/forms/safetynet/change', { replace: true })}
                            color="primary"
                            size="large">
                            <i className="material-icons">create</i>
                        </IconButton>
                        <IconButton
                            className="align-self-center"
                            onClick={() => this.handleRemoveSafetyNetDialog(true)}
                            color="primary"
                            size="large">
                            <i className="material-icons">delete</i>
                        </IconButton>
                    </div>
                </div>
            )

        return (
            <div>
                <div className="border-bottom p-2">
                    <div className="lg-3 mr-2 text-muted text-nowrap">
                        {this.props.t("SRP Safety Net partner")}
                    </div>
                    {safetyNetComponent}
                    <DeleteSafetyNetDialog
                        showDialog={this.state.showRemoveSafetyNetDialog}
                        handleDialog={this.handleRemoveSafetyNetDialog} />
                </div>
            </div>
        )
    }
}

SafetyNetSettingsResponsive.propTypes = {
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    safetyNetProgramInfo: PropTypes.object,
    t: PropTypes.func.isRequired
}

export default withRouter(SafetyNetSettingsResponsive)